import {AddSchema} from "mobx-firelink";
import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import moment from "moment";
import {FBAConfig} from "../fbaConfigs/@FBAConfig.js";
import {LogEntry} from "../../../UI/Tools/@Shared/LogEntry.js";
import {LaunchType} from "../fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";

// session data that is stored permanently
export class EngineSessionInfo {
	constructor(initialData?: Partial<EngineSessionInfo>) { this.VSet(initialData); }

	//_key: string;
	/** Whether session has locally-saved entry. */
	_local?: boolean;
	/** If session has locally-saved entry, this is the folder name the data is stored under. */
	_folderName?: string; // note: in-progress sessions have the equivalent "FBASession.folderName"
	/** Whether session has web-saved entry. */
	_online?: boolean;

	id: string; // todo: remove this, and switch to _key (with local sessions just storing "_key" prop in file)
	creator: string;
	configID: string;
	config: FBAConfig;
	launchType: LaunchType;
	/** Only set if the alarm-delay is locked to a single value in the config. */
	alarmDelay?: number; // in ms

	startTime: number;
	/** How many minutes you'd need to subtract from the local-time (of the session-creator), for it to match the utc-time. (when session started) */
	localOffsetFromUTC: number;
	endTime: number;
	//week: string; // eg. "2020-08-02" (sunday where week started)
	week: number; // time at start of utc-week (in which session started)

	events: SessionEvent[];
	logEntries: LogEntry[];
}
AddSchema("EngineSessionInfo", {
	properties: {
		id: {type: "string"},
		creator: {type: "string"},
		configID: {type: "string"},
		config: {$ref: "FBAConfig"},
		launchType: {$ref: "LaunchType"},
		alarmDelay: {type: ["null", "number"]},

		startTime: {type: "number"},
		localOffsetFromUTC: {type: "number"},
		endTime: {type: "number"},
		week: {type: "number"},

		events: {items: {$ref: "SessionEvent"}},
		logEntries: {items: {$ref: "LogEntry"}},
	},
});