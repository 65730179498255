import {Timer} from "js-vextensions";
import {secondInMS} from "web-vcore";
import {AlarmConfigBase, AlarmSequence, AlarmsGroup} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {LogType} from "../../../../UI/Tools/@Shared/LogEntry.js";
import {FBASession} from "../../../FBASession";
import {AlarmsComp, AlarmsPhase} from "../AlarmsComp.js";
import {EngineSessionComp} from "../EngineSessionComp";
import {SessionLog} from "../../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";

/** This is returned by some base-class methods, as a typescript trick to ensure derived-classes call those base methods. */
const AlarmComp_ProofBaseMethodCalled = Symbol("AlarmComp_ReceiptOfBaseMethodBeingCalled");

export abstract class AlarmComp<T extends AlarmConfigBase> extends EngineSessionComp<T> {
	constructor(session: FBASession, sequence: AlarmSequence, config: T) {
		const getGroup = (session: FBASession)=>session.c.eeg.alarmSequence.alarms.includes(config) ? "eeg" : "alarms";
		super(session, config, s=>s.c[getGroup(s)].enabled && config.enabled, s=>s.IsLocal());
		this.group = getGroup(session);
		this.sequence = sequence;

		this.offsetWaitTimer = new Timer(this.c.startOffset * secondInMS, ()=>{
			SessionLog(`Offset wait @${this.constructor.name}`);
			this.StartAlarm();
		}, 1).SetContext(this.s.timerContext);
		this.autoEndTimer = new Timer(this.c.autoEndAfter >= 0 ? this.c.autoEndAfter * secondInMS : 1_000_000_000, ()=>{
			SessionLog(`Auto-end @${this.constructor.name}`);
			this.StopAlarm("autoEnd");
		}, 1).SetContext(this.s.timerContext);
		this.autoRestartTimer = new Timer(this.c.autoRestartAfter >= 0 ? this.c.autoRestartAfter * secondInMS : 1_000_000_000, ()=>{
			SessionLog(`Auto-restart @${this.constructor.name}`);
			this.StopAlarm("other");
			this.StartAlarm();
		}, 1).SetContext(this.s.timerContext);
		this.effectApplyTimer = new Timer(this.c.effectInterval > 0 ? this.c.effectInterval * 1000 : 1_000_000_000, ()=>{
			const ticksPastFirst = this.effectApplyTimer.callCount_thisRun - 1;

			if (this.c.fadeIn_enabled && this.c.effectInterval > 0) {
				const secondsIntoCountdown = ticksPastFirst * this.c.effectInterval;
				const percentThroughFadeIn = (secondsIntoCountdown / this.c.fadeIn_duration).KeepBetween(0, 1);

				const baseVolume = this.c.intensityStart;
				const fadeInRange_max = this.c.fadeIn_intensityEnd - baseVolume;

				let currentIntensity: number;
				const curve = this.c.fadeIn_curve;
				if (curve != 0) {
					const multiplierAtCurrentPointOnCurve = Math.pow(percentThroughFadeIn, curve) / Math.pow(1, curve);
					currentIntensity = baseVolume + (fadeInRange_max * multiplierAtCurrentPointOnCurve);
				} else {
					currentIntensity = baseVolume + (fadeInRange_max * percentThroughFadeIn);
				}
				this.intensity = currentIntensity;
			} else {
				this.intensity = this.c.intensityStart;
			}
			
			this.PlayAlarmEffect();
		}).SetContext(this.s.timerContext);
	}
	group: AlarmsGroup;
	sequence: AlarmSequence;

	offsetWaitTimer: Timer;
	autoEndTimer: Timer;
	autoRestartTimer: Timer;
	effectApplyTimer: Timer;

	EffectApplyCount() {
		return this.effectApplyTimer.callCount_thisRun;
	}

	intensity = 0;
	//intensityAtLastPrompt = 0;
	//eegActivityAtLastNotify = 0;
	eegActivityAtLastPromptPlayOrUpdate = 0;
	GetIntensityForEEGActivity(eegActivity: number) {
		// there's got to be a better way to calculate this...
		/*const intensityIncreaseRange = this.c.intensityEnd - this.c.intensityStart;
		const intensityIncreasePerActivityUnit = this.c.intensityIncreasePerStep / this.c.effectInterval;
		const activityIncreaseToReachFullIntensity = intensityIncreaseRange / intensityIncreasePerActivityUnit;
		const percentThroughActivityRange = GetPercentFromXToY(this.c.startOffset, this.c.startOffset + activityIncreaseToReachFullIntensity, eegActivity);
		const newIntensity = Lerp(this.c.intensityStart, this.c.intensityEnd, percentThroughActivityRange);
		return newIntensity;*/
		// needs rework; for now hard-code to full-intensity
		return this.c.fadeIn_intensityEnd;
	}
	NotifyEEGActivity(eegActivity: number) {
		const activityChangeSinceLastPromptPlayOrUpdate = eegActivity - this.eegActivityAtLastPromptPlayOrUpdate;
		if (eegActivity >= this.c.startOffset) {
			const oldIntensity = this.intensity;
			this.intensity = this.GetIntensityForEEGActivity(eegActivity);
			//const intensityChangeSinceLastPrompt = comp.intensity - comp.intensityAtLastPrompt;

			// if activity changed (in either direction) by step-size
			if (Math.abs(activityChangeSinceLastPromptPlayOrUpdate) >= this.c.effectInterval) {
				const activitySteppedUp = activityChangeSinceLastPromptPlayOrUpdate > 0;
				if (activitySteppedUp) {
					//const tempIntensity = comp.intensityAtLastPrompt
					this.PlayAlarmEffect();
				} else {
					// only update prompt for activity decrease, if it led to an actual intensity decrease
					if (this.intensity < oldIntensity) {
						this.UpdatePrompt_ForReducedIntensity();
					}
				}
				// always update this; even if intensity doesn't change, we need to be ready to trigger new prompt, on new increase (not all prompts are persistent)
				this.eegActivityAtLastPromptPlayOrUpdate = eegActivity;
			}
		} else {
			// if prompt may have been active before this notify, make sure it's stopped
			if (this.eegActivityAtLastPromptPlayOrUpdate >= this.c.startOffset) {
				this.StopAlarm("other");
			}
			this.eegActivityAtLastPromptPlayOrUpdate = 0; // reset, so prompt will play as soon as above min-activity again
		}
	}
	
	override OnStop() {
		this.StopBaseTimers();
		this.StopAlarm("other");
		return AlarmComp_ProofBaseMethodCalled;
	}

	//override OnStartPhase_Alarm() {
	override OnLeavePhase_Alarm(newPhase: AlarmsPhase) {
		this.StopBaseTimers();
		this.StopAlarm("other");
		return AlarmComp_ProofBaseMethodCalled;
	}
	StopBaseTimers() {
		this.offsetWaitTimer.Stop();
		this.autoEndTimer.Stop();
		this.autoRestartTimer.Stop();
		this.effectApplyTimer.Stop();
	}

	OnSequenceStarted() {
		this.offsetWaitTimer.Start();
		return AlarmComp_ProofBaseMethodCalled;
	}
	/*OnSequenceEnabled() {
		if (this.s.Comp(AlarmsComp).PhaseIs(AlarmsPhase.Alarm)) {
			this.OnStartPhase_Alarm();
		}
		return AlarmComp_ProofBaseMethodCalled;
	}*/
	OnSequenceDisabled() {
		this.StopBaseTimers();
		this.StopAlarm("other");
		return AlarmComp_ProofBaseMethodCalled;
	}

	/*TryPlayAlarmEffect() {
		const alarmsComp = this.s.Comp(AlarmsComp);
		if (alarmsComp.microSnoozeActive) return;
		//const effectApplyCount = this.effectApplyTimer.callCount_thisRun;
		this.PlayAlarmEffect();
	}*/

	/** Called in alarm phase, when the start-offset point has been reached. */
	StartAlarm() {
		this.Log(`Starting alarm (${this.constructor.name})`, LogType.Event_Large);
		this.autoEndTimer.Start();
		this.autoRestartTimer.Start();
		this.effectApplyTimer.Start(0); // have first tick happen immediately
		return AlarmComp_ProofBaseMethodCalled;
	}
	/** Called when effect-interval passes (in alarms comp) or eeg-activity increases by interval (in eeg comp), from last prompt play/apply. */
	abstract PlayAlarmEffect();
	/** Called when eeg-activity drops by step-size, from last trigger. (example: if intensity should increase by 10% per 5 eeg-activity increase, and eeg-activity drops from 10 to 5, this is called) */
	abstract UpdatePrompt_ForReducedIntensity();
	/** Called when snooze occurs (in snooze group; add handling to each comp), or when eeg-activity drops below prompting min (in eeg group). */
	StopAlarm(cause: "autoEnd" | "other") {
		SessionLog(`StopAlarm @${this.constructor.name} @cause:${cause}`);
		this.autoEndTimer.Stop();
		if (cause != "autoEnd") this.autoRestartTimer.Stop();
		this.effectApplyTimer.Stop();
		return AlarmComp_ProofBaseMethodCalled;
	}
}