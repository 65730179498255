import {ModifyString, Range, SleepAsync} from "js-vextensions";
import {FBAConfig_DreamQuiz} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {GetAudioFilesWithSubpath} from "../../../../Store/main/cache.js";
import {AudioFileEntry, PlaySound_ByContentUri, PlaySound_StopAll} from "../../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {FBASession} from "../../../FBASession.js";
import {DreamQuizComp} from "../DreamQuizComp.js";

export class SoundQuizSubcomp {
	constructor(dreamQuizComp: DreamQuizComp) {
		this.s = dreamQuizComp.s;
		this.c = dreamQuizComp.c;
		this.p = dreamQuizComp;
	}
	s: FBASession;
	c: FBAConfig_DreamQuiz;
	p: DreamQuizComp;

	OnStart() {
		this.SelectNewPromptInfo();
	}

	async SubmitPick() {
		if (this.options_targetIndex == -1) return;
		if (this.options_currentIndex == this.options_targetIndex) {
			this.s.AsLocal!.AddEvent({type: "SoundQuiz.TargetHit"});
			this.p.NarrateText("Good.");

			await SleepAsync(1000);
			this.p.GoNextPrompt(true);
		} else {
			this.p.NarrateText("Wrong.");
			// only record a "miss" event if option actually exists at current index ("submit" of an empty option would just be a mistaken press)
			if (this.options[this.options_currentIndex] != null) {
				this.s.AsLocal!.AddEvent({type: "SoundQuiz.TargetMiss"});
			}
		}
	}
	NextOption() {
		if (this.options_targetIndex == -1) return;
		this.options_currentIndex = (this.options_currentIndex + 1).KeepBetween(-1, this.options.length);
		const isEdgeHelper = !this.options_currentIndex.IsBetween(0, this.options.length - 1);
		if (isEdgeHelper) this.PlayCurrentHint();
		else this.NarrateCurrentOption();
	}
	PrevOption() {
		if (this.options_targetIndex == -1) return;
		this.options_currentIndex = (this.options_currentIndex - 1).KeepBetween(-1, this.options.length);
		const isEdgeHelper = !this.options_currentIndex.IsBetween(0, this.options.length - 1);
		if (isEdgeHelper) this.PlayCurrentHint();
		else this.NarrateCurrentOption();
	}
	SkipPick() {
		this.s.AsLocal!.AddEvent({type: "SoundQuiz.TargetGiveUp"});
		this.p.GoNextPrompt(true);
	}

	PlayCurrentHint() {
		if (this.TargetOption == null) return; // can be null if this is called almost immediately at session start (before the async-func in constructor completes and calls SelectNewPromptInfo)
		this.EnsureHintNotPlaying();
		PlaySound_ByContentUri(this.TargetOption.audioFile.contentUri, this.c.soundVolume, this.c.soundDurationLimit, false); // don't repeat audio in this case (user can repeat manually, if desired)
	}
	NarrateCurrentOption() {
		if (this.CurrentOption == null) return;
		this.EnsureHintNotPlaying();
		this.p.NarrateText(this.CurrentOption.GetNarrateText());
	}
	EnsureHintNotPlaying() {
		PlaySound_StopAll();
	}

	SelectNewPromptInfo() {
		const newOptions = [] as SoundQuizOption[];
		for (let i = 0; i < this.c.optionCount; i++) {
			const unpickedOptions = GetAudioFilesWithSubpath(this.c.soundFolder).Exclude(...newOptions.map(a=>a.audioFile));
			const option = new SoundQuizOption({audioFile: unpickedOptions.Random()});
			newOptions.push(option);
		}
		this.options = newOptions;
		this.options_targetIndex = Range(0, this.c.optionCount - 1).Random();
		// start at first-1/last+1, so that first press to next/prev will go to first/last option, and NOT voice the hint
		this.options_currentIndex = this.c.startAtLast ? this.options.length : -1;
	}

	options = [] as SoundQuizOption[];
	options_targetIndex = -1;
	options_currentIndex = -1;
	get TargetOption(): SoundQuizOption|n { return this.options[this.options_targetIndex]; }
	get CurrentOption(): SoundQuizOption|n { return this.options[this.options_currentIndex]; }
}

class SoundQuizOption {
	constructor(data?: Partial<SoundQuizOption>) {
		Object.assign(this, data);
	}
	audioFile: AudioFileEntry;
	GetNarrateText() {
		const fileName = this.audioFile.filePath.split("/").Last();
		const fileName_beforeUnderscore = fileName.split("_")[0];
		const fileName_spacesAdded = ModifyString(fileName_beforeUnderscore, m=>[m.lowerUpper_to_lowerSpaceLower]);
		return fileName_spacesAdded;
	}
}