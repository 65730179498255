import {Assert, GetValues_ForSchema} from "js-vextensions";
import {AddSchema} from "mobx-firelink";
import {TriggerSet} from "../../@TriggerSet";
import {FBAConfig} from "../../@FBAConfig.js";
import {FBAConfig_XAlarm, FBAConfig_XAlarm_schema} from "../@EC_Alarms.js";
import {EffectPointer} from "../../../@Shared/EffectPointer.js";

export class AlarmSequence {
	constructor(data: Partial<AlarmSequence>) { Object.assign(this, data); }

	name = "NewSequence";
	enabled = true;
	alarms = [] as FBAConfig_XAlarm[];
}
AddSchema("AlarmSequence", {
	properties: {
		name: {type: "string"},
		enabled: {type: "boolean"},
		alarms: {items: FBAConfig_XAlarm_schema},
	},
});

export const AlarmsGroup_values = ["eeg", "alarms"] as const; // these must match the root comp-config keys in fba-config structure
//export type AlarmsGroup = "eeg"|"snoozeAndAlarms";
export type AlarmsGroup = typeof AlarmsGroup_values[number];
AddSchema("AlarmsGroup", {oneOf: AlarmsGroup_values.map(a=>({const: a}))});
export function GetAlarmSequenceFromAlarmGroup(group: AlarmsGroup, config: FBAConfig, sequenceIndex: number) {
	if (group == "eeg") return config.eeg.alarmSequence;
	if (group == "alarms") return config.alarms.phaseAlarm_sequences[sequenceIndex];
	Assert(false, "Invalid alarms group.");
}

export enum AlarmType {
	Sound = 10,
	Countdown = 15,
	Shake = 20,
	Light = 30,
	//Memory = 40,
	Script = 50,
}
AddSchema("AlarmType", {oneOf: GetValues_ForSchema(AlarmType)});
export enum AlarmTriggerType {
	Time = 10,
	EEG = 20,
}
AddSchema("AlarmTriggerType", {oneOf: GetValues_ForSchema(AlarmTriggerType)});

export function GetEffectGroupForAlarmType(type: AlarmType) {
	switch (type) {
		case AlarmType.Sound: return "sounds";
		case AlarmType.Countdown: return "sounds";
		case AlarmType.Shake: return "shakes";
		case AlarmType.Light: return "lights";
		case AlarmType.Script: return "scripts";
		default: { Assert(false, "Invalid alarm-type."); }
	}
}

export class AlarmConfigBase {
	constructor(data: PartialBy<AlarmConfigBase, "enabled" | "effectPointer" | "startOffset" | "effectInterval" | "intensityStart" | "fadeIn_enabled" | "fadeIn_intensityEnd" | "fadeIn_duration" | "fadeIn_curve" | "autoEndAfter" | "autoRestartAfter">) {
		Object.assign(this, data);
	}

	enabled = true;
	type: AlarmType;
	//triggerType: AlarmTriggerType;
	//effectTag?: string;
	effectPointer = new EffectPointer();

	startOffset = 0; // in seconds (if time trigger); in activity points (if eye-move trigger)
	autoEndAfter = -1; // in seconds (-1 means never auto-end)
	autoRestartAfter = -1; // in seconds (-1 means never auto-restart)
	effectInterval = 1; // in seconds (if time trigger: <=0 means only play once); in activity points (if eye-move trigger)
	intensityStart = 1; // 0-1

	// if intensityChange_enabled
	fadeIn_enabled = false;
	fadeIn_intensityEnd = 1; // 0-1
	fadeIn_duration = 10; // in seconds
	fadeIn_curve = 1; // 1 = linear, <1 = faster increases toward start, >1 = faster increases toward end
}
//AddSchema("AlarmConfig", {
export const AlarmConfigBase_SchemaProps = {
	enabled: {type: "boolean"},
	type: {$ref: "AlarmType"},
	effectPointer: {$ref: "EffectPointer"},

	startOffset: {type: "number"},
	autoEndAfter: {type: "number"},
	autoRestartAfter: {type: "number"},
	effectInterval: {type: "number"},
	intensityStart: {type: "number"},

	fadeIn_enabled: {type: "boolean"},
	fadeIn_intensityEnd: {type: "number"},
	fadeIn_duration: {type: "number"},
	fadeIn_curve: {type: "number"},
};