import {Button, CheckBox, Column, Row, RowLR, Spinner, Text, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ControlInput} from "../../../@Shared/ControlInput";
import {ES, InfoButton, TextPlus} from "web-vcore";
import {AlarmUI} from "./Alarms/AlarmUI";
import {AlarmConfigBase, AlarmSequence, AlarmsGroup, AlarmType, GetAlarmSequenceFromAlarmGroup} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {FBAConfig_LightAlarm, FBAConfig_ScriptAlarm, FBAConfig_ShakeAlarm, FBAConfig_SoundAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_BasicAlarms.js";
import {FBAConfig_CountdownAlarm} from "../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_CountdownAlarm.js";
import {colors} from "../../../../Utils/UI/GlobalStyles.js";
import {FBASessionPanels_SharedProps, ExtendFBASessionPanelSharedProps} from "../FBAConfigPanel_Local.js";
import {useState} from "react";
import {ShowMessageBox} from "react-vmessagebox";

const GetSharedProps = (props: FBASessionPanels_SharedProps)=>ExtendFBASessionPanelSharedProps(props, c=>c.alarms, (c, v)=>c.alarms = v);

const splitAt = 150;
export const PromptPanel_splitAt = splitAt;

export class Alarms_ConfigUI extends BaseComponent<{remote?: boolean} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {client, enabled} = this.props;
		const sharedProps = GetSharedProps(this.props);
		const {config, ChangeConfig} = sharedProps;

		return (
			<Column mt={15} style={{background: "hsla(0,0%,100%,.1)", borderRadius: 5, padding: 5, color: colors.nightTint}}>
				<Row style={{fontSize: 18}}>
					<CheckBox text="Alarms" enabled={enabled} value={config.enabled} onChange={val=>ChangeConfig(c=>c.enabled = val)}/>
				</Row>

				{config.enabled &&
				<>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Phase sleep:</Text>
						<ControlInput enabled={enabled} value={config.goPhaseSleep_triggerSet} onChange={val=>ChangeConfig(c=>c.goPhaseSleep_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Phase alarm:</Text>
						<ControlInput enabled={enabled} value={config.goPhaseAlarm_triggerSet} onChange={val=>ChangeConfig(c=>c.goPhaseAlarm_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text>Phase solving (5m):</Text>
						<ControlInput enabled={enabled} value={config.goPhaseSolving_triggerSet} onChange={val=>ChangeConfig(c=>c.goPhaseSolving_triggerSet = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus info={`
							If an alarm-wait phase ends, and then awakeness is signalled, it does the following:
							* Creates a "General.SleepCycleEnd" event in active session.
							* Creates a "wake" segment in the session-associated journal-entry.
							(It then waits for next alarm-wait end before applying these effects again.)
						`.AsMultiline(0)}>Signal awakeness:</TextPlus>
						<ControlInput enabled={enabled} value={config.signalAwakeness_triggerSet} onChange={val=>ChangeConfig(c=>c.signalAwakeness_triggerSet = val)}/>
					</RowLR>
					
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Voice, tag:</Text>
						<TextInput enabled={enabled} style={{width: 80}} value={config.voiceSoundTag} onChange={val=>ChangeConfig(c=>c.voiceSoundTag = val)}/>
						<Text ml={5} mr={5}>Vol. mult.:</Text>
						<Spinner min={0} step={0.01} enabled={enabled} value={config.volumeMultiplier} onChange={val=>ChangeConfig(c=>c.volumeMultiplier = val)}/>
					</RowLR>

					<Row mt={15} style={{fontSize: 18}}>Phase: Sleep</Row>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Enter effects:</Text>
						<Text>Sound:</Text>
						<TextInput ml={5} enabled={enabled} style={{width: 100}} value={config.phaseSleep_soundTag} onChange={val=>ChangeConfig(c=>c.phaseSleep_soundTag = val)}/>
						<Text ml={5}>Light:</Text>
						<TextInput ml={5} enabled={enabled} style={{width: 100}} value={config.phaseSleep_lightTag} onChange={val=>ChangeConfig(c=>c.phaseSleep_lightTag = val)}/>
					</RowLR>
					<Row mt={5} center>
						<Text mr={5}>Alarm delay (randomization pool):</Text>
						<TextInput enabled={enabled} value={config.alarmDelay_pool.join(", ")} onChange={val=>{
							const newPool = [] as number[];
							for (const str of val.split(",")) {
								const val = parseFloat(str);
								if (val != null && !isNaN(val)) {
									newPool.push(val);
								}
							}
							// don't allow user to make the pool empty
							if (newPool.length == 0) return;
							ChangeConfig(c=>c.alarmDelay_pool = newPool);
						}}/>
						<Text>m</Text>
					</Row>
					<Column ml={10}>
						<Row mt={5}>
							<TextPlus info="For alarm-delays with no assignment here, the rate defaults to 1.">Pool rates:</TextPlus>
							<Button ml={5} mdIcon="plus" enabled={enabled} onClick={()=>{
								ChangeConfig(c=>{
									for (let i = 0; i < 100; i++) {
										if (c.alarmDelay_poolRates[i] == null) {
											c.alarmDelay_poolRates[i] = 1;
											break;
										}
									}
								});
							}}/>
						</Row>
						{Object.entries(config.alarmDelay_poolRates).map(([alarmDelayStr, rate], index)=>{
							const alarmDelay = parseInt(alarmDelayStr);
							return (
								<Row key={index} ml={10} mt={5}>
									<Text>Delay:</Text>
									<Spinner ml={5} enabled={enabled} value={alarmDelay} onChange={newAlarmDelayForEntry=>ChangeConfig(c=>{
										// set the value this way, so entry ordering stays the same
										/*const newRatesMapObj = {};
										for (const [key, val] of Object.entries(c.alarmDelay_poolRates)) {
											if (key == alarmDelayStr) {
												newRatesMapObj[newAlarmDelayForEntry] = val;
											} else {
												newRatesMapObj[key] = val;
											}
										}
										c.alarmDelay_poolRates = newRatesMapObj;*/
										// firestore loses the ordering of map-objects anyway, so just use basic delete->set
										delete c.alarmDelay_poolRates[alarmDelay];
										c.alarmDelay_poolRates[newAlarmDelayForEntry] = rate;
									})}/>
									<Text ml={5}>Rate:</Text>
									<Spinner ml={5} enabled={enabled} value={rate} onChange={val=>ChangeConfig(c=>c.alarmDelay_poolRates[alarmDelay] = val)}/>
									<Button ml={5} mdIcon="delete" enabled={enabled} onClick={()=>{
										ChangeConfig(c=>delete c.alarmDelay_poolRates[alarmDelay]);
									}}/>
								</Row>
							);
						})}
						<Row mt={5}>
							<CheckBox text="Lock per session" value={config.alarmDelay_lockPerSession} onChange={val=>ChangeConfig(c=>c.alarmDelay_lockPerSession = val)}/>
							<InfoButton ml={5} text="If enabled, alarm-delay is calculated once at session start, rather than re-randomized each time an alarm-wait starts."/>
							<TextPlus ml={5} info={`
								1 = Every alarm-delay in the pool gets one draw-ticket, ie. an equal chance of being selected.
								2+ = Under-represented delay options get X draw-tickets, where X is the deficit of "nights used" compared to leader+1. (but limited to this setting)
								Note: The "nights used" value is the number of first-cycle dream-segments for which a given alarm-delay was used. (as can be seen on stats page)
							`.AsMultiline(0)}>Option catch-up rate:</TextPlus>
							<Spinner ml={5} enabled={enabled} min={1} step={1} value={config.alarmDelay_optionCatchUpRate} onChange={val=>ChangeConfig(c=>c.alarmDelay_optionCatchUpRate = val)}/>
						</Row>
					</Column>

					<Row mt={15} style={{fontSize: 18}}>Phase: Alarm</Row>
					<AlarmSequencesUI {...sharedProps} group="alarms"/>

					<Row mt={15} style={{fontSize: 18}}>Phase: Solving</Row>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Enter effects:</Text>
						<Text>Sound:</Text>
						<TextInput ml={5} enabled={enabled} style={{width: 100}} value={config.phaseSolving_soundTag} onChange={val=>ChangeConfig(c=>c.phaseSolving_soundTag = val)}/>
						<Text ml={5}>Light:</Text>
						<TextInput ml={5} enabled={enabled} style={{width: 100}} value={config.phaseSolving_lightTag} onChange={val=>ChangeConfig(c=>c.phaseSolving_lightTag = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<Text mr={5}>Solve success, message:</Text>
						<TextInput enabled={enabled} value={config.solveSuccess_message} onChange={val=>ChangeConfig(c=>c.solveSuccess_message = val)}/>
					</RowLR>
					<RowLR mt={5} splitAt={splitAt}>
						<TextPlus mr={5} info={`
							Note: The sound played is randomly selected from all sounds whose path includes the given text/subpath.
							Hint: To have no sound play on success, leave this empty.
							Hint: To have it play any alarm sound on your device, use "/".
						`.AsMultiline(0)}>Solve success, sound path:</TextPlus>
						<TextInput enabled={enabled} value={config.solveSuccess_soundPath} onChange={val=>ChangeConfig(c=>c.solveSuccess_soundPath = val)}/>
						<Text ml={5}>Volume:</Text>
						<Spinner ml={5} enabled={enabled} value={(config.solveSuccess_soundVolume * 100).RoundTo(.01)} onChange={val=>ChangeConfig(c=>c.solveSuccess_soundVolume = val / 100)} min={0} max={100}/>
						<Text>%</Text>
					</RowLR>
				</>}
			</Column>
		);
	}
}

export class AlarmSequencesUI extends BaseComponent<{group: AlarmsGroup} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {group, enabled, client, config_full, ChangeConfig_Full} = this.props;
		
		//const sequences = group == "eeg" ? [config_full.eeg.alarmSequence] : config_full.alarms.phaseAlarm_sequences;
		const sequences = config_full.alarms.phaseAlarm_sequences;
		const [selectedSequenceIndex, setSelectedSequenceIndex] = useState(0);
		
		return (
			<>
				<Row mt={5}>
					<CheckBox text="Target sequence, lock per session" value={config_full.alarms.phaseAlarm_sequences_lockPerSession} onChange={val=>ChangeConfig_Full(c=>c.alarms.phaseAlarm_sequences_lockPerSession = val)}/>
				</Row>
				<Row mt={5}>
					<Text>Sequences:</Text>
					{sequences.map((sequence, index)=>{
						return <Button key={index} ml={5} text={`${index + 1}: ${sequence.name}${!sequence.enabled ? " [d]" : ""}`} enabled={enabled}
							style={ES(
								selectedSequenceIndex == index && {backgroundColor: "rgba(100,200,100,.5)"},
							)}
							onClick={()=>setSelectedSequenceIndex(index)}/>;
					})}
					<Button ml={5} mdIcon="plus" enabled={enabled && !client} onClick={()=>{
						ChangeConfig_Full(c=>{
							c.alarms.phaseAlarm_sequences.push(new AlarmSequence({enabled: true, alarms: []}));
						});
					}}/>
				</Row>
				{sequences.length > selectedSequenceIndex &&
				<AlarmSequenceUI {...this.props} sequence={sequences[selectedSequenceIndex]} sequenceIndex={selectedSequenceIndex}/>}
			</>
		);
	}
}

export class AlarmSequenceUI extends BaseComponent<{group: AlarmsGroup, sequence: AlarmSequence, sequenceIndex: number} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {group, sequence, sequenceIndex, enabled, client, config_full, ChangeConfig_Full} = this.props;
		const ChangeSequence = (changerFunc: (sequence: AlarmSequence)=>void)=>{
			ChangeConfig_Full(c=>{
				const newSequence = GetAlarmSequenceFromAlarmGroup(group, c, sequenceIndex);
				changerFunc(newSequence);
			});
		};
		const ChangeAlarms = (alarmsChangerFunc: (alarms: AlarmConfigBase[])=>void)=>{
			ChangeSequence(s=>alarmsChangerFunc(s.alarms));
		};

		const sharedProps = this.props as FBASessionPanels_SharedProps;
		return (
			<>
				{group != "eeg" &&
				<Row mt={5}>
					<Text>Name:</Text>
					<TextInput ml={5} enabled={enabled} value={sequence.name} onChange={val=>ChangeSequence(s=>s.name = val)}/>
					<CheckBox ml={5} text="Enabled" enabled={enabled} value={sequence.enabled} onChange={val=>ChangeSequence(s=>s.enabled = val)}/>
					<Button ml={5} mdIcon="delete" enabled={enabled && !client} onClick={()=>{
						ShowMessageBox({
							title: "Delete sequence?", cancelButton: true,
							message: `Permanently delete this alarm-sequence?`,
							onOK: ()=>{
								ChangeConfig_Full(c=>{
									c.alarms.phaseAlarm_sequences.RemoveAt(sequenceIndex);
								});
							},
						});
					}}/>
				</Row>}
				<Row mt={5} mb={5}>
					<Text>Alarms:</Text>
					<Button ml={5} enabled={enabled && !client} text="Add sound" onClick={()=>{
						ChangeAlarms(a=>a.push(new FBAConfig_SoundAlarm({
							type: AlarmType.Sound,
						})));
					}}/>
					<Button ml={5} enabled={enabled && !client} text="Add countdown" onClick={()=>{
						ChangeAlarms(a=>a.push(new FBAConfig_CountdownAlarm({
							type: AlarmType.Countdown,
						})));
					}}/>
					<Button ml={5} enabled={enabled && !client} text="Add shake" onClick={()=>{
						ChangeAlarms(a=>a.push(new FBAConfig_ShakeAlarm({
							type: AlarmType.Shake,
						})));
					}}/>
					<Button ml={5} enabled={enabled && !client} text="Add light" onClick={()=>{
						ChangeAlarms(a=>a.push(new FBAConfig_LightAlarm({
							type: AlarmType.Light,
						})));
					}}/>
					<Button ml={5} enabled={enabled && !client} text="Add script" onClick={()=>{
						ChangeAlarms(a=>a.push(new FBAConfig_ScriptAlarm({
							type: AlarmType.Script,
						})));
					}}/>
				</Row>
				{sequence.alarms.map((prompt, index)=>{
					return <AlarmUI key={index} {...sharedProps} alarm={prompt} {...{index, group, sequence, sequenceIndex}}/>;
				})}
			</>
		);
	}
}