import {Column, Row, Text} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {Observer} from "web-vcore";
import moment from "moment";
import React, {useState} from "react";
import {JournalEntry, JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {DreamEventsUI} from "./DreamEventsUI.js";
import {SegmentActionButton, SegmentActionRowAndDetailsUI} from "./SegmentUI/SegmentActionButton.js";
import {GetDreamEvents} from "../../../Store/firebase/journalEntries.js";

export function IsSegmentASleepCycleStarter(segment: JournalSegment, prevSegment: JournalSegment|n) {
	// first segment always start's the first sleep-cycle
	if (prevSegment == null) return true;
	// sleep-cycles also start for wake-segments preceded by a non-wake segment
	if (segment.wakeTime != null && prevSegment.wakeTime == null) return true;
	return false;
}

//const SegmentUI_columns = 9;
@Observer
export class SegmentUI_Wake extends BaseComponent<{entry: JournalEntry, segment: JournalSegment, segmentIndex: number}, {}> {
	render() {
		const {entry, segment, segmentIndex} = this.props;
		const uiState = store.main.tools.journey;
		const priorSegments = entry.segments.slice(0, segmentIndex);
		const isSleepCycleStarter = IsSegmentASleepCycleStarter(segment, priorSegments.LastOrX());

		let sleepCycleNumberStr = "";
		if (isSleepCycleStarter) {
			const priorSleepCycleStarters = priorSegments.filter((segment, i)=>{
				return IsSegmentASleepCycleStarter(segment, priorSegments[i - 1]);
			}).length;
			sleepCycleNumberStr = `#${priorSleepCycleStarters + 1} `;
		}

		const [detailsOpen, setDetailsOpen] = useState(false);
		return (
			<Column mt={5} /*pl={10}*/ style={{position: "relative", background: "rgba(255,255,255,.2)"}}>
				{/*<div style={{position: "absolute", left: 0, top: 0, bottom: 0, width: 10, background: "white"}}/>*/}
				<Row>
					<SegmentActionButton parentDroppableInfo={null} journalEntry={entry} segment={segment} onClick={()=>setDetailsOpen(!detailsOpen)}/>
					<Text ml={5}>{sleepCycleNumberStr}Awoke at: {moment(segment.wakeTime!).format("HH:mm:ss")}</Text>
				</Row>
				{detailsOpen &&
				<SegmentActionRowAndDetailsUI journalEntry={entry} segment={segment}/>}
			</Column>
		);
	}
}

@Observer
export class SegmentUI_Dream extends BaseComponent<{
	entry: JournalEntry, entriesToShow: JournalEntry[],
	segment: JournalSegment, segmentIndex: number,
	journalPage: boolean,
}, {}> {
	render() {
		let {entry, entriesToShow, segment, segmentIndex, journalPage} = this.props;
		const index = entriesToShow.indexOf(entry);
		const dreamEvents = GetDreamEvents(segment);

		const [detailsOpen, setDetailsOpen] = useState(false);

		return (
			<Column mt={5} /*pl={10}*/ style={{position: "relative", background: "rgba(0,0,255,.3)"}}>
				{/*<div style={{position: "absolute", left: 0, top: 0, bottom: 0, width: 10, background: "rgba(0,0,255,1)"}}/>*/}
				<Column style={{flex: 1}}>
					<Row style={{alignItems: "flex-start"}}>
						<SegmentActionButton parentDroppableInfo={null} // todo: maybe find way to add this back (if using dnd again)
							journalEntry={entry} segment={segment} onClick={()=>setDetailsOpen(!detailsOpen)}/>
						<Column>
							<DreamEventsUI events={dreamEvents}/>
							{journalPage &&
							<Row sel style={{
								padding: 5, whiteSpace: store.main.journal.textPreviewMultiline ? null : "nowrap",
								background: index % 2 == 0 ? "rgba(30,30,30,.7)" : "rgba(0,0,0,.7)",
							}}>
								<Text>{segment.longText}</Text>
							</Row>}
						</Column>
						{/*!journalPage && <>
							<DreamEntitiesList entry={entry} segment={segment} segmentIndex={segmentIndex}/>
						</>*/}
					</Row>
				</Column>
				{detailsOpen &&
				<SegmentActionRowAndDetailsUI journalEntry={entry} segment={segment}/>}
			</Column>
		);
	}
}