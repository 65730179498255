import {minuteInMS} from "web-vcore";
import {CycleInfo, GetDataFromSessionsForJournalEntry, DiscernCycleInfosFromEventGroups, GetEventGroupsInSessions} from "../../../../Store/firebase/@Shared/DreamPlusSessionUtils.js";
import {IsMetaTerm} from "../../../../Store/firebase/entities.js";
import {EstimateDreamEndTime, EstimateDreamStartTime} from "../../../../Store/firebase/journalEntries.js";
import {JournalEntry, JournalSegment, GetTermsInDreamSegment} from "../../../../Store/firebase/journalEntries/@JournalEntry.js";
import {EngineSessionInfo} from "../../../../Store/firebase/sessions/@EngineSessionInfo.js";
import {JourneyStatsState, StatsXType, StatsYType, StatsGrouping} from "../../../../Store/main/tools/journey.js";
import {GetDayOffset} from "../JourneyStatsUI.js";
import {MetricCollector, ALL_GROUP_NAME} from "./MetricCollector.js";
import {eventTypes_dreamOrSoundQuiz_promptEnder, eventTypes_dreamQuiz, eventTypes_dreamQuiz_promptEnder, SessionEventType} from "../../../../Engine/FBASession/SessionEvent.js";
import {Assert, Range} from "js-vextensions";

export type AggregationData = ReturnType<typeof JStatsUI_GetAggregationData>;
export function JStatsUI_GetAggregationData(
	xTicks: number[], sessions_raw: EngineSessionInfo[], dreams_raw: JournalEntry[],
	// from ui-state (as main case)
	xType: StatsXType, yType: StatsYType, combined_yTypes: StatsYType[], grouping: StatsGrouping,
	dateRange_enabled: boolean, dateRange_min: number|n, dateRange_max: number|n,
) {
	const xValues_min = xTicks.First();
	const xValues_max = xTicks.Last();

	const sessions = sessions_raw.filter(session=>{
		if (dateRange_enabled && dateRange_min && session.startTime < dateRange_min) return false;
		if (dateRange_enabled && dateRange_max && session.endTime > dateRange_max) return false;
		return true;
	});
	const dreams = dreams_raw.filter(dream=>{
		const startTime = EstimateDreamStartTime(dream);
		const endTime = EstimateDreamEndTime(dream);
		if (dateRange_enabled && dateRange_min && startTime < dateRange_min) return false;
		if (dateRange_enabled && dateRange_max && endTime && endTime > dateRange_max) return false;
		return true;
	});

	/*const segments = dreams.map(a=>a.segments).flat();
	const sessionsForEachXValue = new Map<number, EngineSessionInfo[]>();
	const compositeSessionForEachXValue = new Map<number, {proHits: number, conHits: number}>();*/

	const alarmDelays = sessions.SelectMany(a=>{
		const jConf = a.config["journey"] ?? {};
		const aConf = a.config.alarms ?? {};
		const validNums = (...possibleNums: any[])=>possibleNums.filter(a=>a != null && typeof a == "number" && !isNaN(a));

		const delaysInMS = [] as number[];
		if (validNums(jConf.resleep_waitDuration)) {
			delaysInMS.push(jConf.resleep_waitDuration);
		}
		if (validNums(jConf.resleep_waitDuration_min, jConf.resleep_waitDuration_max, jConf.resleep_waitDuration_step)){
			delaysInMS.push(...Range(jConf.resleep_waitDuration_min, jConf.resleep_waitDuration_max, jConf.resleep_waitDuration_step));
		}
		if (jConf.alarmDelay_pool != null && validNums(...jConf.alarmDelay_pool)) {
			delaysInMS.push(...jConf.alarmDelay_pool.map(a=>a * minuteInMS));
		}
		if (aConf.alarmDelay_pool != null && validNums(...aConf.alarmDelay_pool)) {
			delaysInMS.push(...aConf.alarmDelay_pool.map(a=>a * minuteInMS));
		}
		return delaysInMS.map(a=>(a / minuteInMS).RoundTo(1)).Distinct();
	}).Distinct().OrderBy(a=>a);

	const sessionsForEachXValue = new Map<number, EngineSessionInfo[]>();
	//const dreamsForEachXValue = new Map<number, JournalEntry[]>();
	const segmentsForEachXValue = new Map<number, JournalSegment[]>();
	//const compositeSessionForEachXValue = new Map<number, {proHits: number, conHits: number}>();
	const segmentExistenceSamples = new MetricCollector<number>();
	const segmentIsLucidSamples = new MetricCollector<number>();
	const segmentTermCountSamples = new MetricCollector<number>();
	const responseTimeSamples = new MetricCollector<number>();
	const quizPromptHasHitSamples = new MetricCollector<boolean>();
	const quizTryIsHitSamples = new MetricCollector<boolean>();
	const quizFirstTryIsHitSamples = new MetricCollector<boolean>();
	const quizTimeTillSuccessSamples = new MetricCollector<number>();
	const linkVisualizationExistenceSamples = new MetricCollector<boolean>();

	const findXTick = (dayOffset: number, cycleNumber: number)=>{
		if (xType == StatsXType.showAll) return 0;
		if (xType == StatsXType.dayOffset) return dayOffset;
		return cycleNumber;
	};

	const yTypes = yType == StatsYType.combined ? combined_yTypes : [yType];

	if (yTypes.ContainsAny(StatsYType.dreamSegments_sum, StatsYType.lucids_sum, StatsYType.termsInShortText, StatsYType.termsInShortText_sum, StatsYType.termsInLongText, StatsYType.termsInLongText_sum)) {
		for (const [i_dream, dream] of dreams.entries()) {
			let cycleInfos: CycleInfo[]|n;
			if (grouping == StatsGrouping.alarmDelay) {
				const sessionsInfo = GetDataFromSessionsForJournalEntry(dream, dreams[i_dream + 1], false);
				cycleInfos = DiscernCycleInfosFromEventGroups(sessionsInfo.eventGroups, {dream, segments: dream.segments});
			}

			const nonWakeSegments = dream.segments.filter(a=>a.wakeTime == null);
			const dreamTime = EstimateDreamStartTime(dream);
			const dayOffset = GetDayOffset(dreamTime);

			for (const [i_segment, segment] of dream.segments.entries()) {
				if (segment.wakeTime != null) continue; // only dream/non-wake segments are relevant atm
				//const time = EstimateTimeOfSegment(dream, segment);

				// optimization: skip segments that are too far outside the x-values range
				if (xType == StatsXType.dayOffset && (dayOffset < xValues_min || dayOffset > xValues_max)) continue;

				let group = ALL_GROUP_NAME;
				if (grouping == StatsGrouping.alarmDelay) {
					const associatedCycle = cycleInfos!.find(a=>a.associatedDreamSegments.includes(segment));
					if (associatedCycle) {
						group = associatedCycle.alarmDelay.toString();
					}
				}

				const cycleNumber = nonWakeSegments.indexOf(segment) + 1; // todo: probably replace this with a more accurate cycle-number calculation in future (which reads from the data of the session for that night)
				const xValue = findXTick(dayOffset, cycleNumber);
				segmentsForEachXValue.set(xValue, (segmentsForEachXValue.get(dayOffset) ?? []).concat(segment));

				// todo: probably remove segmentsForEachXValue, and just use segmentExistenceSamples directly
				segmentExistenceSamples.AddSample(xValue, 1, group);

				const lucidityValue =
					segment.lucid ? 1 :
					segment.semiLucid ? .25 :
					0;
				segmentIsLucidSamples.AddSample(xValue, lucidityValue, group);

				const segmentTerms = GetTermsInDreamSegment(segment, yTypes.ContainsAny(StatsYType.termsInShortText, StatsYType.termsInShortText_sum) ? "shortText" : "longText", false);
				const validSegmentTerms = segmentTerms.filter(a=>!IsMetaTerm(a));
				segmentTermCountSamples.AddSample(xValue, validSegmentTerms.length, group);
			}
		}
	}

	for (const [index, session] of sessions.entries()) {
		const dayOffset = GetDayOffset(session.startTime);
		{
			const cycleNumber = 0; // todo: add better session<>journal association system, to discern this
			const xValue = findXTick(dayOffset, cycleNumber);
			sessionsForEachXValue.set(xValue, (sessionsForEachXValue.get(xValue) ?? []).concat(session));
		}

		// todo: maybe change this to consider multiple sessions that cover the same sleep (ie. journal-entry) period, as part of one "composite session"
		const eventGroups = GetEventGroupsInSessions([session]);
		const cycleInfos = DiscernCycleInfosFromEventGroups(eventGroups);
		for (const cycleInfo of cycleInfos) {
			const xValue = findXTick(dayOffset, cycleInfo.cycleNumber);
			responseTimeSamples.AddSample(xValue, cycleInfo.responseTime, cycleInfo.alarmDelay.toString());
		}

		for (const [index, event] of session.events.entries()) {
			const priorEvents = session.events.slice(0, index);
			
			const cycleNumber = 0; // todo: add better session<>journal association system, to discern this
			const xValue = findXTick(dayOffset, cycleNumber);
			const group = ALL_GROUP_NAME; // todo
			
			// dream-quiz events
			const dreamQuiz_lastHitOrMissOrGiveUp = priorEvents.findLast(a=>eventTypes_dreamQuiz.includes(a.type));
			if (event.type == "DreamQuiz.TargetHit") {
				quizPromptHasHitSamples.AddSample(xValue, true, group);
				quizTryIsHitSamples.AddSample(xValue, true, group);
				const isFirstTry = dreamQuiz_lastHitOrMissOrGiveUp?.type != "DreamQuiz.TargetMiss";
				if (isFirstTry) {
					quizFirstTryIsHitSamples.AddSample(xValue, true, group);
				}

				const lastIndexBeforeQuizEventsBlock = priorEvents.findLastIndex(a=>!eventTypes_dreamQuiz.includes(a.type));
				const priorEventsInQuizEventsBlock = lastIndexBeforeQuizEventsBlock == -1 ? [] :
					priorEvents.slice(lastIndexBeforeQuizEventsBlock + 1);
				const quiz_lastHitOrGiveUpEarlierInBlock = priorEventsInQuizEventsBlock.findLast(a=>eventTypes_dreamOrSoundQuiz_promptEnder.includes(a.type));
				if (quiz_lastHitOrGiveUpEarlierInBlock) {
					const timeTillSuccess = event.date - quiz_lastHitOrGiveUpEarlierInBlock.date;
					// hard-coded: if a quiz-prompt takes over 60s to solve, assume it was interrupted and discard it
					if (timeTillSuccess <= 60000) {
						quizTimeTillSuccessSamples.AddSample(xValue, timeTillSuccess, group);
					}
				}
			} else if (event.type == "DreamQuiz.TargetMiss") {
				quizTryIsHitSamples.AddSample(xValue, false, group);
				const isFirstTry = dreamQuiz_lastHitOrMissOrGiveUp?.type != "DreamQuiz.TargetMiss";
				if (isFirstTry) {
					quizFirstTryIsHitSamples.AddSample(xValue, false, group);
				}
			} else if (event.type == "DreamQuiz.TargetGiveUp") {
				quizPromptHasHitSamples.AddSample(xValue, false, group);
			} else if (event.type == "ConceptLink.TargetVisualized") {
				linkVisualizationExistenceSamples.AddSample(xValue, true, group);
			}
		}
	}

	return {
		alarmDelays, sessionsForEachXValue,
		segmentExistenceSamples, segmentIsLucidSamples, segmentTermCountSamples, responseTimeSamples,
		quizPromptHasHitSamples, quizTryIsHitSamples, quizFirstTryIsHitSamples, quizTimeTillSuccessSamples,
		linkVisualizationExistenceSamples,
	};
}

export function GetYValuesForYType(uiState: JourneyStatsState, xTicks: number[], aggregationData: AggregationData, yType: StatsYType, group: string) {
	const {
		alarmDelays,
		segmentExistenceSamples, segmentIsLucidSamples, segmentTermCountSamples, responseTimeSamples,
		quizPromptHasHitSamples, quizTryIsHitSamples, quizFirstTryIsHitSamples, quizTimeTillSuccessSamples,
		linkVisualizationExistenceSamples,
	} = aggregationData;

	const keepMiddleXPercent = (vals: number[]|n, resultIfValsNullOrResultNaN = 0)=>{
		if (vals == null || vals.length == 0) return resultIfValsNullOrResultNaN;
		const vals_sorted = vals.OrderBy(a=>a);
		// special case: if middle-keep-% is 0, interpret that as meaning "keep just the median value"
		if (uiState.middleKeepPercent == 0) {
			return vals_sorted.Median();
		}
		const percentToTrimAtEachExtreme = (1 - uiState.middleKeepPercent) / 2;
		const vals_sorted_toKeep_startIndex = Math.floor(vals_sorted.length * percentToTrimAtEachExtreme);
		const vals_sorted_toKeep = vals.slice(vals_sorted_toKeep_startIndex, vals_sorted.length - vals_sorted_toKeep_startIndex);
		return vals_sorted_toKeep.Average();
	};
	const getSum = (vals: number[]|n, resultIfValsNull = 0)=>{
		if (vals == null) return resultIfValsNull;
		return vals.Sum();
	}

	let yValues: number[];
	if (yType == StatsYType.dreamSegments_sum) {
		yValues = xTicks.map(xValue=>getSum(segmentExistenceSamples.GetSampleValues(xValue, group)));
	} else if (yType == StatsYType.lucids_sum) {
		yValues = xTicks.map(xValue=>getSum(segmentIsLucidSamples.GetSampleValues(xValue, group)));
	} else if (yType == StatsYType.termsInShortText || yType == StatsYType.termsInLongText) {
		yValues = xTicks.map(xValue=>keepMiddleXPercent(segmentTermCountSamples.GetSampleValues(xValue, group)).RoundTo(1));
	} else if (yType == StatsYType.termsInShortText_sum || yType == StatsYType.termsInLongText_sum) {
		yValues = xTicks.map(xValue=>getSum(segmentTermCountSamples.GetSampleValues(xValue, group)));
	} else if (yType == StatsYType.responseTime) {
		yValues = xTicks.map(xValue=>{
			const vals = responseTimeSamples.GetSampleValues(xValue, group);
			//return (keepMiddleXPercent(vals) / minuteInMS).RoundTo(.1);
			return (keepMiddleXPercent(vals) / 1000).RoundTo(1);
		});
	} else if (yType == StatsYType.quizPrompts_sum) {
		yValues = xTicks.map(xValue=>quizPromptHasHitSamples.GetSampleValues(xValue, group).length);
	} /*else if (yType == StatsYType.quizTryHitPercent) {
		yValues = xTicks.map(xValue=>{
			const trySamples = quizTryIsHitSamples.GetSampleValues(xValue, group);
			if (trySamples.length == 0) return 0;
			const hitCount = trySamples.filter(a=>a).length;
			return ((hitCount / trySamples.length) * 100).RoundTo(1);
		});
	}*/ else if (yType == StatsYType.quizFirstTryHitPercent) {
		yValues = xTicks.map(xValue=>{
			const firstTrySamples = quizFirstTryIsHitSamples.GetSampleValues(xValue, group);
			if (firstTrySamples.length == 0) return 0;
			const hitCount = firstTrySamples.filter(a=>a).length;
			return ((hitCount / firstTrySamples.length) * 100).RoundTo(1);
		});
	} else if (yType == StatsYType.quizTimeTillSuccess) {
		yValues = xTicks.map(xValue=>{
			const vals = quizTimeTillSuccessSamples.GetSampleValues(xValue, group);
			return (keepMiddleXPercent(vals) / 1000).RoundTo(.1);
		});
	} else if (yType == StatsYType.linkVisualizations_sum) {
		yValues = xTicks.map(xValue=>linkVisualizationExistenceSamples.GetSampleValues(xValue, group).length);
	} else {
		Assert(false, "Invalid yType.");
	}
	//console.log("YValues:", yValues, "aggData:", aggregationData);

	const valMultiplier = GetValMultiplierForGroupMetricNormalization(uiState, group, yType);
	if (valMultiplier != 1) {
		yValues = yValues.map(val=>(val * valMultiplier).RoundTo(1));
	}

	if (uiState.renderType != "bars" && uiState.smoothing != null) {
		yValues = SmoothLine(yValues, uiState.smoothing) as number[];
	}

	return yValues;
}

export function GetValMultiplierForGroupMetricNormalization(uiState: JourneyStatsState, group: string, metric: StatsYType | "lucidityRate") {
	if (uiState.normalizeGroupMetrics && uiState.grouping == StatsGrouping.alarmDelay) {
		const alarmDelayStandard = uiState.normalizeGroupMetrics_alarmDelay;
		const alarmDelayForCurrentGroup = Number(group);
		const metricMakesSenseToNormalize = [StatsYType.termsInShortText, StatsYType.termsInLongText, "lucidityRate"].includes(metric);
		if (metricMakesSenseToNormalize) {
			// if our wake-delay is 40mins, and the "standard" is 80mins, multiply our values by 2
			// (to normalize them, eg. so user can extrapolate results to "total amount expected per 8-hour night")
			const valMultiplier = alarmDelayStandard / alarmDelayForCurrentGroup;
			return valMultiplier;
		}
	}
	return 1;
}

export function SmoothLine(values: number[], smoothing: number, smoothType = "centered" as "centered" | "previous") {
	return values.map((value, index)=>{
		//if (values[i] == null) continue;
		if (value == null) return null;
		if (isNaN(value)) return null; // correct?
		const valuesToAverage = smoothType == "centered"
			? values.slice((index - ((smoothing / 2) + (smoothing % 2))).KeepAtLeast(0), index + (smoothing / 2) + 1)
			: values.slice((index - smoothing).KeepAtLeast(0), index + 1);
		return valuesToAverage.filter(a=>a != null && !isNaN(a) && a != Infinity && a != -Infinity).Average();
	});
}