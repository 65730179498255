import {FBAConfig_ConceptLink} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_ConceptLink.js";
import {DreamQuiz_QuestionType, FBAConfig_DreamQuiz} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamQuiz.js";
import {EstimateDreamStartTime, GetDreamEvents, GetSegmentToDreamMap, GetValidQuizSegments} from "../../../Store/firebase/journalEntries.js";
import {JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {NarrateText_ForEngineComp} from "../../../Utils/Services/TTS.js";
import {FBASession, TriggerPackage} from "../../FBASession.js";
import {DreamQuizSubcomp, FindFreshRange} from "./DreamQuiz/DreamQuizSubcomp.js";
import {SoundQuizSubcomp} from "./DreamQuiz/SoundQuizSubcomp.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {autorun} from "mobx";
import {Range, SleepAsync} from "js-vextensions";
import {AlarmsComp, AlarmsPhase} from "./AlarmsComp.js";
import {AudioFileEntry, PlaySound_ByContentUri, PlaySound_StopAll} from "../../../Utils/Bridge/Bridge_Native/MediaPlayer.js";
import {EffectPointerPlayer} from "../../../Store/firebase/@Shared/EffectPointerPlayer.js";
import {GetAudioFilesWithSubpath, GetCache} from "../../../Store/main/cache.js";
import {MeID} from "../../../Store/firebase/users.js";
import {GetDayOffset} from "../../../UI/Tools/Journey/JourneyStatsUI.js";

export class ConceptLinkComp extends EngineSessionComp<FBAConfig_ConceptLink> {
	constructor(session: FBASession, config: FBAConfig_ConceptLink) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
		
		this.dbAutorun_disposer = autorun(()=>{
			this.validSegmentsForSequences_raw = GetValidQuizSegments(this.c.lucidSegmentsOnly, this.c.segmentMinEvents, this.c.eventMaxWords, DreamQuiz_QuestionType.isPresent);
			const segmentToDreamMap = GetSegmentToDreamMap(MeID());
			this.validSegmentsForSequences_withBoosts = this.validSegmentsForSequences_raw.SelectMany(segment=>{
				let segmentBoost = 1;

				const dream = segmentToDreamMap.get(segment);
				if (dream) {
					const dreamTime = EstimateDreamStartTime(dream);
					const segmentDayOffset = GetDayOffset(dreamTime);
					for (const [recencyStr, boostValue] of Object.entries(this.c.recencyBoosts)) {
						const recency = parseInt(recencyStr);
						if (Math.abs(segmentDayOffset) <= recency && boostValue > segmentBoost) {
							segmentBoost = boostValue;
						}
					}
				}

				const copiesOfSegment = [] as JournalSegment[];
				for (let i = 0; i < segmentBoost; i++) {
					copiesOfSegment.push(segment);
				}
				return copiesOfSegment;
			});
		});
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("ConceptLink_NextSequence", this.c.nextSequence_triggerSet, this, {}, async triggerInfo=>{
				//this.sequencesSelected_currentIndex = (this.sequencesSelected_currentIndex + 1).KeepBetween(0, this.sequencesSelected.length - 1);
				this.EnsureVisualizerNotPlaying();

				// todo: make-so we only generate new sequence if at end of list
				this.SelectNewSequenceAsCurrent();
				
				this.NarrateCurrentSequence();
				this.TryToSolve(0);
			}),
			new TriggerPackage("ConceptLink_PrevSequence", this.c.prevSequence_triggerSet, this, {}, async triggerInfo=>{
				this.EnsureVisualizerNotPlaying();
				this.sequencesSelected_currentIndex = (this.sequencesSelected_currentIndex - 1).KeepBetween(0, this.sequencesSelected.length - 1);
				this.TryToSolve(0);
				this.NarrateCurrentSequence();
			}),
			new TriggerPackage("ConceptLink_PlayAudio", this.c.playAudio_triggerSet, this, {}, async triggerInfo=>{
				this.PlayAudioFileForVisualization();
			}),
			new TriggerPackage("ConceptLink_RandomizeAudio", this.c.randomizeAudio_triggerSet, this, {}, triggerInfo=>{
				this.SelectNewAudioFile();
				this.PlayAudioFileForVisualization();
			}),
		];
	}

	OnStart() {
		this.effectPlayer.effectPointer = this.c.effect2;
		this.effectPlayer.OnStart();
		this.SelectNewSequenceAsCurrent();
		this.SelectNewAudioFile();
	}
	OnStop() {
		this.effectPlayer.Stop();
		this.dbAutorun_disposer();
	}

	async NarrateText(text: string, volumeMultiplier = 1) {
		await NarrateText_ForEngineComp(this, text, this.c.volumeMultiplier * volumeMultiplier, this.c.voiceSoundTag);
	}

	// players
	effectPlayer = new EffectPointerPlayer();

	// sequences
	dbAutorun_disposer: ()=>void;
	validSegmentsForSequences_raw: JournalSegment[];
	validSegmentsForSequences_withBoosts: JournalSegment[];
	sequencesSelected = [] as EventSequence[];
	sequencesSelected_currentIndex = -1;
	sequencesSelected_visualized = new Set<number>();

	// audio files
	currentAudioContentUri: string|n;

	// solver
	cycle_visualizationsMade = 0;
	OnLeavePhase_Sleep(newPhase: AlarmsPhase): void {
		this.cycle_visualizationsMade = 0;
	}

	NarrateCurrentSequence() {
		const sequence = this.sequencesSelected[this.sequencesSelected_currentIndex];
		if (sequence) {
			const text = sequence.items.join("; THEN: ");
			this.NarrateText(text);
		}
	}

	SelectNewSequenceAsCurrent() {
		const onFailed = ()=>{
			throw new Error(`
				Failed to find valid next sequence.
				Do you have enough journal entries?
			`.AsMultiline(0));
		};
		if (this.validSegmentsForSequences_withBoosts.length == 0) onFailed();

		let loopSucceeded = false;
		tryLoop: for (let tryIndex = 0; tryIndex < 10; tryIndex++) {
			const selectedSegment = this.validSegmentsForSequences_withBoosts.Random();
			const selectedSegmentEvents = GetDreamEvents(selectedSegment, this.c.eventMaxWords);

			const newSequence = FindFreshRange(selectedSegmentEvents, this.c.sequenceSize, []);
			if (newSequence == null) continue tryLoop;
			const events = Range(newSequence.first, newSequence.after, 1, false).map(i=>selectedSegmentEvents[i]);
			this.sequencesSelected.push(new EventSequence(events));
			this.sequencesSelected_currentIndex = this.sequencesSelected.length - 1;

			loopSucceeded = true;
			break;
		}
		if (!loopSucceeded) onFailed();
	}

	SelectNewAudioFile() {
		const audioFiles_visualizers = GetAudioFilesWithSubpath(this.c.soundFolder);
		this.currentAudioContentUri = audioFiles_visualizers.Random()?.contentUri;
	}
	effect2_waitBuildup = 0;
	async PlayAudioFileForVisualization() {
		if (this.sequencesSelected_currentIndex == -1) return;

		// add event for target visualization
		if (!this.sequencesSelected_visualized.has(this.sequencesSelected_currentIndex)) {
			this.s.AsLocal!.AddEvent({type: "ConceptLink.TargetVisualized"});
			this.sequencesSelected_visualized.add(this.sequencesSelected_currentIndex);
			this.cycle_visualizationsMade++;
			this.TryToSolve(1);
		}

		// do this check *after* marking the target as visualized
		// (some may prefer simply pressing a button to notify of visualization done internally; also makes testing panel work on PC)
		if (this.currentAudioContentUri == null) return;

		await PlaySound_ByContentUri(this.currentAudioContentUri, this.c.soundVolume, this.c.soundDurationLimit, false);

		let timeToPlayEffect2 = false;
		if (this.c.effect2_chanceAsBuildup) {
			this.effect2_waitBuildup = (this.effect2_waitBuildup + this.c.effect2_chance).RoundTo(this.c.effect2_chance);
			if (this.effect2_waitBuildup >= 1) {
				this.effect2_waitBuildup -= 1;
				timeToPlayEffect2 = true;
			}
		} else {
			timeToPlayEffect2 = Math.random() < this.c.effect2_chance;
		}
		if (timeToPlayEffect2) {
			await SleepAsync(1000 * this.c.effect2_delay);
			// todo: find way to avoid playing this effect2, if micro-snooze happened during the wait period
			await this.effectPlayer.Play();
		}
	}
	EnsureVisualizerNotPlaying() {
		PlaySound_StopAll();
	}

	TryToSolve(newVisualizationsMade: number) {
		const alarmsComp = this.s.Comp(AlarmsComp);
		if (this.cycle_visualizationsMade < this.c.solver_minSequences) {
			if (this.c.solver_markProgressBeforeCompletion) {
				alarmsComp.NotifySolveProgress(this.c.solver_maxTimeForProgress);
			}
			return;
		}

		// If we make it here, then the solving has completed!
		// ==========

		alarmsComp.NotifySolveCompleted(this.c.solver_maxTimeForProgress);
		this.cycle_visualizationsMade = 0;
	}
}

class EventSequence {
	constructor(items: string[]) {
		this.items = items;
	}
	items: string[];
}