import {InfoButton, TextPlus} from "web-vcore";
import {E} from "js-vextensions";
import {Button, CheckBox, Column, Row, RowLR, Spinner, Text, TextInput, TimeSpanInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {PromptPanel_splitAt} from "../Alarms_ConfigUI.js";
import {AlarmSequence, AlarmsGroup, AlarmType, GetAlarmSequenceFromAlarmGroup, GetEffectGroupForAlarmType} from "../../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@AlarmConfig.js";
import {FBAConfig} from "../../../../../Store/firebase/fbaConfigs/@FBAConfig.js";
import {FBAConfig_XAlarm} from "../../../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_Alarms.js";
import {FBAConfig_SoundAlarm} from "../../../../../Store/firebase/fbaConfigs/@EngineConfig/Alarms/@EC_BasicAlarms.js";
import {EffectPreviewButton, EffectPreviewButton_AutoGetEntry} from "../../../../@Shared/TagTargetEntryUI.js";
import {colors} from "../../../../../Utils/UI/GlobalStyles.js";
import {FBASessionPanels_SharedProps} from "../../FBAConfigPanel_Local.js";
import {EffectPointerInput} from "../../../../@Shared/EffectPointerInput.js";

export type AlarmUI_SharedProps = FBASessionPanels_SharedProps & {
	alarm: FBAConfig_XAlarm, index: number, group: AlarmsGroup,
	alarms: FBAConfig_XAlarm[], ChangeAlarm: (alarmChangerFunc: (prompt: FBAConfig_XAlarm)=>void)=>void,
};

export class AlarmUI extends BaseComponent<{alarm: FBAConfig_XAlarm, index: number, group: AlarmsGroup, sequence: AlarmSequence, sequenceIndex: number} & FBASessionPanels_SharedProps, {}> {
	render() {
		const {alarm, index, group, sequence, sequenceIndex, client, enabled, config_full, ChangeConfig_Full} = this.props;
		const ChangeAlarm = (alarmChangerFunc: (config: FBAConfig_XAlarm)=>any)=>{
			ChangeConfig_Full(c=>{
				const newAlarms = GetAlarmSequenceFromAlarmGroup(group, c, sequenceIndex).alarms;
				alarmChangerFunc(newAlarms[index]);
			});
		};

		const splitAt = PromptPanel_splitAt;
		const sharedProps = E(this.props.ExcludeKeys("key" as any), {alarms: sequence.alarms, ChangeAlarm}) as AlarmUI_SharedProps;
		return (
			<Column mt={index == 0 ? 0 : 5} p={5} style={{background: "rgba(0,0,0,.3)", borderRadius: 5}}>
				<Row style={{fontSize: 15}}>
					<CheckBox text={`${AlarmType[alarm.type]} alarm`} enabled={enabled} value={alarm.enabled} onChange={val=>ChangeAlarm(a=>a.enabled = val)}/>
					<Button ml={5} faIcon="arrow-up" title="Move up" enabled={enabled && index > 0} onClick={()=>{
						ChangeConfig_Full(c=>{
							const newAlarms = GetAlarmSequenceFromAlarmGroup(group, c, sequenceIndex).alarms;
							newAlarms.Move(newAlarms[index], index - 1);
						});
					}}/>
					<Button ml={5} faIcon="arrow-down" title="Move down" enabled={enabled && index < sequence.alarms.length - 1} onClick={()=>{
						ChangeConfig_Full(c=>{
							const newAlarms = GetAlarmSequenceFromAlarmGroup(group, c, sequenceIndex).alarms;
							newAlarms.Move(newAlarms[index], index + 1);
						});
					}}/>
					<Button ml={5} faIcon="trash" title="Delete" enabled={enabled} onClick={()=>{
						ShowMessageBox({
							title: "Delete prompt?", cancelButton: true,
							message: `Permanently delete this prompt?`,
							onOK: ()=>{
								ChangeConfig_Full(c=>{
									const newAlarms = GetAlarmSequenceFromAlarmGroup(group, c, sequenceIndex).alarms;
									newAlarms.RemoveAt(index);
								});
							},
						});
					}}/>
				</Row>
				{alarm.enabled &&
				<Column ml={15}>
					{!client &&
					<>
						{group == "eeg" &&
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Start at eeg-activity:</Text>
							<Spinner enabled={enabled} value={alarm.startOffset} onChange={val=>ChangeAlarm(a=>a.startOffset = val)}/>
						</RowLR>}
						{group == "alarms" &&
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Start-time offset:</Text>
							<TimeSpanInput enabled={enabled} value={alarm.startOffset} onChange={val=>ChangeAlarm(a=>a.startOffset = val)}/>
						</RowLR>}
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								Note: -1 means no auto-end.
								Note: This "auto-end" does NOT interrupt the auto-restart timer.
							`.AsMultiline(0)}>Auto-end after:</TextPlus>
							<Spinner enabled={enabled} min={-1} value={alarm.autoEndAfter} onChange={val=>ChangeAlarm(a=>a.autoEndAfter = val)}/>
							<Text>s</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info={`
								Note: -1 means no auto-restart.
							`.AsMultiline(0)}>Auto-restart after:</TextPlus>
							<Spinner enabled={enabled} min={-1} value={alarm.autoRestartAfter} onChange={val=>ChangeAlarm(a=>a.autoRestartAfter = val)}/>
							<Text>s</Text>
						</RowLR>

						<RowLR mt={5} splitAt={splitAt}>
							<Text style={{color: colors.nightTint}}>Effect:</Text>
							<EffectPointerInput enabled={enabled} value={alarm.effectPointer} onChange={val=>ChangeAlarm(a=>a.effectPointer = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info="(If <= 0, the effect is only played once, at the point of alarm's start-offset)">Effect interval:</TextPlus>
							<Spinner enabled={enabled} min={-1} value={alarm.effectInterval} onChange={val=>ChangeAlarm(a=>a.effectInterval = val)}/>
							<Text>s</Text>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<Text>Intensity, start:</Text>
							<Spinner enabled={enabled} min={0} value={alarm.intensityStart} onChange={val=>ChangeAlarm(a=>a.intensityStart = val)}/>
						</RowLR>
						<RowLR mt={5} splitAt={splitAt}>
							<TextPlus info="Note: To use this, effect-interval must be more than 0.">Intensity, fade-in:</TextPlus>
							<CheckBox enabled={enabled} value={alarm.fadeIn_enabled} onChange={val=>ChangeAlarm(a=>a.fadeIn_enabled = val)}/>
						</RowLR>
						{alarm.fadeIn_enabled &&
						<Column mt={5} ml={10}>
							<RowLR mt={5} splitAt={splitAt - 10}>
								<Text>Intensity, end:</Text>
								<Spinner enabled={enabled} min={0} value={alarm.fadeIn_intensityEnd} onChange={val=>ChangeAlarm(a=>a.fadeIn_intensityEnd = val)}/>
							</RowLR>
							<RowLR mt={5} splitAt={splitAt - 10}>
								<Text>Fade-in duration:</Text>
								<Spinner enabled={enabled} min={0} value={alarm.fadeIn_duration} onChange={val=>ChangeAlarm(a=>a.fadeIn_duration = val)}/>
								<Text>s</Text>
							</RowLR>
							<RowLR mt={5} splitAt={splitAt - 10}>
								<Row>
									<CheckBox enabled={enabled} text="Fade-in curve" value={alarm.fadeIn_curve != 0} onChange={val=>{
										ChangeAlarm(a=>a.fadeIn_curve = val ? 1 : 0);
									}}/>
									<InfoButton ml={5} text={`
										1 = "linear" (code-wise; perception-wise this is usually non-linear!)
										<1 = faster increases toward start
										>1 = faster increases toward end (recommended: 2)
										See: https://ux.stackexchange.com/a/116300
									`.AsMultiline(0)} style={{fontSize: 12}}/>
								</Row>
								{alarm.fadeIn_curve != 0 &&
									<Spinner enabled={enabled} min={0} max={10} step={0.1} value={alarm.fadeIn_curve} onChange={val=>ChangeAlarm(a=>a.fadeIn_curve = val)}/>}
							</RowLR>
						</Column>}
					</>}
				</Column>}
			</Column>
		);
	}
}