import uPlot from "uplot";
import {Annotation, AnnotationsPlugin, GroupedBarsPlugin, GroupedBarsPluginOptions} from "uplot-vplugins";
import {uplotDefaults} from "web-vcore";
import chroma from "chroma-js";
import {store} from "../../../../../Store/index.js";
import {SamplesGraphYType_Label} from "./SamplesGraph.js";
import {summarySamples_countPerSecond} from "../../../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";

export function SamplesGraph_XValueToLabel(xValue: number) {
	// while the tick-values themselves are the summary-sample offsets, we display them as seconds on the axes
	return (xValue / 16).FloorTo(1).toString();
}

export function Samples_GetChartOptions(args: {
	width: number, height: number, series: uPlot.Series[], annotations: Annotation[],
	xValues: number[],
}) {
	const {width, height, series, annotations, xValues} = args;
	const uiState = store.main.settings.transcribe;
	const legendHeight = 33; // from dev-tools

	//const yScales = series.filter(a=>a.scale != "x").map(a=>a.scale as string).Distinct(); //.OrderBy(a=>a);
	const yScales = ["y"];

	const chartOptions: uPlot.Options = {
		id: `samples_chart`,
		class: "samples-chart",
		width,
		height: height - legendHeight,
		plugins: [
			AnnotationsPlugin({
				annotations,
			}),
		] as uPlot.Plugin[],
		cursor: {
			drag: {x: false, setScale: false},
		},
		axes: [
			{
				...uplotDefaults.axis_props_base,
				scale: "x",
				label: "Sample offset [s]",
				//time: false,

				// label section
				labelFont: "bold 12px Arial",
				labelSize: 12,
				values: (self, ticks)=>ticks.map(xValue=>SamplesGraph_XValueToLabel(xValue)),

				// ticks section
				font: "9px Arial",
				size: 25,
				gap: 3,
				space: 20,
				incrs: [summarySamples_countPerSecond, summarySamples_countPerSecond * 2],
			},
			...yScales.map((scale, i)=>{
				const scaleSeries = series.filter(a=>a.scale == scale);
				const base: uPlot.Axis = {
					...uplotDefaults.axis_props_base,
					scale,
					side: i == 0 ? 3 : 1,
					
					// label section
					labelFont: "bold 12px Arial",
					labelSize: 12,

					// ticks section
					font: "9px Arial",
					size: 40,
					gap: 3,
					space: 20,
					//incrs: [1, 5, 10, 50, 100, 250, 500, 1000, 5000, 10000],
				};

				/*if (scale?.startsWith("y_")) {
					const scaleMax = parseFloat(scale.slice(2));
					return {
						...base,
						label: `${scaleSeries.map(a=>a.label).join(", ")}`, // (max: ${scaleMax})`,
						// if max-val is 3-digits or less, we can max this scale's display-area take even less width
						size: scaleMax <= 999 ? 30 : 40,
						stroke: series.length > 2 ? chroma.average(scaleSeries.map(a=>a.stroke + "")).css() : "white",
					};
				}*/

				return {
					...base,
					label: SamplesGraphYType_Label(uiState.yType),
					stroke: series.length > 2 ? chroma.average(scaleSeries.map(a=>a.stroke + "")).css() : "white",
				};
			}),
		],
		scales: {
			x: {
				time: false,
			},
			...yScales.ToMapObj(scaleName=>scaleName as string, (scaleName, i)=>{
				return {
					range: (u, dataMin, dataMax)=>{
						/*if (scaleName?.startsWith("y_")) {
							const scaleMax = parseFloat(scaleName.slice(2));
							return [0, scaleMax];
						}*/

						let dataExtreme = Math.max(Math.abs(dataMin), Math.abs(dataMax)); 
						return [-dataExtreme, dataExtreme];
					},
				};
			}),
		},
		series,
	};

	// reuse/hide x-values series in legend
	chartOptions.hooks ??= {};
	chartOptions.hooks.init = [
		u=>{
			const legendBoxes = [...u.root.querySelectorAll(".u-legend .u-series")] as HTMLElement[];
			legendBoxes[0].style.display = "none";
		},
	];

	return chartOptions;
}