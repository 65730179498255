import {BaseComponent, GetInnerComp} from "react-vextensions";
import {Button, Div, Row, Column, Spinner, Select, CheckBox, TextArea, Pre} from "react-vcomponents";
import {ActivityDetailsUI} from "../../UI/Activities/ActivityDetailsUI";
import {ShowMessageBox, BoxController} from "react-vmessagebox";
import {Activity} from "./../../Store/firebase/activities/@Activity";
import {AddActivity} from "./../../Server/Commands/AddActivity";
import {GetUser, MeID} from "./../../Store/firebase/users";

export function ShowAddActivityDialog() {
	let newActivity = new Activity({name: "", color: "128,128,128,1"});

	let detailsUI: ActivityDetailsUI|n;
	let error = null;
	const Change = (..._)=>boxController.UpdateUI();
	let boxController: BoxController = ShowMessageBox({
		title: `Add activity`, cancelButton: true,
		message: ()=>{
			boxController.UpdateOptions({okButtonProps: {enabled: error == null}});
			return (
				<Column style={{padding: `10px 0`, width: 600}}>
					<ActivityDetailsUI ref={c=>detailsUI = c} baseData={newActivity} forNew={true}
						onChange={val=>Change(newActivity = val, error = detailsUI!.GetValidationError())}/>
					{error && error != "Please fill out this field." && <Row mt={5} style={{color: "rgba(200,70,70,1)"}}>{error}</Row>}
				</Column>
			);
		},
		onOK: ()=>{
			new AddActivity({activity: newActivity}).Run();
		},
	});
}