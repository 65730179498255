import {E} from "js-vextensions";
import {Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ShowMessageBox} from "react-vmessagebox";
import {DefaultSkin, minuteInMS, Observer, secondInMS} from "web-vcore";
import Moment from "moment";
import React from "react";
import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import {EventGroup, EventGroupWasRehearsalCycle} from "../../../Store/firebase/@Shared/DreamPlusSessionUtils.js";

@Observer
export class DreamEventsUI extends BaseComponent<{events: string[]}, {}> {
	render() {
		let {events} = this.props;

		if (events.length == 0) return null;
		return (
			<Row style={{
				position: "relative", flexWrap: "wrap",
				fontFamily: DefaultSkin.main.MainFont(), // include Symbola font, to avoid the system-font's overly-distracting colored emojis
			}}>
				{events.map((event, index)=>{
					return <DreamEventUI key={index} event={event} events={events}/>;
				})}
			</Row>
		);
	}
}

export const DreamEventUI = (props: {event: string, events: string[]})=>{
	const {event, events} = props;
	//const index = events.indexOf(event);

	const baseStyle = {
		marginLeft: 3, padding: "0 3px", borderRadius: 5,
		background: "rgba(0,0,0,.5)", border: "1px solid rgba(255,255,255,.3)",
		gap: 3,
		height: "fit-content", // fixes height issue (where height increases to match height of left-anchored action-button)
	};

	return <Row style={E(
		baseStyle,
		{cursor: "pointer"},
	)}>
		{event}
	</Row>;
};