import {GetEntries} from "js-vextensions";
import React from "react";
import {Button, CheckBox, Column, Row, RowLR, Select, Spinner, Text} from "react-vcomponents";
import {BaseComponent, BaseComponentPlus} from "react-vextensions";
import {InfoButton, Observer, RunInAction_Set, TextPlus} from "web-vcore";
import {store} from "../../../../Store/index.js";
import {useAutoUnregisterWhisperTranscriptionWatcher, WhisperTranscriptionWatcher} from "../../../../Utils/Bridge/Whisper.js";
import {ClearSummarySamplesBuffer} from "../../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";
import {SamplesGraph} from "./Transcribe/SamplesGraph.js";
import {TranscribeSettingsSubpanel} from "../../../../Store/main/settings.js";
import {ScrollView} from "react-vscrollview";
import {ShowMessageBox} from "react-vmessagebox";
import {InAndroid, nativeBridge} from "../../../../Utils/Bridge/Bridge_Native.js";

@Observer
export class SettingsUI_Transcribe extends BaseComponentPlus({}, {}) {
	render() {
		const uiState = store.main.settings.transcribe;
		return (
			<>
				<Row mt={5}>
					<Select displayType="button bar" options={GetEntries(TranscribeSettingsSubpanel, "ui")} value={uiState.subpanel} onChange={val=>uiState.subpanel = val}/>
				</Row>
				{uiState.subpanel == TranscribeSettingsSubpanel.config && <ConfigSubpanel/>}
				{uiState.subpanel == TranscribeSettingsSubpanel.model && <ModelSubpanel/>}
				{uiState.subpanel == TranscribeSettingsSubpanel.test && <TestSubpanel/>}
			</>
		);
	}
}

const splitAt = 200;

@Observer
class ConfigSubpanel extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.settings.transcribe;
		return (
			<>
				<RowLR splitAt={splitAt - 10}>
					<TextPlus info={`
						Min duration of chunk to send to whisper to transcribe.
					`.AsMultiline(0)}>Chunk, min duration:</TextPlus>
					<Spinner min={1} enforceRange={true} value={uiState.chunk_minDuration} onChange={val=>RunInAction_Set(this, ()=>uiState.chunk_minDuration = val)}/>
					<Text>s</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt - 10}>
					<TextPlus info={`
						Max duration of chunk to send to whisper to transcribe. (30s is the hard-max, for technical reasons)
						Recommendation: If you silence-detection isn't working well (and you don't like delayed chunk-completions), you can set this lower. (eg. 15s instead of 30s)
					`.AsMultiline(0)}>Chunk, max duration:</TextPlus>
					<Spinner min={1} max={30} enforceRange={true} value={uiState.chunk_maxDuration} onChange={val=>RunInAction_Set(this, ()=>uiState.chunk_maxDuration = val)}/>
					<Text>s</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt - 10}>
					<TextPlus info={`
						This constraint is unique, in that it does not affect the chunk-selection process. Rather:
						* Once a chunk is about to be sent for transcription, this constraint is checked.
						* If the constraint is not matched, then the chunk is simply discarded.
						Purpose: Reduce cpu load by avoiding the processing of chunks that are too quiet to plausibly contain words.
						Recommendation: Leave this at 0% (ie. sending all chunks for actual whisper-api recognition), *unless* you are noticing cpu-load, overheat, etc. issues.
					`.AsMultiline(0)}>Chunk, min peak volume:</TextPlus>
					<Spinner min={0} max={100} step={0.1} value={uiState.chunk_minPeakVolume?.ToPercent(.01)} onChange={val=>RunInAction_Set(this, ()=>uiState.chunk_minPeakVolume = val.FromPercent())}/>
					<Text>%</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt - 10}>
					<TextPlus info={`
						How long a period of "silence" to monitor for, before sectioning off the current buffer, as a new "chunk" to send for transcription.
						Note: For this to happen, the "silence" and "chunk" periods must also have their other constraints satisfied. (silence-max-volume and chunk-min-duration)
						Exception: If buffer reaches chunk-max-duration, a chunk is forcibly selected -- based on the quietest "silence period" candidate that results in a valid chunk-duration.
					`.AsMultiline(0)}>Silence, min duration:</TextPlus>
					<Spinner min={0} value={uiState.silence_minDuration} onChange={val=>RunInAction_Set(this, ()=>uiState.silence_minDuration = val)}/>
					<Text>s</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt - 10}>
					<Text>Silence, max peak volume:</Text>
					<Spinner min={0} max={100} step={0.1} value={uiState.silence_maxPeakVolume?.ToPercent(.01)} onChange={val=>RunInAction_Set(this, ()=>uiState.silence_maxPeakVolume = val.FromPercent())}/>
					<Text>%</Text>
				</RowLR>
				<RowLR mt={5} splitAt={splitAt - 10}>
					<TextPlus info={`
						Excludes sound-effect notations like "[Music]" or "[Laughter]" from the transcription text.
					`.AsMultiline(0)}>Exclude effect notations:</TextPlus>
					<CheckBox value={uiState.excludeEffectNotations} onChange={val=>RunInAction_Set(this, ()=>uiState.excludeEffectNotations = val)}/>
				</RowLR>
				<Row mt={5}>
					<TextPlus info={`
						Once the command to speak a text is sent, the transcription system will ignore "hearing" those words until the utterance has completed.
						This buffer value adjusts the end-point of the ignore period. (higher means wait longer before allowing transcribing those words again)
						Note: If you want to disable this feature, just set this to a very negative value (eg. -99).
						Recommendation: For now, should be set pretty high, like 3s or higher. (there are apparently some timing imprecisions that makes 0s not work well; needs investigation)
					`.AsMultiline(0)}>{`Avoid-transcribe buffer (for voiced word):`}</TextPlus>
					<Spinner ml={5} step={.1} value={uiState.voice_avoidTranscribePadding} onChange={val=>RunInAction_Set(this, ()=>uiState.voice_avoidTranscribePadding = val)}/>
					<Text>s</Text>
				</Row>
			</>
		);
	}
}

class ModelInfo {
	static NewRow(modelName: string, diskSize: number, memUsage: number) {
		return [modelName, ModelInfo.New(modelName, diskSize, memUsage)] as const;
	}
	static New(modelName: string, diskSize: number, memUsage: number) {
		const result = new ModelInfo();
		result.name = modelName;
		result.diskSize = diskSize;
		result.memUsage = memUsage;
		return result;
	}
	name: string;
	diskSize: number;
	memUsage: number;
};
const whisperModelInfos: Map<string, ModelInfo> = new Map([
	ModelInfo.NewRow("ggml-tiny-q5_1.bin",      75, 390),
	ModelInfo.NewRow("ggml-tiny.bin",           75, 390),
	ModelInfo.NewRow("ggml-tiny.en-q5_1.bin",   75, 390),
	ModelInfo.NewRow("ggml-tiny.en-q8_0.bin",   75, 390),
	ModelInfo.NewRow("ggml-tiny.en.bin",        75, 390),
	ModelInfo.NewRow("ggml-base-q5_1.bin",      142, 500),
	ModelInfo.NewRow("ggml-base.bin",           142, 500),
	ModelInfo.NewRow("ggml-base.en-q5_1.bin",   142, 500),
	ModelInfo.NewRow("ggml-base.en.bin",        142, 500),
	ModelInfo.NewRow("ggml-small-q5_1.bin",     466, 1000),
	ModelInfo.NewRow("ggml-small.bin",          466, 1000),
	ModelInfo.NewRow("ggml-small.en-q5_1.bin",  466, 1000),
	ModelInfo.NewRow("ggml-small.en.bin",       466, 1000),
	ModelInfo.NewRow("ggml-medium-q5_0.bin",    1500, 2600),
	ModelInfo.NewRow("ggml-medium.bin",         1500, 2600),
	ModelInfo.NewRow("ggml-medium.en-q5_0.bin", 1500, 2600),
	ModelInfo.NewRow("ggml-medium.en.bin",      1500, 2600),
	ModelInfo.NewRow("ggml-large-v1.bin",       2900, 4700),
	ModelInfo.NewRow("ggml-large-v2-q5_0.bin",  2900, 4700),
	ModelInfo.NewRow("ggml-large-v2.bin",       2900, 4700),
	ModelInfo.NewRow("ggml-large-v3-q5_0.bin",  2900, 4700),
	ModelInfo.NewRow("ggml-large-v3.bin",       2900, 4700),
]);

@Observer
class ModelSubpanel extends BaseComponentPlus({}, {installedModelNames: [] as string[], activeModel: "" as string}) {
	async ComponentDidMount() {
		this.RefreshModels();
	}
	async RefreshModels() {
		const devices = navigator.mediaDevices ? await navigator.mediaDevices.enumerateDevices() : [];
		const {installedModelNames, activeModel} = (await nativeBridge.Call("WhisperGetInstalledModels")) as any;
		this.SetState({installedModelNames, activeModel});
	}
	
	render() {
		const {installedModelNames, activeModel} = this.state;
		const uiState = store.main.settings.transcribe;
		return (
			<>
				<Row center mt={5}>
					<Text>Whisper (speech to text) models:</Text>
				</Row>
				<ScrollView style={{flex: 1}}>
					<table>
						<thead>
							<tr>
								<th>Model</th>
								<th>Disk (mb)</th>
								<th>Mem (mb)</th>
								<th>Installed</th>
								<th>Use</th>
							</tr>
						</thead>
						<tbody>
							{[...whisperModelInfos.entries()].map((entry, index)=>{
								const info = entry[1];
								const installed = installedModelNames.includes(info.name);
								const active = info.name == activeModel;
								return (
									<tr key={index}>
										<td>{info.name.replace("ggml-", "").replace(".bin", "")}</td>
										<td>{info.diskSize}</td>
										<td>{info.memUsage}</td>
										<td>
											<Button p="0 10px" style={{width: "100%"}} enabled={info.name != "ggml-tiny.en.bin"} text={installed ? "Uninstall" : "Install"} onClick={async()=>{
												const opIsInstall = !installed;
												const proceed = async()=>{
													const callbackID = nativeBridge.RegisterCallback((resultMessage: string)=>{
														ShowMessageBox({message: `Model ${opIsInstall ? "installed" : "uninstalled"}.\nBackend message: ${resultMessage}`});
														this.RefreshModels();
													});
													await nativeBridge.Call(opIsInstall ? "WhisperModelInstall" : "WhisperModelUninstall", callbackID, info.name);
												};
												if (opIsInstall) {
													ShowMessageBox({
														title: "Confirm install", cancelButton: true,
														message: `Are you sure you want to install the "${info.name}" model?\n\nNote: It may take quite a while for the model to download, so be patient; a message will display on completion.`,
														onOK: proceed,
													});
												} else {
													ShowMessageBox({
														title: "Confirm uninstall", cancelButton: true,
														message: `Are you sure you want to uninstall the "${info.name}" model?`,
														onOK: proceed,
													});
												}
											}}/>
										</td>
										<td>
											<CheckBox enabled={installedModelNames.includes(info.name)} value={active} onChange={async val=>{
												if (val) {
													const callbackID = nativeBridge.RegisterCallback(()=>{
														this.RefreshModels();
													});
													await nativeBridge.Call("WhisperSetChosenModel", callbackID, info.name);
												}
											}}/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</ScrollView>
			</>
		);
	}
}

@Observer
class TestSubpanel extends BaseComponent<{}, {}> {
	whisperTranscriptionWatcher = new WhisperTranscriptionWatcher({
		keepsRecordingAlive: true,
		onChunkTranscribed: ({text})=>{
			// we don't actually care about what's transcribed here; we just need this watcher to enable the mic-recorder
		},
	});
	
	render() {
		const uiState = store.main.settings.transcribe;

		useAutoUnregisterWhisperTranscriptionWatcher(this.whisperTranscriptionWatcher, ()=>RunInAction_Set(this, ()=>uiState.samplesGraph_active = false));
		
		return (
			<>
				<Row>
					<Text>Graph, time to show:</Text>
					<Spinner ml={5} step={1} max={30} enforceRange={true} value={uiState.graphTimeToShow} onChange={val=>RunInAction_Set(this, ()=>uiState.graphTimeToShow = val)}/>
					<Text>s</Text>
				</Row>
				<Row>
					<Button text={this.whisperTranscriptionWatcher.IsRegistered() ? "Stop recording" : "Start recording"} onClick={()=>{
						const newActive = !this.whisperTranscriptionWatcher.IsRegistered();
						if (newActive) {
							this.whisperTranscriptionWatcher.Register();
						} else {
							this.whisperTranscriptionWatcher.Unregister("YES, LOSING BUFFERED SAMPLES IS FINE");
						}
						RunInAction_Set(this, ()=>uiState.samplesGraph_active = newActive);
					}}/>
					<Button ml={5} text="Clear" onClick={()=>{
						ClearSummarySamplesBuffer();
						this.Update();
					}}/>
				</Row>
				<SamplesGraph/>
			</>
		);
	}
}