import {secondInMS} from "web-vcore";
import {Assert, Timer} from "js-vextensions";
import React from "react";
import {FBASession} from "../../../Engine/FBASession.js";
import {store} from "../../../Store/index.js";
import {InAndroid} from "../../../Utils/Bridge/Bridge_Native";
import {GetNormalKeyName} from "../../../Utils/General/Keys";
import {ScreenState, Sequence, SequenceItem, TriggerSet, TriggerType} from "./@TriggerSet";
import {AlarmsComp} from "../../../Engine/FBASession/Components/AlarmsComp.js";
import {screenOn} from "../../../Utils/Bridge/Bridge_Native/PhoneSensors.js";

type ReactKeyboardEvent = React.KeyboardEvent<any>;
type ReactMouseEvent = React.MouseEvent<any, MouseEvent>;

export interface TriggerInfo {
	triggerSet: TriggerSet;
	activatedTriggerType: TriggerType;
}

export class DownedKeyInfo {
	constructor(timeOfDowning: number) {
		this.timeOfDowning = timeOfDowning;
	}
	timeOfDowning: number;
	timeOfLastRepeat: number|n;
	otherKeyDownedWhileThisKeyDown = false;
	//timerToCheckForRepeat: Timer|n;
}

export function IsKeyDownStateValid(keyInfo: DownedKeyInfo, now: number) {
	const {keyRepeatThreshold} = store.main.settings;
	const delaySinceDowningOrLastRepeat = now - Math.max(keyInfo.timeOfDowning, keyInfo.timeOfLastRepeat ?? 0);
	// if we're tracking a downed-key, but there hasn't been a "repeat" key-down event within the realistic wait-window, then
	// ...we know the key has not actually been held down this whole time (instead a key-up event was just missed), so treat it as such
	// (we can't safely generate a key-up event, since timing may be off now, but this at least avoids generating a key-hold event)
	return keyRepeatThreshold == -1 || delaySinceDowningOrLastRepeat < keyRepeatThreshold * 1000;
}

export class TriggerSet_Activator {
	constructor(name: string, set: TriggerSet, action: (triggerInfo: TriggerInfo)=>any, session: FBASession) {
		Assert(set != null, "A TriggerSet_Activator must be provided a TriggerSet.");
		this.name = name;
		this.set = set;
		this.action = action;
		this.session = session;
		//this.timerContext = timerContext;
		//this.sequenceActivators = this.set == null ? [] : this.set.sequences.map(sequence=>{
		// only create sequence-activators for sequences that can be activated (ie. have at least 1 item) [otherwise the activator errors]
		this.sequenceActivators = this.set.sequences.filter(a=>a.items.length).map(sequence=>{
			return new Sequence_Activator(this, sequence);
		});
	}

	name: string;
	set: TriggerSet;
	action: (TriggerInfo: TriggerInfo)=>any;
	session: FBASession;
	//timerContext: TimerContext;
	sequenceActivators: Sequence_Activator[] = [];

	ResetStateForSuspend() {
		this.downedKeyInfos.clear();
		this.sequenceActivators.forEach(a=>a.ResetStateForSuspend());
	}

	lastActionActivateTime = 0;
	OnSequenceActivated(type: TriggerType|n) {
		if (Date.now() - this.lastActionActivateTime < this.set.minTriggerInterval * secondInMS) return;
		this.lastActionActivateTime = Date.now();
		const triggerInfo = {triggerSet: this.set, activatedTriggerType: type} as TriggerInfo;
		this.action(triggerInfo);
	}

	// we could add info to distinguish between left/right versions of the same key-name (eg. left/right alt), but not really needed
	downedKeyInfos = new Map<string, DownedKeyInfo>();
	onKeyDown(e: ReactKeyboardEvent) {
		const key = GetNormalKeyName(e.key);
		const now = Date.now();

		let keyInfo = this.downedKeyInfos.get(key);
		// store a new key-down entry/time if slot empty, or if presumed key-hold period turned out to be spurious / due to a missed key-up
		if (keyInfo == null || !IsKeyDownStateValid(keyInfo, now)) {
			keyInfo = new DownedKeyInfo(now);
			this.downedKeyInfos.set(key, keyInfo);
		} else {
			keyInfo.timeOfLastRepeat = now;
		}

		for (const [otherKey, otherInfo] of this.downedKeyInfos.entries()) {
			if (otherKey != key) {
				otherInfo.otherKeyDownedWhileThisKeyDown = true;
			}
		}

		const holdDuration = now - keyInfo.timeOfDowning;
		this.sequenceActivators.forEach(a=>a.onKeyDown(e, holdDuration));
	}
	onKeyUp(e: ReactKeyboardEvent) {
		const key = GetNormalKeyName(e.key);
		const now = Date.now();

		let keyInfo = this.downedKeyInfos.get(key);
		// this can be null if key-down event was missed (eg. due to owner comp being disabled at the time)
		if (keyInfo == null) return;

		// if presumed key-hold period turned out to be spurious, interpret this key-up as an instant key-down-then-key-up
		// (commented; this check does not actually seem needed atm, and just complicates the logic around keyInfo.otherKeyDownedWhileThisKeyDown)
		/*if (!IsKeyDownStateValid(keyInfo, now)) {
			keyInfo = new DownedKeyInfo(now);
			//this.downedKeyInfos.set(key, keyInfo);
		}*/
		
		const holdDuration = now - keyInfo.timeOfDowning;
		this.downedKeyInfos.delete(key);
		this.sequenceActivators.forEach(a=>a.onKeyUp(e, holdDuration, keyInfo!.otherKeyDownedWhileThisKeyDown));
	}
	onMouseDown(e: ReactMouseEvent) { this.sequenceActivators.forEach(a=>a.onMouseDown(e)); }
	onMouseUp(e: ReactMouseEvent) { this.sequenceActivators.forEach(a=>a.onMouseUp(e)); }
	onMouseMove(e: ReactMouseEvent) { this.sequenceActivators.forEach(a=>a.onMouseMove(e)); }
	onMicLoudness(notifyLoudness: number, actualLoudness: number) { this.sequenceActivators.forEach(a=>a.onMicLoudness(notifyLoudness, actualLoudness)); }
	onScreenStateChange(screenState: ScreenState) { this.sequenceActivators.forEach(a=>a.onScreenStateChange(screenState)); }
	onPhoneMotionData(motionAveragesOfChunksFromLast10s: number[]) { this.sequenceActivators.forEach(a=>a.onPhoneMotionData(motionAveragesOfChunksFromLast10s)); }
}
export class Sequence_Activator {
	constructor(setActivator: TriggerSet_Activator, sequence: Sequence) {
		this.setActivator = setActivator;
		this.sequence = sequence;

		this.waitForNextItemActivationTimer = new Timer(store.main.settings.sequence_itemWait * secondInMS, ()=>{
			//this.waitForNextItemActivationTimer.ClearContexts(); // keep timer-list in context from bloating
			this.ResetSequence();
		}, 1).SetContext(setActivator.session.timerContext);
	}

	setActivator: TriggerSet_Activator;
	sequence: Sequence;

	currentItemIndex = 0;
	get CurrentItem() { return this.sequence.items[this.currentItemIndex]; }
	//get CurrentItemTriggerType() { return SequenceItem.GetTriggerType(this.CurrentItem); }

	ResetStateForSuspend() {
		this.ResetSequence();
		this.keyDownPeriodsUsed.clear();
	}
	ResetSequence() {
		this.waitForNextItemActivationTimer.Stop();
		if (this.noKey_waitTimer) this.noKey_waitTimer.Stop();
		this.currentItemIndex = 0;
	}

	waitForNextItemActivationTimer: Timer;
	noKey_waitTimer: Timer;
	OnCurrentItemActivated() {
		// check if screen-state matches that required; if not, don't allow sequence-item activation (only apply this check on Android, since we can't check screen state elsewhere)
		if (this.sequence.screenState != null && InAndroid(0)) {
			const screenWantedOn = this.sequence.screenState == "on";
			if (screenOn != screenWantedOn) return; // ignore any item-activations that occur when screen-on state is incorrect
		}
		// check if journey-phase matches one of those required; if not, don't allow sequence-item activation
		if (this.sequence.journeyPhases?.length) {
			const phase = this.setActivator.session.Comp(AlarmsComp).GetPhase();
			if (!this.sequence.journeyPhases.includes(phase)) return; // ignore any item-activations that occur when journey-phase is incorrect
		}
		
		// maybe temp/needs-reworking: check if prior-keys-down match those required; if not, don't allow sequence-item activation
		// (note: our passing "true" to GetKeysCurrentlyDown does block the ability to "hold one key, then keep pressing other key" as hotkey; this
		// ...is unfortunate, but the lesser of two evils)
		const priorKeysDown = this.GetKeysCurrentlyDown(true)
			.Exclude("F8") // ignore F8 key "being held down", as this can happen (as I found out...) just when using dev-tools "F8" hotkey to pause/resume execution
			.Exclude(...this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames())
			.length > 0;
		const sequence_requireNoPriorKeysDown = true; // atm, just assume that first Key/KeyHold item in sequence requires that no prior keys are down
		if (priorKeysDown && sequence_requireNoPriorKeysDown && this.currentItemIndex == 0) {
			return;
		}

		this.waitForNextItemActivationTimer.Stop();
		// if we just completed the last item, notify that the whole sequence has been activated
		if (this.currentItemIndex == this.sequence.items.length - 1) {
			//this.setActivator.OnSequenceActivated(SequenceItem.GetTriggerType(this.CurrentItem));
			this.setActivator.OnSequenceActivated(this.CurrentItem.type);
			this.ResetSequence();
		} else {
			this.currentItemIndex++;
			if (this.CurrentItem.noKey != null) {
				//if (this.noKey_waitTimer) this.noKey_waitTimer.Stop();
				// wait till period is over, then auto-progress past the step (if a key is pressed during this time, the timer gets stopped and the sequence reset)
				this.noKey_waitTimer = new Timer(this.CurrentItem.noKey * secondInMS, ()=>{
					this.noKey_waitTimer.ClearContexts(); // keep timer-list in context from bloating
					this.OnCurrentItemActivated();
				}, 1).SetContext(this.setActivator.session.timerContext).Start();
			} else {
				// wait for next sequence-item to get activated by an input event; if doesn't occur in time, timer activates, resetting us to the first item/step
				this.waitForNextItemActivationTimer.Start();
			}
		}
	}
	// Call this when the event-data matches the requirements. This function will check whether the *timing* of that data is such that it should actually activate the current item.
	/*TryActivateCurrentItem() {
	}*/

	GetKeysCurrentlyDown(applyKeyRepeatFix: boolean) {
		return [...this.setActivator.downedKeyInfos]
			.filter(a=>applyKeyRepeatFix ? IsKeyDownStateValid(a[1], Date.now()) : true)
			.map(a=>a[0]);
	}
	keyDownPeriodsUsed = new Set<string>();
	onKeyDown(e: ReactKeyboardEvent, holdDuration: number) {
		const key = GetNormalKeyName(e.key);
		if (this.keyDownPeriodsUsed.has(key)) return;

		//if (!["Key", "Keys", "KeyHold"].includes(this.CurrentItem.type)) return;

		const keyEventType_hold = holdDuration >= store.main.settings.keyHoldDuration * 1000;
		if (this.CurrentItem.type == "KeyDown" && holdDuration == 0) {
			if (this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames()[0] == key) { // KeyDown type only ever has one entry
				this.OnCurrentItemActivated();
			}
		}
		/*if (this.CurrentItem.type == "Key" && !keyEventType_hold) {
			this.keyDownsUsed.add(key);
			if (this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames()[0] == key) { // Key type only ever has one entry
				this.OnCurrentItemActivated();
			}
		} else*/
		else if (this.CurrentItem.type == "Keys") {
			const targetKeyNames = this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames();
			// only apply key-repeat fix when Keys is targeting 2 or fewer keys (since 3+ can legitimately have key-down-event repeat-gaps)
			const keysCurrentlyDown = this.GetKeysCurrentlyDown(targetKeyNames.length <= 2);
			const allOurKeysDown = targetKeyNames.every(keyName=>keysCurrentlyDown.includes(keyName));
			if (allOurKeysDown) {
				for (const keyName of targetKeyNames) {
					this.keyDownPeriodsUsed.add(keyName);
				}
				this.OnCurrentItemActivated();
			}
		} else if (this.CurrentItem.type == "KeyHold" && keyEventType_hold) {
			this.keyDownPeriodsUsed.add(key);
			if (this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames()[0] == key) { // KeyHold type only ever has one entry
				this.OnCurrentItemActivated();
			}
		}
	}
	onKeyUp(e: ReactKeyboardEvent, holdDuration: number, otherKeyDownedWhileThisKeyDown: boolean) {
		const key = GetNormalKeyName(e.key);
		const keyDownPeriodUsed = this.keyDownPeriodsUsed.has(key);
		if (keyDownPeriodUsed) {
			this.keyDownPeriodsUsed.delete(key);
			//return; // todo: make sure this being commented is correct
		}
		
		//if (!["Key", "Keys", "KeyHold", "NoKey"].includes(this.CurrentItem.type)) return;
		//if (!["Key", "KeyHold", "NoKey"].includes(this.CurrentItem.type)) return;

		const keyEventType_hold = holdDuration >= store.main.settings.keyHoldDuration * 1000;
		const keyWasHeldAsModifier = otherKeyDownedWhileThisKeyDown;
		if (this.CurrentItem.type == "KeyUp") {
			if (this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames()[0] == key) { // KeyUp type only ever has one entry
				this.OnCurrentItemActivated();
			}
		} else if (this.CurrentItem.type == "Key" && !keyEventType_hold && !keyWasHeldAsModifier) {
			if (this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames()[0] == key) { // Keytype only ever has one entry
				this.OnCurrentItemActivated();
			}
		}
		// Why check that`!keyDownUsed`? If key-down period already triggered something (eg. a key-hold event) within
		// ...this sequence, don't trigger another key-hold event until a new key-down period starts.
		else if (this.CurrentItem.type == "KeyHold" && keyEventType_hold && !keyDownPeriodUsed) {
			if (this.CurrentItem.Cast(SequenceItem).GetNormalKeyNames()[0] == key) { // KeyHold type only ever has one entry
				this.OnCurrentItemActivated();
			}
		} else if (this.CurrentItem.type == "NoKey") {
			// if a key was pressed in the period a key wasn't supposed to be pressed, reset the sequence
			if (this.noKey_waitTimer && this.noKey_waitTimer.Enabled) {
				this.ResetSequence();
				return;
			}
		}
	}
	onMouseDown(e: ReactMouseEvent) {
		if (this.CurrentItem.type != "MouseDown" && this.CurrentItem.type != "MouseClick") return;
		this.OnCurrentItemActivated();
	}
	onMouseUp(e: ReactMouseEvent) {
		if (this.CurrentItem.type != "MouseUp" && this.CurrentItem.type != "MouseClick") return;
		this.OnCurrentItemActivated();
	}
	onMouseMove(e: ReactMouseEvent) {
		if (this.CurrentItem.type != "MouseMove") return;
		this.OnCurrentItemActivated();
	}
	onMicLoudness(notifyLoudness: number, actualLoudness: number) {
		if (this.CurrentItem.type != "MicLoudness") return;
		if (notifyLoudness == this.CurrentItem.micLoudness_loudness) {
			this.OnCurrentItemActivated();
		}
	}
	onScreenStateChange(screenState: ScreenState) {
		if (this.CurrentItem.type != "ScreenChange") return;
		if (screenState == this.CurrentItem.screenChange_state) {
			this.OnCurrentItemActivated();
		}
	}
	onPhoneMotionData(motionAveragesOfChunksFromLast10s: number[]) {
		if (this.CurrentItem.type != "PhoneMotion") return;

		const chunksInWindow_targetCount = this.CurrentItem.checkWindow * 2;
		if (motionAveragesOfChunksFromLast10s.length >= chunksInWindow_targetCount) {
			const chunksInWindow_motionAverages = motionAveragesOfChunksFromLast10s.slice(-chunksInWindow_targetCount);
			const motionAverageOverChunksInWindow = chunksInWindow_motionAverages.Average();
			//console.log("Average over window:", motionAverageOverChunksInWindow);
			if (motionAverageOverChunksInWindow >= this.CurrentItem.minAverageMotion) {
				this.OnCurrentItemActivated();
			}
		}
	}
}