import {GenerateUUID} from "mobx-firelink";
import type {KeyboardEventHandler, PointerEventHandler} from "react";
import {CreateGeneralAudioProcessor, InitAudioNodes, LogWarning, RunInAction, TextSpeaker, VMediaRecorder} from "web-vcore";
import {Assert, E, Timer, TimerContext, Vector2} from "js-vextensions";
import moment from "moment";
import {GetSelectedFBAConfig} from "../Store/firebase/fbaConfigs.js";
import {LaunchType} from "../Store/firebase/fbaConfigs/@EngineConfig/@EC_ModeSwitcher.js";
import {FBAConfig} from "../Store/firebase/fbaConfigs/@FBAConfig.js";
import {ScreenState, TriggerSet} from "../Store/firebase/fbaConfigs/@TriggerSet.js";
import {TriggerInfo, TriggerSet_Activator} from "../Store/firebase/fbaConfigs/@TriggerSet_Activator.js";
import {GetLights_WithUserTag} from "../Store/firebase/lights.js";
import {LightType} from "../Store/firebase/lights/@Light.js";
import {GetScripts_WithUserTag} from "../Store/firebase/scripts.js";
import {GetSounds_WithUserTag} from "../Store/firebase/sounds.js";
import {MeID} from "../Store/firebase/users.js";
import {store} from "../Store/index.js";
import {SessionRecoveryInfo} from "../Store/main/tools/engine.js";
import {GroupX_AddSessionLogEntry, LogOptions_ForGroupX, OnSessionEnded, SessionLog} from "../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogEntry, LogType} from "../UI/Tools/@Shared/LogEntry.js";
import {InAndroid, nativeBridge} from "../Utils/Bridge/Bridge_Native.js";
import {remoteUserProcessesBridge} from "../Utils/Bridge/Bridge_RemoteUserProcesses.js";
import {GetOwnInstanceInfo} from "../Utils/Bridge/SiteInstanceBridgeManager.js";
import {PluginGeneral} from "../Utils/Capacitor/GeneralPlugin.js";
import {LightPlayer} from "../Utils/EffectPlayers/LightPlayer.js";
import {ScriptPlayer} from "../Utils/EffectPlayers/ScriptPlayer.js";
import {SoundPlayer} from "../Utils/EffectPlayers/SoundPlayer.js";
import {UpdateWindowTitle} from "../Utils/General/General.js";
import {DisableSleepBlockers, EnableSleepBlockers_Repeating, SleepBlockerOptions} from "../Utils/General/SleepBlockers.js";
import {ClearMotionAveragesBuffer as ResetPhoneMotionAveragesBuffer} from "../Utils/Bridge/Bridge_Native/PhoneSensors.js";
import {EngineSessionComp} from "./FBASession/Components/EngineSessionComp.js";
import {TryNotifyClientOfActiveHostSessionState} from "./Remoting/ClientConnection.js";

/*import type {FBASession_Client} from "./FBASession_Client";
import type {FBASession_Local} from "./FBASession_Local";
import type {FBASession_CreateComponents} from "./FBASession/ComponentRegistry";*/
type FBASession_Client = import("./FBASession_Client").FBASession_Client;
type FBASession_Local = import("./FBASession_Local").FBASession_Local;
//type FBASession_CreateComponents = import("./FBASession/ComponentRegistry").FBASession_CreateComponents;

// sessions (high level)
// ==========

export async function StartHostSession(launchType = "night" as LaunchType) {
	const {FBASession_Local} = require("./FBASession_Local") as typeof import("./FBASession_Local.js"); // late-require, avoiding circular importing

	// this is called by ui code and client, both of which may think session-starting is possible when its not (due to state-update delay), so just ignore invalid calls
	if (liveFBASession != null) return;

	// get youtube-api ready ahead of time
	//await EnsureYoutubeAPIReady();

	const config = GetSelectedFBAConfig();
	await SetAndStartLiveFBASession(new FBASession_Local(config!, undefined, launchType));
	NotifyFBASessionSet();
}
export async function StopHostSession(saveLocally: boolean, saveOnline: boolean) {
	// this is called by ui code and client, both of which may think session-stopping is possible when its not (due to state-update delay), so just ignore invalid calls
	if (liveFBASession == null || !liveFBASession.IsLocal()) return;

	//await fbaCurrentSession.Stop();
	//await liveFBASession.Stop(saveLocally, saveOnline);
	await ClearAndStopLiveFBASession(saveLocally, saveOnline);
}

// sessions
// ==========

export const mainDataPathMarker = "*MainData*"; // we include "*", so that if substitution is missed, the path is invalid
export const sessionFolderName_formatStr = "YYYY-MM-DD-HH-mm-ss";
/*export function GetSessionFolderPath(sessionStartTime: number) {
	return `${mainDataPathMarker}/Sessions/${moment(sessionStartTime).format(sessionFolderName_formatStr)}`;
}*/
export function GetSessionFolderPath(sessionFolderName: string) {
	return `${mainDataPathMarker}/Sessions/${sessionFolderName}`;
}
export function GetSessionSubpath(sessionFolderName: string, subpath: string) {
	return `${GetSessionFolderPath(sessionFolderName)}/${subpath}`;
}

export let liveFBASession: FBASession|n;
export function GetLiveFBASession_Reactive(reactOnLog = false) {
	// these lines activate mobx tracking
	var tracker1 = store.main.tools.engine.liveFBASession_setAt;
	if (reactOnLog) var tracker2 = store.main.tools.engine.liveFBASession_loggedAt;
	return liveFBASession;
}
export async function SetAndStartLiveFBASession(session: FBASession) {
	const oldSession = liveFBASession;
	// make sure a session is not already active
	Assert(oldSession == null, "Cannot start a host-session when a session is already active!");
	const storeState = store.main.tools.engine;

	liveFBASession = session;

	// first, load recovery-info from store, if applicable (current session's recoveryInfo is a new object, but should end up containing the same data after the restore happens)
	if (session?.IsLocal() && storeState.liveFBASession_recoveryInfo) {
		const recovInfo = storeState.liveFBASession_recoveryInfo;
		session.Log(`Restoring session from recovery-info... @startTime:${recovInfo.startTime} @launchType:${recovInfo.launchType
			} @events:${recovInfo.events.length} @alarmDelay:${recovInfo.journey_alarmDelay}`, LogType.Event_Large);
		session.LoadRecoveryInfo(recovInfo);
	}
	// then make sure store is storing the new session's recovery-info object
	RunInAction("SetLiveFBASession.setRecoveryInfoInStoreToThatOfNewSession", ()=>storeState.liveFBASession_recoveryInfo = session?.AsLocal?.recoveryInfo_saved);

	UpdateWindowTitle();
	NotifyFBASessionSet();
	MaybeNotifyClientOfActiveHostSessionState(session, null);

	await session.Start(session.AsLocal?.recoveryInfo_loaded, true);
}
export async function ClearAndStopLiveFBASession(saveLocally: boolean, saveOnline: boolean) {
	const oldSession = liveFBASession;
	// make sure a session is active
	Assert(oldSession != null, "No session is active to stop!");
	const storeState = store.main.tools.engine;

	await oldSession.Stop(saveLocally, saveOnline, true);

	liveFBASession = null;

	// clear the stored recovery-info, now that the user is choosing to end the current session (as opposed to the session being interrupted by a crash)
	RunInAction("SetLiveFBASession.setRecoveryInfoInStoreToThatOfNewSession", ()=>storeState.liveFBASession_recoveryInfo = null);

	UpdateWindowTitle();
	NotifyFBASessionSet();
	MaybeNotifyClientOfActiveHostSessionState(null, oldSession);

	if (oldSession != null) OnSessionEnded();
}
function MaybeNotifyClientOfActiveHostSessionState(liveSession: FBASession|n, oldSession: FBASession|n) {
	// if host-session active-state changed, notify client of its config (if one is connected)
	if (liveSession?.IsLocal() || oldSession?.IsLocal()) {
		TryNotifyClientOfActiveHostSessionState();
	}
}

// helper funcs, to make usage easier for journey-sessions (ie. sessions with journey-comp active); for example, assuming they're always local (which is true for now)
/*export function GetLiveJourneySession() {
	const session = liveFBASession;
	if (session != null && session.c.journey.enabled) return session as FBASession_Local;
	return null;
}
export function GetLiveJourneySession_Reactive(reactOnLog = false) {
	const session = GetLiveFBASession_Reactive(reactOnLog);
	if (session != null && session.c.journey.enabled) return session as FBASession_Local;
	return null;
}*/

// helper funcs, to make usage easier for logic that only operates in cases of an FBASession_Local being active
export function GetLiveLocalSession() {
	const session = liveFBASession;
	if (session != null && session.IsLocal()) return session as FBASession_Local;
	return null;
}
export function GetLiveLocalSession_Reactive(reactOnLog = false) {
	const session = GetLiveFBASession_Reactive(reactOnLog);
	if (session != null && session.IsLocal()) return session as FBASession_Local;
	return null;
}

export const fbaEndedSessions = [] as FBASession[];
export function GetAllFBASessions() {
	return fbaEndedSessions.concat(liveFBASession ? [liveFBASession] : []);
}

// general
// ==========

// used by user to check whether another instance is online (too broad to be that useful honestly)
remoteUserProcessesBridge.RegisterFunction("Ping", ()=>{
	console.log("Received ping call!");
	return "Remote site received ping...";
});

// classes
// ==========

export type MicLoudnessListener = (notifyLoudness: number, actualLoudness: number)=>any;
export type ScreenStateChangeListener = (screenState: ScreenState)=>any;
export type PhoneMotionDataListener = (motionAveragesOfChunksFromLast10s: number[])=>any;

/*export type TriggerActionFunc = (triggerInfo: TriggerInfo)=>any;
export class TriggerAction {
	func: (triggerInfo: TriggerInfo)=>any;
}*/
export type TriggerAction = (triggerInfo: TriggerInfo)=>any;
export class TriggerPackage {
	constructor(initialData: Partial<TriggerPackage>);
	constructor(name: string, condition: TriggerSet|n, comp: EngineSessionComp, otherInitialData: Partial<TriggerPackage>, actionIfLocal: TriggerAction, actionIfRemote?: TriggerAction);
	constructor(...args) {
		if (args.length == 1) {
			const [initialData] = args as [Partial<TriggerPackage>];
			Object.assign(this, initialData);
		} else {
			const [name, condition, comp, otherInitialData, actionIfLocal, actionIfRemote] = args as [string, TriggerSet, boolean, Partial<TriggerPackage>, TriggerAction, TriggerAction|n];
			Object.assign(this, E(
				{name, condition, comp, actionIfLocal, actionIfRemote},
				otherInitialData
			));
		}
	}

	name: string;
	condition: TriggerSet|n;
	actionIfLocal: TriggerAction;
	actionIfRemote: TriggerAction;
	comp: EngineSessionComp;

	// attached later by FBASession
	activator: TriggerSet_Activator|n;
}

export class BroadcastOptions {
	constructor(data?: Partial<BroadcastOptions>) {
		Object.assign(this, data);
	}
	broadcastToDisabledComps = false;
}

export abstract class FBASession {
	constructor(config: FBAConfig, hostConfig?: FBAConfig, launchType = "night" as LaunchType) {
		this.id = GenerateUUID();
		this.config = config;
		this.hostConfig = hostConfig || this.config;
		this.launchType = launchType;
		Assert(MeID() != null, "User must be signed-in to run a session.");
		this.userID = MeID()!;
		this.CreateComponents();
	}

	id: string;
	config: FBAConfig;
	hostConfig: FBAConfig;
	get c() { return this.config; } // alias
	//get hc() { return this.IsRemote() ? this.hostConfig : this.config; } // alias
	get hc() { return this.hostConfig; } // alias
	launchType: LaunchType;

	userID: string; // user must be signed-in to run a session; store this as non-null for easier type-checking (complicates code too much otherwise)

	timerContext = new TimerContext();

	running: boolean;
	startTime: number;
	localOffsetFromUTC: number;
	folderName: string;
	endTime: number;

	// listeners (only the first listener in each of these arrays is actually "attached" natively; the rest are called by the first listener)
	keyDownListeners: KeyboardEventHandler<any>[];
	keyUpListeners: KeyboardEventHandler<any>[];
	/*mouseDownListeners: MouseEventHandler<any>[];
	mouseUpListeners: MouseEventHandler<any>[];
	mouseMoveListeners: MouseEventHandler<any>[];*/
	mouseDownListeners: PointerEventHandler<any>[];
	mouseUpListeners: PointerEventHandler<any>[];
	mouseMoveListeners: PointerEventHandler<any>[];
	micLoudnessListeners: MicLoudnessListener[];
	screenStateChangeListeners: ScreenStateChangeListener[];
	phoneMotionDataListeners: PhoneMotionDataListener[];

	// microphone system
	micRecorder = new VMediaRecorder();
	micRecorder_audioContext: AudioContext;

	// players
	textSpeaker = new TextSpeaker(); // this is for web-tts api (on android, this is not used)
	effectSoundPlayer = new SoundPlayer();
	startupScriptPlayer = new ScriptPlayer();
	resetScreenLightPlayer = new LightPlayer();

	heartbeatTimer: Timer|n;
	sleepBlockersTimer: Timer|n;

	// components
	components = [] as EngineSessionComp<any>[];
	protected AddComponent(comp: EngineSessionComp<any>) {
		this.components.push(comp);
	}
	// components (new approach)
	CreateComponents() {
		const {FBASession_CreateComponents} = require("./FBASession/ComponentRegistry") as typeof import("./FBASession/ComponentRegistry.js"); // late-require, avoiding circular importing

		FBASession_CreateComponents.call(this);
	}

	//GetComponent<CompType extends FBASessionComponent>(compType: new(..._)=>CompType): CompType {
	/** Variant of Comp(), which only requests the component's class-name, rather than the class itself. (can help for eg. javascript import-cycle cases) */
	Comp_ByString<CompType extends EngineSessionComp>(compTypeName: string): CompType {
		return this.components.find(a=>a.constructor.name == compTypeName) as CompType;
	}
	Comp<CompType extends EngineSessionComp>(compType: new(..._)=>CompType): CompType {
		return this.components.find(a=>a instanceof compType) as CompType;
	}
	Comps<CompType extends EngineSessionComp>(compType: new(..._)=>CompType): CompType[] {
		return this.components.filter(a=>a instanceof compType) as CompType[];
	}
	Comps_Enabled<CompType extends EngineSessionComp>(compType: new(..._)=>CompType): CompType[] {
		return this.components.filter(a=>a instanceof compType && a.behaviorEnabled) as CompType[];
	}
	TryCallOnComp(compTypeName: string, methodName: string, ...args: any[]) {
		const comp = this.components.find(a=>a.constructor.name == compTypeName);
		return comp?.[methodName](...args);
	}
	/*Broadcast<T>(funcCaller: (comp: EngineSessionComp<any>)=>any);
	Broadcast<T>(options: BroadcastOptions, funcCaller: (comp: EngineSessionComp<any>)=>any);
	Broadcast(...args) {
		let options: BroadcastOptions, funcCaller: (comp: EngineSessionComp<any>)=>any);
		if (IsFunction(args[0])) [funcCaller] = args;
		else [options, funcCaller] = args;
		options = E(new BroadcastOptions(), options!);

		this.components.forEach(comp=>{
			if (!comp.behaviorEnabled && !options.broadcastToDisabledComps && !func["callEvenWhenCompDisabled"]) return;
			funcCaller(comp);
		});
	}*/
	/*Broadcast<T>(funcNameOrGetter: string | ((comp: EngineSessionComp<any>)=>T), ...funcArgs: any[]);
	Broadcast<T>(options: BroadcastOptions, funcNameOrGetter: string | ((comp: EngineSessionComp<any>)=>T), ...funcArgs: any[]);
	Broadcast(...args) {
		let options: BroadcastOptions, funcNameOrGetter: string | ((comp: EngineSessionComp<any>)=>any), funcArgs: any[];
		if (IsString(args[0]) || IsFunction(args[0])) [funcNameOrGetter, ...funcArgs] = args;
		else [options, funcNameOrGetter, funcArgs] = args;
		options = E(new BroadcastOptions(), options!);

		const funcName = IsString(funcNameOrGetter) ? funcNameOrGetter : ConvertPathGetterFuncToPropChain(funcNameOrGetter).Last();
		this.components.forEach(comp=>{
			const func = comp[funcName] as Function;
			if (func == null) return;
			if (!comp.behaviorEnabled && !options.broadcastToDisabledComps && !func["callEvenWhenCompDisabled"]) return;
			func.call(comp, ...funcArgs);
		});
	}*/
	Broadcast<T extends (...args: any[])=>any>(options: Partial<BroadcastOptions>, funcGetter: (comp: EngineSessionComp<any>)=>T, ...funcArgs: Parameters<T>) {
		const opt = new BroadcastOptions(options);
		this.components.forEach(comp=>{
			const func = funcGetter(comp) as Function;
			if (func == null) return;
			if (!comp.behaviorEnabled && !opt.broadcastToDisabledComps && !func["callEvenWhenCompDisabled"]) return;
			func.call(comp, ...funcArgs);
		});
	}

	IsLocal(): this is FBASession_Local {
		//return this instanceof FBASession_Local;
		return this.constructor.name == "FBASession_Local"; // use constructor-name, so we don't reference the class itself (since causes circular imports between the base-class and subclasses)
	}
	IsClient(): this is FBASession_Client {
		//return this instanceof FBASession_Remote;
		return this.constructor.name == "FBASession_Client";
	}
	get AsLocal(): FBASession_Local|n { return this.IsLocal() ? this : null; }
	get AsClient(): FBASession_Client|n { return this.IsClient() ? this : null; }

	// abstract methods
	//abstract Start();
	/*abstract CreateBridge();
	abstract DestroyBridge();*/
	//abstract CreateListeners();
	//abstract CreateTimers();
	//abstract Stop();

	async Start(recoveryInfo: SessionRecoveryInfo|n, calledFromGlobalFunc: boolean) {
		Assert(calledFromGlobalFunc, "Currently, FBASession.Start() must be called from the SetAndStartLiveFBASession() function.");
		Assert(!this.running);
		this.running = true;
		// if we're restoring from recovery-info, use the crashed session's start-time as our own session start-time (eg. so that the restored-event timestamps make sense)
		const startMoment = recoveryInfo?.startTime ? moment(recoveryInfo.startTime) : moment();
		this.startTime = startMoment.valueOf();
		if (this.IsLocal()) {
			RunInAction("FBASession.Start", ()=>{
				this.AsLocal!.recoveryInfo_saved.startTime = this.startTime;
				this.AsLocal!.recoveryInfo_saved.launchType = this.launchType;
			});
		}
		this.localOffsetFromUTC = startMoment.utcOffset();
		this.folderName = startMoment.format("YYYY-MM-DD-HH-mm-ss");
		this.Log(`Starting ${this.IsLocal() ? "local" : "remote"} FBA session`, LogType.Event_Large);

		this.Broadcast({}, a=>a.OnStart_Early);
		//this.CreateBridge();
		await this.CreateListeners();
		this.RegisterTriggerPackages();
		this.SetUpTriggerActivators();
		if (InAndroid(0)) this.InitAndroid();

		if (this.IsLocal()) {
			this.CreateTimers();
			this.initialDelayTimer.Start();
			if (this.c.general.autoEnd_enabled) this.autoEndDelayTimer.Start();
		} else if (this.IsClient()) {
			this.CreateTimers();
			this.statusReportTimer.Start();
		}

		// todo: maybe change heartbeat system to start at time of JS startup, or at time of first Root comp render (and then just have each heartbeat send whether a session is active at that point)
		this.heartbeatTimer = new Timer(10000, ()=>nativeBridge.Call("Heartbeat")).Start();
		// at start of session, also send a set of 6 artificial heartbeats (one minute's worth); this ensures the crash-checker is satisifed at the start, despite real heartbeats not having been amassed enough to satisfy its requirements
		for (let i = 0; i < 6; i++) {
			nativeBridge.Call("Heartbeat");
		}
		
		// on initial sleep-blocking, use all sleep-blockers; for repeating, only use the anti-web-view-optimizations sleep-blocker
		if (this.c.general.sleepBlockers_enabled) {
			this.sleepBlockersTimer = EnableSleepBlockers_Repeating(new SleepBlockerOptions(true), new SleepBlockerOptions(false, {antiWebViewOptimizations: true}), 30000);
		}
		// todo: make this run all matching scripts
		this.startupScriptPlayer.script = GetScripts_WithUserTag(this.c.general.startupScriptTag).Random();
		this.startupScriptPlayer.Play(1);
		this.resetScreenLightPlayer.light = GetLights_WithUserTag(this.c.general.resetScreenLightTag).filter(a=>a.type == LightType.Screen).Random();

		this.Broadcast({}, a=>a.OnStart, recoveryInfo);

		if (store.main.tools.engine.allowClientConnections) {
			remoteUserProcessesBridge.Call("HostSessionStarted_AllowingClientConnections", GetOwnInstanceInfo());
		}
	}
	async Stop(saveLocally: boolean, saveOnline: boolean, calledFromGlobalFunc: boolean) {
		Assert(calledFromGlobalFunc, "Currently, FBASession.Stop() must be called from the ClearAndStopLiveFBASession() function.");
		Assert(this.running);
		this.running = false;
		this.endTime = Date.now();
		this.Log(`Stopping ${this.IsLocal() ? "local" : "remote"} FBA session`, LogType.Event_Large);

		//this.DestroyBridge();
		this.timerContext.Reset(); // stop all timers
		this.RemoveListeners();
		// stop Android side
		if (InAndroid(0)) PluginGeneral.StopSessionHelpers();
		this.textSpeaker.Stop();
		this.effectSoundPlayer.Stop();
		this.startupScriptPlayer.Stop();

		if (this.heartbeatTimer) {
			this.heartbeatTimer.Stop();
			this.heartbeatTimer = null;
		}
		if (this.sleepBlockersTimer) {
			this.sleepBlockersTimer.Stop();
			this.sleepBlockersTimer = null;
			DisableSleepBlockers();
		}

		this.Broadcast({}, a=>a.OnStop, saveLocally);

		// do at end (of synchronous portion), since some things still want to access to fbaCurrentSession, while shutting down (eg. SonicBomb.StopShake(), sending to remote)
		fbaEndedSessions.push(this);

		if (this.IsLocal()) {
			// note: this call is async, but we don't await it; we don't want to block the session-end process on it
			this.FinalizeStoredData(saveLocally, saveOnline);
		}
	}

	// pointer-move window is not used if config is invalid (ie. would have no meaningful behavior anyway); behavior then becomes the base "any mouse-move event causes a trigger"
	get UsePointerMoveWindow() { return (this.c.general.mouseMove_compareWindow ?? 0) > 0 && (this.c.general.mouseMove_minDistToTrigger ?? 0) > 0; }

	recentPointerPositions = new Map<number, Vector2>();
	currentPointerInput_processedPositions = new Set<string>();
	async CreateListeners() {
		// add root key-listeners
		this.keyDownListeners = [e=>this.keyDownListeners.slice(1).forEach(listener=>listener(e))];
		document.addEventListener("keydown", this.keyDownListeners[0] as any);
		this.keyUpListeners = [e=>this.keyUpListeners.slice(1).forEach(listener=>listener(e))];
		document.addEventListener("keyup", this.keyUpListeners[0] as any);

		// add root mouse-listeners
		this.mouseDownListeners = [e=>{
			this.recentPointerPositions.set(Date.now(), new Vector2(e.pageX, e.pageY));

			this.mouseDownListeners.slice(1).forEach(listener=>listener(e));
		}];
		document.addEventListener("pointerdown", this.mouseDownListeners[0] as any);
		this.mouseUpListeners = [e=>{
			if (this.c.general.mouseMoveRepeatReject_enabled) {
				this.currentPointerInput_processedPositions.clear();
			}

			this.mouseUpListeners.slice(1).forEach(listener=>listener(e));
		}];
		document.addEventListener("pointerup", this.mouseUpListeners[0] as any);
		this.mouseMoveListeners = [e=>{
			const currentPos = new Vector2(e.pageX, e.pageY);
			this.recentPointerPositions.set(Date.now(), currentPos);
			// mouse-move min-dist-to-trigger filter
			if (this.UsePointerMoveWindow) {
				const windowStart = Date.now() - this.c.general.mouseMove_compareWindow;
				const recentPointerPositionEntries = [...this.recentPointerPositions.entries()].OrderBy(([time, pos])=>time); // they should already be sorted by time, but use OrderBy to make sure
				const posAtStartOfWindow = recentPointerPositionEntries.filter(([time, pos])=>time <= windowStart).LastOrX()?.[1] ?? recentPointerPositionEntries[0]?.[1]; // if window-start is before first entry, use first entry
				const dist = currentPos.DistanceTo(posAtStartOfWindow);
				if (dist < this.c.general.mouseMove_minDistToTrigger) return;
			}
			// mouse-move repeat-reject filter
			if (this.c.general.mouseMoveRepeatReject_enabled) {
				const posKey = `${e.pageX.RoundTo_Str(this.c.general.mouseMoveRepeatReject_roundPositionsTo)},${e.pageY.RoundTo_Str(this.c.general.mouseMoveRepeatReject_roundPositionsTo)}`;
				if (this.currentPointerInput_processedPositions.has(posKey)) return;
				this.currentPointerInput_processedPositions.add(posKey);
			}

			this.mouseMoveListeners.slice(1).forEach(listener=>listener(e));
		}];
		document.addEventListener("pointermove", this.mouseMoveListeners[0] as any);

		// test
		/*document.addEventListener("pointerdown", ()=>(console.log("pointerdown"), (this.mouseMoveListeners[0] as any)()));
		document.addEventListener("pointermove", ()=>(console.log("pointermove"), (this.mouseMoveListeners[0] as any)()));
		document.addEventListener("pointerup", ()=>(console.log("pointerup"), (this.mouseMoveListeners[0] as any)()));
		document.addEventListener("touchstart", ()=>(console.log("touchstart"), (this.mouseMoveListeners[0] as any)())); 
		document.addEventListener("touchend", ()=>(console.log("touchend"), (this.mouseMoveListeners[0] as any)()));*/

		// define root mic-listener
		this.micLoudnessListeners = [(notifyLoudness, actualLoudness)=>{
			//console.log(`NotifyLoudness: ${message["notifyLoudness"]}; ActualLoudness: ${message["actualLoudness"]}`);
			// only snooze prompting; don't snooze_fail, since that might trigger sound-effect, causing issues due to frequency (todo: add min-interval option to avoid this workaround)
			//this.Snooze(`Snoozing (mic loudness: ${message["actualLoudness"].toFixed(4)})`, false); // don't apply external-effects (eg. sound effect) if already applied, since can cause issues due to frequency
			this.micLoudnessListeners.slice(1).forEach(listener=>listener(notifyLoudness, actualLoudness));
		}];
		// if needs mic-listener, and not in android, set up web mic-listener (when in android, mic-listener is initialized through the general Init function)
		if (this.NeedsMicListener && !InAndroid(0)) {
			await this.CreateMicListener_Web();
		}

		// add root screen-state-change listener
		this.screenStateChangeListeners = [screenState=>{
			this.screenStateChangeListeners.slice(1).forEach(listener=>listener(screenState));
		}];

		// add root phone-motion listener
		this.phoneMotionDataListeners = [motionAveragesOfChunksFromLast10s=>{
			this.phoneMotionDataListeners.slice(1).forEach(listener=>listener(motionAveragesOfChunksFromLast10s));
		}];

		// Why are we registering these "root listeners" (at index 0) in the arrays above, but then not registering anything here to call them?
		// Well, those index-0 listeners are generally called by event-handlers registered elsewhere (mostly in the files under Utils/Services, eg. PhoneSensors.ts).
		// Some of these event-handlers registered elsewhere hold state in the frontend, so the calls below are to clear that data (since may be left-over from a previous session / period where that pipeline was active).
		ResetPhoneMotionAveragesBuffer();
	}

	// helpers for setting up mic-listeners
	get SequencesWithMicListeners() {
		return FBAConfig.GetAllTriggerSequences(this.c).filter(sequence=>sequence.items.find(a=>a.micLoudness_loudness));
	}
	get NeedsMicListener() {
		return this.SequencesWithMicListeners.length >= 1;
	}
	get MicNotifyLoudnesses() {
		return this.SequencesWithMicListeners.SelectMany(sequence=>{
			return sequence.items.filter(a=>a.micLoudness_loudness).map(a=>a.micLoudness_loudness);
		}).Distinct();
	}
	async CreateMicListener_Web() {
		await this.micRecorder.StartRecording_Audio(store.main.settings.audio.mainMicrophoneID);

		this.micRecorder_audioContext = new AudioContext();
		await InitAudioNodes(this.micRecorder_audioContext);
		const retrieveAudioProcessorNode = CreateGeneralAudioProcessor(this.micRecorder_audioContext);
		retrieveAudioProcessorNode.port.onmessage = event=>{
			const message = event.data;
			if (!this.running) {
				LogWarning("Received message from audio-processor, but session already closed! Message: ", message);
				return;
			}
			if (message.type == "notify-loudness") {
				this.micLoudnessListeners[0](message["notifyLoudness"], message["actualLoudness"]);
			}
		};
		retrieveAudioProcessorNode.port.postMessage({
			type: "init",
			//notifyLoudnesses: [this.c.snoozeFailTrigger_micLoudness_loudness],
			notifyLoudnesses: this.MicNotifyLoudnesses,
			minNotifyInterval: 1,
		});
		//retrieveAudioProcessorNode.port.start();

		const recorderStream = this.micRecorder_audioContext.createMediaStreamSource(this.micRecorder.stream);
		recorderStream.connect(retrieveAudioProcessorNode);
		retrieveAudioProcessorNode.connect(this.micRecorder_audioContext.destination);
		console.log("Microphone listener initialized.");
	}

	// helpers for setting up phone-motion listeners
	get SequencesWithPhoneMotionListeners() {
		return FBAConfig.GetAllTriggerSequences(this.c).filter(sequence=>sequence.items.find(a=>a.checkWindow != null));
	}
	get NeedsPhoneMotionListener() {
		return this.SequencesWithPhoneMotionListeners.length >= 1;
	}

	RemoveListeners() {
		// todo: maybe rather than adding/removing these root handlers, instead create them all ahead of time (eg. in WebSensors.ts file), and just pass the data on to the live-session (if one is active)
		document.removeEventListener("keydown", this.keyDownListeners[0] as any);
		document.removeEventListener("keyup", this.keyUpListeners[0] as any);
		document.removeEventListener("pointerdown", this.mouseDownListeners[0] as any);
		document.removeEventListener("pointerup", this.mouseUpListeners[0] as any);
		document.removeEventListener("pointermove", this.mouseMoveListeners[0] as any);
		document.removeEventListener("touchmove", this.mouseMoveListeners[0] as any);

		this.DestroyMicListener();
	}
	async DestroyMicListener() {
		// stop microphone system
		if (this.micRecorder) await this.micRecorder.StopRecording();
		if (this.micRecorder_audioContext) await this.micRecorder_audioContext.close();
	}

	InitAndroid() {
		//const snoozeTriggerSet = State(a=>a.main.tools.fba.remoteConfig).snoozeAndPrompts.snooze_triggerSet;
		const sessionHelpersOptions = E(
			{
				// global settings
				voiceOnRingtoneChannel: store.main.settings.audio.voiceOnRingtoneChannel,
				autoRestart: store.main.settings.autoRestartService,
				sessionCrashCheck_enabled: store.main.settings.sessionCrashCheck_enabled,
				sessionCrashCheck_interval: store.main.settings.sessionCrashCheck_interval,
				sessionJSWake_enabled: store.main.settings.sessionJSWake_enabled,
				sessionJSWake_interval: store.main.settings.sessionJSWake_interval,
				sessions_systemStartVolume_media_enabled: store.main.settings.audio.sessions_systemStartVolume_media_enabled,
				sessions_systemStartVolume_media: store.main.settings.audio.sessions_systemStartVolume_media,
				sessions_systemStartVolume_call_enabled: store.main.settings.audio.sessions_systemStartVolume_call_enabled,
				sessions_systemStartVolume_call: store.main.settings.audio.sessions_systemStartVolume_call,
				sessions_systemStartVolume_ringtone_enabled: store.main.settings.audio.sessions_systemStartVolume_ringtone_enabled,
				sessions_systemStartVolume_ringtone: store.main.settings.audio.sessions_systemStartVolume_ringtone,

				// panel settings
				//engineContext: EngineContext[State(a=>a.main.tools.fba.engineContext)],
				/*snoozeFailTrigger_volumeKeys: State(a=>a.main.tools.fba.remote_snoozeFailTrigger_volumeKeys),
				snoozeFailTrigger_micLoudness: State(a=>a.main.tools.fba.remote_snoozeFailTrigger_micLoudness),*/
				volumeKeyListener_notify: FBAConfig.GetAllTriggerSequenceItems(this.c).Any(item=>["VolumeUp", "VolumeDown"].includes(item.key_name)),
				micListener_notifyLoudnesses: this.MicNotifyLoudnesses,
				micListener_minNotifyInterval: 1,
				//micListener_logLoudnesses: true,
				phoneMotionListenerNeeded: this.NeedsPhoneMotionListener,
			},
		);
		PluginGeneral.StartSessionHelpers(sessionHelpersOptions);
	}

	//triggerActions: TriggerAction[] = [];
	/*triggerActions = {} as {[key: string]: TriggerAction};
	CreateTriggerAction(name: string, action: TriggerAction) {
		this.triggerActions[name] = action;
	}*/

	triggerPackages = [] as TriggerPackage[];
	RegisterTriggerPackages() {
		// don't worry; the trigger-actions below are only actually called if this is a local session
		const triggerPackages = [] as TriggerPackage[];
		//triggerPackages.push(...this.GetRootTriggerPackages());
		this.components.forEach(comp=>{
			triggerPackages.push(...comp.GetTriggerPackages());
		});
		for (const pack of triggerPackages) {
			this.RegisterTriggerPackage(pack);
		}
	}
	RegisterTriggerPackage(pack: TriggerPackage) {
		/*if (this.IsRemote()) {
			const self = this;
			pack.actionIfLocal = triggerInfo=>{
				this.Log(`Running trigger-action on host: ${pack.name} TriggerInfo: ${ToJSON(triggerInfo)}`, LogType.Action);
				self.hostBridge.Call("RunTriggerAction", pack.name, triggerInfo);
			};
			//pack.enabled = true; // all trigger-packages are enabled for remote sessions, since they may be enabled for the host session
		}*/
		if (this.IsClient() && pack.actionIfRemote == null) {
			const self = this;
			pack.actionIfRemote = triggerInfo=>{
				self.RunTriggerActionOnHost(pack.name, triggerInfo);
			};
		}
		this.triggerPackages.push(pack);

		// also register the trigger-package on the individual component (so on suspend/unsuspend, it can reset the activators' states)
		pack.comp.triggerPackages.push(pack);
	}

	//triggerActivators = [] as TriggerSet_Activator[];
	SetUpTriggerActivators() {
		for (const pack of this.triggerPackages) {
			// For a trigger to even have its trigger-activator created initially:
			// * Comp must be enabled at session start (ie. in config), AND...
			// * The trigger itself must be defined, AND...
			// * The trigger itself must not be disabled
			if (!pack.comp.triggersEnabled || pack.condition == null || pack.condition?.disabled) continue;

			const action = this.IsLocal() ? pack.actionIfLocal : pack.actionIfRemote;
			pack.activator = new TriggerSet_Activator(pack.name, pack.condition, action, this);
		}
		//const packagesWithActivators = this.triggerPackages.filter(a=>a.activator);
		
		const SendInputToActivators = (transferFunc: (activator: TriggerSet_Activator)=>any)=>{
			// as in window.addEventListener("WakeUp", [...]), trigger any overdue timers here (the wake-up timer doesn't always activate fast enough, and we need precision for input events)
			if (InAndroid(0)) {
				this.timerContext.ManuallyTriggerOverdueTimers();
			}
			const activatorsToSendTo = this.triggerPackages.filter(pack=>{
				if (pack.activator == null) return false;
				// we only need to check comp.triggersEnabled here, because failure on the other checks would have left pack.activator as null
				if (!pack.comp.triggersEnabled) return false;
				return true;
			}).map(a=>a.activator!);
			// two-step process, so order of trigger-activations can't change what receives the input-events (due to comp.triggersEnabled mutations)
			// (for example, if user performed the hotkey to "switch modes", only the old mode/component should also be able to process that input-event)
			for (const activator of activatorsToSendTo) {
				transferFunc(activator);
			}
		};
		this.keyDownListeners.push(e=>SendInputToActivators(activator=>activator.onKeyDown(e)));
		this.keyUpListeners.push(e=>SendInputToActivators(activator=>activator.onKeyUp(e)));
		this.mouseDownListeners.push(e=>SendInputToActivators(activator=>activator.onMouseDown(e)));
		this.mouseUpListeners.push(e=>SendInputToActivators(activator=>activator.onMouseUp(e)));
		this.mouseMoveListeners.push(e=>SendInputToActivators(activator=>activator.onMouseMove(e)));
		this.micLoudnessListeners.push((notifyLoudness, actualLoudness)=>SendInputToActivators(activator=>activator.onMicLoudness(notifyLoudness, actualLoudness)));
		this.screenStateChangeListeners.push(screenState=>SendInputToActivators(activator=>activator.onScreenStateChange(screenState)));
		this.phoneMotionDataListeners.push(motionAveragesOfChunksFromLast10s=>SendInputToActivators(activator=>activator.onPhoneMotionData(motionAveragesOfChunksFromLast10s)));
	}

	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}

	async PlaySoundEffect(soundTag: string, volume = 1, loop = false, tryPlayMissingSound = false) {
		this.effectSoundPlayer.sound = GetSounds_WithUserTag(soundTag).Random();
		if (this.effectSoundPlayer.youtubePlayer) {
			this.effectSoundPlayer.youtubePlayer.loop = loop;
		}
		if (this.effectSoundPlayer.sound || tryPlayMissingSound) {
			await this.effectSoundPlayer.Play(volume);
		}
	}

	logEntries = [] as LogEntry[];
	Log(message: string, type = LogType.Event_Small, opt?: LogOptions_ForGroupX) {
		return SessionLog(message, type, E({group: this}, opt));
	}
	AddLogEntry(entry: LogEntry, alsoLogToConsole: boolean|n = null) {
		GroupX_AddSessionLogEntry(this, entry, alsoLogToConsole);
	}
	ClearLog() {
		this.logEntries.Clear();
		NotifyFBASessionLogChanged();
	}
}

export function NotifyFBASessionSet() {
	//if (MobXComputationDepth() > 0) {
	// break out of call-stack before notifying of change, else can cause mobx "can't change observables inside reaction" error
	// 	(eg. runInAction -> some action with logging -> console.log intercept -> SessionLog -> GroupX_AddSessionLogEntry -> NotifyFBASessionSetOrLogChanged)
	setTimeout(()=>{
		RunInAction("NotifyFBASessionSet", ()=>store.main.tools.engine.liveFBASession_setAt = Date.now());
	});
}
//export const fbaLogListeners = [];
export function NotifyFBASessionLogChanged() {
	//fbaLogListeners.forEach(a=>a());

	//if (MobXComputationDepth() > 0) {
	// break out of call-stack before notifying of change, else can cause mobx "can't change observables inside reaction" error
	// 	(eg. runInAction -> some action with logging -> console.log intercept -> SessionLog -> GroupX_AddSessionLogEntry -> NotifyFBASessionSetOrLogChanged)
	setTimeout(()=>{
		RunInAction("NotifyFBASessionLogChanged", ()=>store.main.tools.engine.liveFBASession_loggedAt = Date.now());
	});
}