import {liveFBASession} from "../../../Engine/FBASession.js";
import type {MicroSnoozeComp} from "../../../Engine/FBASession/Components/MicroSnoozeComp.js";
import {SessionLog} from "../../../UI/Tools/@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../../../UI/Tools/@Shared/LogEntry.js";
import {nativeBridge} from "../Bridge_Native.js";
import {GetRawKeyName} from "../../General/Keys.js";

export let screenOn = true;
nativeBridge.RegisterFunction("ScreenOn", pack=>{
	const {sendTime} = pack as any;
	SessionLog(`Got ScreenOn event! @SendDelay(${Date.now() - sendTime})`, LogType.Event_Large);
	screenOn = true;
	liveFBASession?.screenStateChangeListeners[0]("on");
});
nativeBridge.RegisterFunction("ScreenOff", pack=>{
	const {sendTime} = pack as any;
	SessionLog(`Got ScreenOff event! @SendDelay(${Date.now() - sendTime})`, LogType.Event_Large);
	screenOn = false;
	liveFBASession?.screenStateChangeListeners[0]("off");
});

const keysDownNow = {} as {[key: string]: boolean};
nativeBridge.RegisterFunction("NotifyVolumeKeyDown", (volKeyCode: number)=>{
	const keyName = {"-1": "VolumeDown", 1: "VolumeUp"}[volKeyCode]!;
	if (!keysDownNow[keyName]) {
		liveFBASession?.Log(`Volume key first-downed! VolKeyCode: ${volKeyCode} (${keyName})`);
	}

	keysDownNow[keyName] = true;
	liveFBASession?.keyDownListeners[0]({key: GetRawKeyName(keyName)} as any);
});
nativeBridge.RegisterFunction("NotifyVolumeKeyUp", (volKeyCode: number)=>{
	const keyName = {"-1": "VolumeDown", 1: "VolumeUp"}[volKeyCode]!;
	const keyWasAlreadyKnownAsUp = !keysDownNow[keyName];
	if (keyWasAlreadyKnownAsUp) {
		// note: this may be bug in OS layer, or limitation of my key-event inference logic (see VolumeProvider.onAdjustVolume in ForegroundService.kt)
		liveFBASession?.Log(`Volume key upped twice in a row! This shouldn't be possible (OS bug seemingly), so event ignored. VolKeyCode: ${volKeyCode} (${keyName})`);
		return;
	} else {
		liveFBASession?.Log(`Volume key upped! VolKeyCode: ${volKeyCode} (${keyName})`);
	}
	
	keysDownNow[keyName] = false;
	liveFBASession?.keyUpListeners[0]({key: GetRawKeyName(keyName)} as any);
});
nativeBridge.RegisterFunction("NotifyLoudnessHit", (notifyLoudness: number, actualLoudness: number)=>{
	//Remote_SnoozeFail_TryCall(`Calling Remote_SnoozeFail (from NotifyLoudnessHit: ${loudness})`);
	liveFBASession?.micLoudnessListeners[0](notifyLoudness, actualLoudness);
});

const chunkSizeInMS = 500;
const numberOfChunksInBuffer = 10_000 / chunkSizeInMS;
const motionAveragesOfChunksFromLast10s = [] as number[];
export function ClearMotionAveragesBuffer() {
	motionAveragesOfChunksFromLast10s.Clear();
}
nativeBridge.RegisterFunction("NotifyPhoneMotionSamples", (
	chunkMotionAverage: number,
	/* 0 = phone-back toward earth, 180 = phone-screen toward earth, 90 = halfway between (eg. phone side-edge/top-edge/bottom-edge toward earth) */
	tiltAngle: number
)=>{
	motionAveragesOfChunksFromLast10s.push(chunkMotionAverage);
	if (motionAveragesOfChunksFromLast10s.length > numberOfChunksInBuffer) {
		motionAveragesOfChunksFromLast10s.RemoveAt(0);
	}
	liveFBASession?.phoneMotionDataListeners[0](motionAveragesOfChunksFromLast10s);

	const microSnoozeComp = liveFBASession?.Comp_ByString<MicroSnoozeComp>("MicroSnoozeComp");
	if (microSnoozeComp?.behaviorEnabled && microSnoozeComp.triggersEnabled) { // which "enabledness" fields to check is a bit unclear...
		microSnoozeComp.OnTiltAngleReceived(tiltAngle);
	}
});

export const summarySamples_countPerSecond = 16;
export const summarySamples_bufferSize = 30 * summarySamples_countPerSecond;
export const summarySamples_bufferOfLast30s = [] as SummarySample[];
export function ClearSummarySamplesBuffer() {
	summarySamples_bufferOfLast30s.Clear();
}
export class SummarySample {
	constructor(public avg: number, public min: number, public max: number, public absAvg: number, public absMin: number) {}
}
nativeBridge.RegisterFunction("OnMicSampleSet", (summarySamples: SummarySample[])=>{
	summarySamples_bufferOfLast30s.push(...summarySamples);
	if (summarySamples_bufferOfLast30s.length > summarySamples_bufferSize) {
		summarySamples_bufferOfLast30s.splice(0, summarySamples_bufferOfLast30s.length - summarySamples_bufferSize);
	}
	OnMicSampleSet_extraListeners.forEach(a=>a(summarySamples));
});
export const OnMicSampleSet_extraListeners = [] as ((summarySamples: SummarySample[])=>void)[];