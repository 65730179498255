import {Button, Row} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {Observer} from "web-vcore";
import React from "react";
import {GetLiveLocalSession_Reactive} from "../../../Engine/FBASession.js";
import {AddJournalEntry} from "../../../Server/Commands/AddJournalEntry.js";
import {Journey_GetJournalEntriesToShow} from "../../../Store/firebase/journalEntries.js";
import {JournalEntry} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {store} from "../../../Store/index.js";
import {JournalEntryPreview} from "../../Journal/JournalEntryPreview.js";

@Observer
export class DreamPeriodsUI extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey;
		const liveLocalSession = GetLiveLocalSession_Reactive();

		const {journalEntryForSession, journalEntriesToShow} = Journey_GetJournalEntriesToShow();

		return (
			<ScrollView style={{flex: 60}} contentStyle={{display: "flex", flexDirection: "column"}}>
				{journalEntriesToShow.map(entry=>{
					return <JournalEntryPreview key={entry._key} entry={entry} entriesToShow={journalEntriesToShow} journalPage={false}/>;
				})}
				{liveLocalSession != null && journalEntryForSession == null &&
				<Row mt={30} mb={30} style={{justifyContent: "center"}}>
					<Button text="Add journal entry for live session" enabled={liveLocalSession != null} onClick={async()=>{
						const entry = new JournalEntry({
							title: "New entry",
							sleepTime: liveLocalSession.startTime,
							segments: [],
						});
						const entryID = await new AddJournalEntry({entry}).Run();
					}}/>
				</Row>}
				{/* add spacer at bottom, for more control of scrolling */}
				<div style={{height: 300}}/>
			</ScrollView>
		);
	}
}