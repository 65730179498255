import {minuteInMS, OnPopulated} from "web-vcore";
import {Muse_eegSamplesPerSecond_raw} from "../../../UI/Tools/@Shared/MuseInterface/EEGStructs";
import {Muse_gyroSamplesPerSecond_raw} from "../../../UI/Tools/@Shared/MuseInterface/GyroStructs";
import {DreamQuiz_QuestionType} from "./@EngineConfig/@EC_DreamQuiz.js";
import {GyroChannel} from "./@EngineConfig/@EC_Gyro";
import {AutoEndType, FBAConfig} from "./@FBAConfig";
import {prompt_storyExplore_1} from "./@LLMPrompts/Prompts_StoryExplore.js";
import {Sequence, SequenceItem, TriggerSet} from "./@TriggerSet";
import {TranscribeTarget} from "./@EngineConfig/@EC_DreamTranscribe.js";
import {AlarmSequence, AlarmType} from "./@EngineConfig/Alarms/@AlarmConfig.js";
import {OptionPoolLayer, OptionPoolOrdering, OptionPoolSource} from "./@EngineConfig/@EC_Memory.js";
import {FBAConfig_SoundAlarm} from "./@EngineConfig/Alarms/@EC_BasicAlarms.js";
import {EffectPointer} from "../@Shared/EffectPointer.js";

export let FBAConfig_GetDefault_static: FBAConfig;
OnPopulated(()=>{
	FBAConfig_GetDefault_static = FBAConfig_GetDefault() as FBAConfig;
});

export function FBAConfig_GetDefault_Remote() {
	const result = FBAConfig_GetDefault();
	//result.alarms.alarms = result.alarms.alarms.filter(a=>a.type == PromptType.Memory);
	return result;
}

// we define defaults here rather than with class field-initializers, because some of these default-values are very expansive (eg. snoozeAndPrompts.prompts array)
export function FBAConfig_GetDefault(): Omit<FBAConfig, "creator" | "createdAt" | "editedAt" | "name"> {
	return {
		general: {
			initialDelay: 1,
			autoEnd_enabled: false,
			autoEnd_type: AutoEndType.AfterDuration,
			autoEnd_time: 0,
			autoEnd_delay: 0,
			sleepBlockers_enabled: true,
			startupScriptTag: null,
			defaultVoice_soundTag: "",
			defaultVoice_volumeMultiplier: 1,
			mouseMove_minDistToTrigger: 3,
			mouseMove_compareWindow: 500,
			mouseMoveRepeatReject_enabled: true,
			mouseMoveRepeatReject_roundPositionsTo: 1,
			sessionUI_hideStatusBar: true,
			sessionUI_hideNavBar: true,
			lockOverlay_hideStatusBar: true,
			lockOverlay_hideNavBar: true,
			notes: "",
		},

		modeSwitcher: {
			enabled: false,
			cycleActiveComp_triggerSet: new TriggerSet({sequences: [
				/*new Sequence([
					new SequenceItem({type: "KeyHold", key_name: "VolumeDown"}),
					new SequenceItem({type: "Key", key_name: "VolumeUp"}),
				], {}),*/
				new Sequence([
					new SequenceItem({type: "Keys", key_name: "@KeyName", key_extra: "VolumeDown,VolumeUp"}),
					//new SequenceItem({type: "KeyUp", key_name: "VolumeDown"}),
				], {}),
			]}),
			onAlarmWaitEnd_setActiveMode: "DreamTranscribe",
			startStates: [],
			cycleEntries: [],
		},

		background: {
			enabled: false,
			startVolume: .1,

			increaseVolume_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "@KeyName", key_extra: "1"})])],
			}),
			increaseVolume_amount: .05,
			decreaseVolume_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "@KeyName", key_extra: "`"})])],
			}),
			decreaseVolume_amount: .05,
		},

		camera: {
			enabled: false,
			//savePath: "$SessionFolder/Camera/$TriggerTime_TriggerAt-$TriggerOffset.webm",
			preMotionBuffer: 10,
			postMotionBuffer: 60,
			//minVideoLength: 60,
			maxVideoLength: 300,

			camMotion_record: true,
			camMotion_pixelTrigger_minValueDelta: 16,
			//camMotion_frameTrigger_minTriggerPixelChainLength: 5,
			camMotion_frameTrigger_minTriggerPixelCount: 500,
			camMotion_motionTrigger_minTriggerFramePercent: .1,
			camMotion_motionTrigger_windowDuration: 5,

			eegMotion_record: true,
			eegMotion_minActivity: 5,
		},

		gyro: {
			enabled: false,
			useSampleRounding: true,

			recordData_raw: true,
			recordData_motions: true,

			detectMotion: true,
			motion_channel: GyroChannel.All_Sum,

			motion_sampleTrigger_normalizationWindowSize: 20, // can be higher than eeg window-size, since over-triggering (while adjusting) is fine
			motion_sampleTrigger_smoothing: Muse_gyroSamplesPerSecond_raw * .5,
			motion_sampleTrigger_minDeviation: 5,
			motion_motionTrigger_windowSize: .05, // resolves to 1 sample (we want gyro motion to trigger faster than eeg motion)
			motion_motionTrigger_minTriggerSamplePercent: .1,
			motion_motionTrigger_maxTriggerRate: 3,

			motion_disableEEGMotion_enabled: true,
			motion_disableEEGMotion_duration: 10,

			motion_resetEEGActivity_enabled: true,
			motion_resetEEGActivity_maxValue: 0,
		},

		eeg: {
			enabled: false,
			useSampleRounding: true,

			recordData_raw: true,
			recordData_extended: true,
			recordData_activity: true,

			detectMotion: true,

			motion_sampleTrigger_normalizationWindowSize: 10,
			motion_sampleTrigger_smoothing: Muse_eegSamplesPerSecond_raw * .5,
			motion_eegPatterns: [
				{
					enabled: true,
					width_min: .5,
					height_min: 5,
					activityIncrease: 1,
				},
			],

			motion_maxActivity: 20,
			motion_activityDecayAmount: 1,
			motion_activityDecayInterval: 20,
			motion_activityDecay_motionTriggersInterrupt: false,
			motion_activityReset_to: 0,
			motion_activityReset_at: 0,

			//motion_prompts_enabled: true,
			alarmSequence: new AlarmSequence({alarms: []}),

			/*motion_resetActivity_triggerSet: new TriggerSet({
				minTriggerInterval: 1,
				sequences: [],
			}),
			motion_snoozePrompts_triggerSet: new TriggerSet({
				minTriggerInterval: 1,
				sequences: [],
			}),
			motion_snoozePrompts_duration: 30,*/
		},

		alarms: {
			enabled: true,
			goPhaseSleep_triggerSet: new TriggerSet({sequences: []}),
			goPhaseAlarm_triggerSet: new TriggerSet({sequences: []}),
			goPhaseSolving_triggerSet: new TriggerSet({sequences: []}),
			signalAwakeness_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {}),
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {}),
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {}),
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {}),
			]}),

			voiceSoundTag: "",
			volumeMultiplier: 1,

			phaseSleep_soundTag: "",
			phaseSleep_lightTag: "",
			alarmDelay_pool: [80],
			alarmDelay_poolRates: {80: 1},
			alarmDelay_lockPerSession: true,
			alarmDelay_optionCatchUpRate: 1,
			
			phaseAlarm_sequences: [
				new AlarmSequence({
					alarms: [
						new FBAConfig_SoundAlarm({
							type: AlarmType.Sound,
						}),
					],
				}),
			],
			phaseAlarm_sequences_lockPerSession: true,

			phaseSolving_soundTag: "",
			phaseSolving_lightTag: "",
			solveSuccess_message: "",
			solveSuccess_soundPath: "",
			solveSuccess_soundVolume: 1,
		},

		microSnooze: {
			enabled: false,
			microSnooze_triggerSet: new TriggerSet({sequences: []}),
			tiltSnooze_enabled: false,
			tiltSnooze_minAngleFromFlat: 45,
			tiltSnooze_maxTiltDuration: 300,
			tiltSnooze_soundPath: "",
			tiltSnooze_soundVolume: 1,
			maxTimeForProgress: 10,
		},

		memory: {
			enabled: false,
			startPoint: 5 * 60,

			intensityStart: .1,
			intensityMax: 1,
			intensityIncreasePerStep: .1,
			stepSize: 10,

			optionPoolSize: 5,
			optionPoolOrdering: OptionPoolOrdering.Alphabetically,
			optionPoolSource: OptionPoolSource.Geography,
			optionPoolLayers: [
				new OptionPoolLayer({}, "1", "2", "3", "4", "5", "6", "7", "8", "9"),
				new OptionPoolLayer({enabled: false}, "youtube", "facebook", "twitter", "reddit", "dreamviews"),
				new OptionPoolLayer({enabled: false}, "rabbit", "elephant", "giraffe", "zebra", "bird"),
			],
			optionPool_countriesEnabled: ["China", "India", "United States", "Indonesia", "Brazil"], // five most populous countries

			minHintInterval: 10,
			nextHint_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {screenState: "off"})],
			}),
			previousHint_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {screenState: "off"})],
			}),
			giveUp_triggerSet: new TriggerSet({
				//sequences: [new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {screenState: "off"})],
				sequences: [new Sequence([], {screenState: "off"})],
			}),
			targetMiss_snoozePenalty: 30,
			targetMiss_snoozeMin: 3 * 60,

			enumerationSubmission: {
				enabled: false,

				submitPick_triggerSet: new TriggerSet({sequences: [
					new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {screenState: "off"})],
				}),

				nextOption_triggerSet: new TriggerSet({sequences: [
					new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {screenState: "off"})],
				}),
				previousOption_triggerSet: new TriggerSet({sequences: [
					new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {screenState: "off"})],
				}),
				changePick_snooze_enabled: true,
				changePick_snooze_duration: 10,
				minPickInterval: 5,
			},
			speechMatchSubmission: {
				enabled: true,

				remote_recordAudioOnRemote: false,

				listen_backgroundSoundTag: "",
				listen_backgroundSoundVolume: 0,

				listenOrSubmitPick_triggerSet: new TriggerSet({sequences: [
					new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {screenState: "off"})],
				}),
				validTermSensitivity: 10,
				//maxDelayBetweenItems: 1,
				maxCharsBetweenItems: 10,
			},
		},

		dreamTranscribe: {
			enabled: false,
			transcribeStart_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyDown", key_name: "VolumeUp"})], {}),
			]}),
			transcribeEnd_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyUp", key_name: "VolumeUp"})], {}),
			]}),
			background_enabled: false,

			transcribeTarget: TranscribeTarget.panel,
			bracketWordsNearTTS: 1,
			bracketWordsNearTTS_noSpeakCountOrVibrate: true,
			bracketWordsNearTTS_noDJ: true,
			onTranscribe_speakWordCount: false,
			onTranscribe_vibrateDuration: 0,
			onTranscribe_vibrateStrength: "light",
			
			solver_enabled: true,
			solver_minWords: 5,
			solver_ignoreWordsNearTTS: 1,
			solver_wordSequence: "",
			solver_minTime: 0,
			solver_maxTimeValuePerWord: 1,
			solver_markProgressBeforeCompletion: true,
			solver_maxTimeForProgress: 30,
		},

		dreamNarration: {
			enabled: false,
			playAudioOnRemote: false,
			startVolume: 0,
			voiceSoundTag: "",

			/* Example result
				==========
				2019, July 4, 14:06
				Dream title: "Something something"
				Segment 1: "some1; some2; some3 some4; some5" Long text: "Something happened then this other thing."
				Segment 2: "some1; some2; some3 some4; some5"*/
			narrationTemplate: `
				if (dream.tags.includes("@NoNarrate")) return false;
				return \`
					\${date.format("YYYY-MM-DD")} at \${date.format("HH:mm")}
					Title: \${dream.title}
					\${dream.segments.map((segment, index)=>{
						let segmentNarration = \`Segment \${index + 1}: \`;
						if (segment.shortText) {
							segmentNarration += \`\${segment.shortText}\`;
						}
						if (segment.longText) {
							segmentNarration += \` Long text: \${segment.longText}\`;
						}
						return segmentNarration;
					}).join("\n")}
				\`;
			`.AsMultiline(0),

			repeatDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {screenState: "off"}),
			]}),
			nextDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {screenState: "off"}),
			]}),
			previousDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {screenState: "off"}),
			]}),
			stopNarration_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {screenState: "off"}),
			]}),
			increaseVolume_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {screenState: "on"}),
			]}),
			decreaseVolume_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {screenState: "on"}),
			]}),

			increaseVolume_amount: .1,
			decreaseVolume_amount: .1,
			volumeChangePreviewText: "New volume",
		},

		dreamRecall: {
			enabled: true,
			prevDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {}),
			]}),
			nextDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {}),
			]}),
			randomDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {}),
			]}),
			lastDream_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {}),
			]}),
			stopVoice_triggerSet: new TriggerSet({sequences: [
				],
			}),

			dreamNarration_enabled: true,
			dreamNarration_voiceSoundTag: "",
			dreamNarration_volumeMultiplier: 1,
			dreamNarration_segmentMinEntities: 1,
		},

		dreamQuiz: {
			enabled: true,
			submitPick_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {}),
			]}),
			nextOption_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {}),
			]}),
			prevOption_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {}),
			]}),
			skipPick_triggerSet: new TriggerSet({disabled: true, sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {}),
			]}),

			voiceSoundTag: "",
			volumeMultiplier: 1,
			targetCountPerCycle: [],
			optionCount: 4,
			startAtLast: false,

			lucidSegmentsOnly: false,
			segmentMinEvents: 10,
			eventMaxWords: 7,
			hintSize: 2,
			targetSize: 2,
			minDistToTarget: 0,
			questionType: DreamQuiz_QuestionType.isPresent,
			questionType_invert: false,

			soundQuiz_enabled: false,
			quizRatio_dream: 1,
			quizRatio_sound: 1,
			soundFolder: "",
			soundVolume: 1,
			soundDurationLimit: 10,
		},

		conceptLink: {
			enabled: true,
			nextSequence_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {}),
			]}),
			prevSequence_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {}),
			]}),
			playAudio_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {}),
			]}),
			randomizeAudio_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {}),
			]}),

			voiceSoundTag: "",
			volumeMultiplier: 1,

			lucidSegmentsOnly: false,
			segmentMinEvents: 10,
			eventMaxWords: 7,
			sequenceSize: 2,
			recencyBoosts: {},

			soundFolder: "Alarms/Visualizers",
			soundVolume: 1,
			soundDurationLimit: 10,
			effect2: new EffectPointer(),
			effect2_delay: 0,
			effect2_chance: 0,
			effect2_chanceAsBuildup: false,

			solver_enabled: true,
			solver_minSequences: 1,
			solver_markProgressBeforeCompletion: true,
			solver_maxTimeForProgress: 30,
		},

		storyExplore: {
			enabled: true,
			prevText_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {}),
			]}),
			nextText_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {}),
			]}),
			selectText_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})], {}),
			]}),
			stopVoice_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {screenState: "off"}),
			]}),
			newStory_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})], {screenState: "on"}),
			]}),

			voiceSoundTag: "",
			volumeMultiplier: 1,

			minNumberedListSize: 12,
			dynamicListNumbers: [],
			llmHistory_keepLastXNumberedLists: 3,
			temperature: 1,
			startPrompt: prompt_storyExplore_1,
		},

		journeyTraining: {
			enabled: true,
			entityTags: [],
			entityMinCount: 1,
			seeds_basePerEntity: 1,
			seeds_addFromDreams: false,
			
			voiceSoundTag: "",
			volumeMultiplier: 1,
			prefixText: "",
			postfixText: "",
		},

		journeyVisualization: {
			enabled: true,
			userNudge_triggerSet: new TriggerSet({
				//minTriggerInterval: 1000,
				sequences: [new Sequence([new SequenceItem({type: "MouseMove"})], {})],
			}),
			userNudge_soundTag: "",

			voiceSoundTag: "",
			volumeMultiplier: 1,
			entityTags: [],
			seeds_orderKey: "order",
			voiceEntityNumberInCycle: true,
			
			speakInterval: 1000,
			cycleReverse_minTime: 5000,
			cycleReverse_maxTime: 60000,
			userReversalDetection_maxDelay: 3000,
			userReversalDetectionFail_waitPeriod: 10000,
		},

		journeyGrid: {
			enabled: true,
			listenStart_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyDown", key_name: "VolumeUp"})], {}),
			]}),
			listenEnd_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "KeyUp", key_name: "VolumeUp"})], {}),
			]}),

			entityTags_regular: ["person", "object", "concept"],
			entityTags_target: ["lucid"],
			entityTags_success: ["grid-success"],

			voiceSoundTag: "",
			volumeMultiplier: 1,
			speakInterval: 2000,

			targetDelay_minTime: 6000,
			targetDelay_maxTime: 20000,
			userTargetDetection_maxDelay: 6000,
		},

		journeyEntry: {
			enabled: true,
			prevEntity_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeUp"})], {}),
			]}),
			nextEntity_triggerSet: new TriggerSet({sequences: [
				new Sequence([new SequenceItem({type: "Key", key_name: "VolumeDown"})], {}),
			]}),
			
			voiceSoundTag: "",
			volumeMultiplier: 1,
		},
	};
}