import {AddSchema} from "mobx-firelink";
import {AutoEndType} from "../@FBAConfig.js";

export class FBAConfig_General {
	constructor(initialData: Partial<FBAConfig_General>) { this.VSet(initialData); }

	initialDelay: number; // in ms
	autoEnd_enabled: boolean;
	autoEnd_type: AutoEndType;
	autoEnd_time: number; // target time (regular format: ms since epoch)
	autoEnd_delay: number; // in ms
	sleepBlockers_enabled: boolean;
	startupScriptTag?: string|n;
	resetScreenLightTag?: string|n;
	defaultVoice_soundTag: string;
	defaultVoice_volumeMultiplier: number;
	mouseMove_minDistToTrigger: number;
	mouseMove_compareWindow: number;
	mouseMoveRepeatReject_enabled: boolean;
	mouseMoveRepeatReject_roundPositionsTo: number;
	//mouseMoveRepeatReject_cyclingResetTime: number;
	sessionUI_hideStatusBar: boolean;
	sessionUI_hideNavBar: boolean;
	lockOverlay_hideStatusBar: boolean;
	lockOverlay_hideNavBar: boolean;
	notes: string;
}
AddSchema("FBAConfig_General", {
	properties: {
		initialDelay: {type: "number"},
		autoEnd_enabled: {type: "boolean"},
		autoEnd_type: {$ref: "AutoEndType"},
		autoEnd_time: {type: "number"},
		autoEnd_delay: {type: "number"},
		sleepBlockers_enabled: {type: "boolean"},
		startupScriptTag: {type: ["null", "string"]},
		resetScreenLightTag: {type: ["null", "string"]},
		defaultVoice_soundTag: {type: "string"},
		defaultVoice_volumeMultiplier: {type: "number"},
		mouseMove_minDistToTrigger: {type: "number"},
		mouseMove_compareWindow: {type: "number"},
		mouseMoveRepeatReject_enabled: {type: "boolean"},
		mouseMoveRepeatReject_roundPositionsTo: {type: "number"},
		sessionUI_hideStatusBar: {type: "boolean"},
		sessionUI_hideNavBar: {type: "boolean"},
		lockOverlay_hideStatusBar: {type: "boolean"},
		lockOverlay_hideNavBar: {type: "boolean"},
		notes: {type: "string"},
	},
});