import {autorun} from "mobx";
import {dayInMS, hourInMS, minuteInMS, TextSpeaker, weekInMS} from "web-vcore";
import {GetEntities} from "../../../Store/firebase/entities.js";
import {Entity} from "../../../Store/firebase/entities/@Entity.js";
import {FBAConfig_DreamRecall} from "../../../Store/firebase/fbaConfigs/@EngineConfig/@EC_DreamRecall.js";
import {EstimateSegmentStartTime, GetJournalEntries, SortJournalEntries} from "../../../Store/firebase/journalEntries";
import {GetTermsInDreamSegment, JournalEntry, JournalSegment} from "../../../Store/firebase/journalEntries/@JournalEntry";
import {GetSounds} from "../../../Store/firebase/sounds";
import {Sound, SoundType} from "../../../Store/firebase/sounds/@Sound.js";
import {GetUserEntityTags, MeID} from "../../../Store/firebase/users";
import {InAndroid, nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {FBASession, TriggerPackage} from "../../../Engine/FBASession.js";
import {EngineSessionComp} from "./EngineSessionComp.js";
import {store} from "../../../Store/index.js";
import {NarrateText} from "../../../Utils/Services/TTS.js";

export class DreamRecallComp extends EngineSessionComp<FBAConfig_DreamRecall> {
	constructor(session: FBASession, config: FBAConfig_DreamRecall) {
		super(session, config, s=>config.enabled, s=>s.IsLocal());
		
		this.soundsAutorun_disposer = autorun(()=>{
			this.sounds_all = GetSounds();
		});
		this.entitiesAutorun_disposer = autorun(()=>{
			this.entities_all = GetEntities();
		});
		this.journalEntryAutorun_disposer = autorun(()=>{
			this.journalEntries_sorted = SortJournalEntries(GetJournalEntries(MeID()), true);
			this.journalSegments_sorted = this.journalEntries_sorted.SelectMany(a=>a.segments);
			this.journalSegments_toNarrate = this.journalSegments_sorted.filter(segment=>{
				/*const validEntities = segment.entitiesSequence!.filter(entityID=>{
					return this.entities_all.find(a=>a._key == entityID)?.name != "[pass]";
				})
				return validEntities.length >= this.c.dreamNarration_segmentMinEntities;*/
				if (segment.excludeFromIntegration) return false;
				return GetTermsInDreamSegment(segment, "shortText", false).length >= this.c.dreamNarration_segmentMinEntities;
			});
		});
	}

	GetTriggerPackages() {
		return [
			new TriggerPackage("DreamRecall_PrevDream", this.c.prevDream_triggerSet, this, {}, async triggerInfo=>{
				// move to previous dream, and narrate it
				this.SetNarrateSegmentIndex(()=>this.journalSegments_toNarrate.length - 2, old=>(old - 1).KeepAtLeast(0));
				this.NarrateCurrentSegment();
			}),
			new TriggerPackage("DreamRecall_NextDream", this.c.nextDream_triggerSet, this, {}, async triggerInfo=>{
				// move to next dream, and narrate it
				this.SetNarrateSegmentIndex(()=>this.journalSegments_toNarrate.length - 1, old=>(old + 1).KeepAtMost(this.journalSegments_toNarrate.length - 1));
				this.NarrateCurrentSegment();
			}),
			new TriggerPackage("DreamRecall_RandomDream", this.c.randomDream_triggerSet, this, {}, async triggerInfo=>{
				// move to random dream, and narrate it
				const randomIndex = this.journalSegments_toNarrate.map((_, i)=>i).Random() ?? -1;
				this.SetNarrateSegmentIndex(()=>randomIndex, old=>randomIndex);
				this.NarrateCurrentSegment();
			}),
			new TriggerPackage("DreamRecall_LastDream", this.c.lastDream_triggerSet, this, {}, triggerInfo=>{
				// move to last dream (but don't narrate it)
				this.SetNarrateSegmentIndex(()=>this.journalSegments_toNarrate.length - 1, old=>this.journalSegments_toNarrate.length - 1);
				this.StopTextSpeaker();
			}),
			new TriggerPackage("DreamRecall_StopVoice", this.c.stopVoice_triggerSet, this, {}, triggerInfo=>{
				this.StopTextSpeaker();
			}),
		];
	}

	OnStart() {}
	OnStop() {
		this.StopTextSpeaker();
		this.soundsAutorun_disposer();
		this.entitiesAutorun_disposer();
		this.journalEntryAutorun_disposer();
	}

	//dreamNarrationSoundPlayer = new SoundPlayer(); // we use SoundPlayer (not TextSpeaker), since it's project-specific so understands Sound-objects directly
	textSpeaker = new TextSpeaker();

	soundsAutorun_disposer: ()=>void;
	sounds_all: Sound[];

	entitiesAutorun_disposer: ()=>void;
	entities_all: Entity[];

	journalEntryAutorun_disposer: ()=>void;
	journalEntries_sorted: JournalEntry[];
	journalSegments_sorted: JournalSegment[];
	journalSegments_toNarrate: JournalSegment[];
	journalSegments_toNarrate_currentIndex = -1;
	SetNarrateSegmentIndex(setter_initial: ()=>number, setter_fromValid: (oldVal: number)=>number) {
		const oldVal = this.journalSegments_toNarrate_currentIndex;
		const oldIsValid = oldVal >= 0 && oldVal < this.journalSegments_toNarrate.length;
		const newIndex = oldIsValid ? setter_fromValid(oldVal) : setter_initial();
		const newIsValid = newIndex >= 0 && newIndex < this.journalSegments_toNarrate.length;
		this.journalSegments_toNarrate_currentIndex = newIsValid ? newIndex : -1;
	}
	//volume = 1; // 0-1

	// dream narration
	// ==========

	CurrentDreamAndSegment() {
		const segment = this.journalSegments_toNarrate[this.journalSegments_toNarrate_currentIndex];
		const dream = this.journalEntries_sorted.find(a=>a.segments.includes(segment));
		return {dream, segment};
	}
	async NarrateCurrentSegment() {
		const {dream, segment} = this.CurrentDreamAndSegment();
		if (dream == null || segment == null) return;
		const text = this.GetDreamNarrationText(dream, segment);
		let sound = this.sounds_all.filter(a=>GetUserEntityTags(MeID(), "sounds", a._key)?.includes(this.c.dreamNarration_voiceSoundTag)).Random();
		NarrateText(text, this.textSpeaker, this.c.dreamNarration_volumeMultiplier, sound);
	}
	GetDreamNarrationText(dream: JournalEntry, segment: JournalSegment) {
		const timeAgo = Date.now() - EstimateSegmentStartTime(dream, segment)!;
		let timeAgoText = TimeAgoToNarrateText(timeAgo);

		const lucidText = segment.lucid ? "lucid" : null;
		const anchorEntityName = this.entities_all.find(a=>a._key == segment.anchorEntity)?.name;
		const anchorEntityText = anchorEntityName ? `reference ${anchorEntityName}` : null;
		/*const entityNames = segment.entitiesSequence!.map(entityID=>{
			const result = this.entities_all.find(a=>a._key == entityID)?.name;
			if (result == null) return "null";
			return result
				// replace any brackets with spaces; the TTS engines (on Android anyway) interpret brackets weirdly (acting like the comma after them doesn't exist, even with a space between)
				.replace(/[()\[\]\{\}]/g, " ");
		});*/
		const entityNames = GetTermsInDreamSegment(segment, "shortText", false).map(term=>{
			return term
				// replace any brackets with spaces; the TTS engines (on Android anyway) interpret brackets weirdly (acting like the comma after them doesn't exist, even with a space between)
				.replace(/[()\[\]\{\}]/g, " ");
		});
		return [timeAgoText, lucidText, ...entityNames, anchorEntityText].filter(a=>a != null).join(", ");
	}

	StopTextSpeaker() {
		if (g.speechSynthesis != null) {
			this.textSpeaker.Stop();
		} else if (InAndroid(0)) {
			nativeBridge.Call("StopSpeaking");
		}
	}
}

export function TimeAgoToNarrateText(timeAgo: number, roundToInt = true, allowWeeks = false) {
	let timeAgoText: string;
	if (roundToInt) {
		if (timeAgo < hourInMS) timeAgoText = `${Math.round(timeAgo / minuteInMS)} minute${timeAgo >= 1.5*minuteInMS ? "s" : ""} ago`;
		else if (timeAgo < dayInMS) timeAgoText = `${Math.round(timeAgo / hourInMS)} hour${timeAgo >= 1.5*hourInMS ? "s" : ""} ago`;
		else if (timeAgo < weekInMS || !allowWeeks) timeAgoText = `${Math.round(timeAgo / dayInMS)} day${timeAgo >= 1.5*dayInMS ? "s" : ""} ago`;
		else timeAgoText = `${Math.round(timeAgo / weekInMS)} week${timeAgo >= 1.5*weekInMS ? "s" : ""} ago`;
	} else {
		if (timeAgo < hourInMS) timeAgoText = `${(timeAgo / minuteInMS).RoundTo_Str(1)} minute${(timeAgo / minuteInMS).RoundTo(1) > 1 ? "s" : ""} ago`;
		else if (timeAgo < dayInMS) timeAgoText = `${(timeAgo / hourInMS).RoundTo_Str(.1)} hour${(timeAgo / hourInMS).RoundTo(.1) > 1 ? "s" : ""} ago`;
		else if (timeAgo < weekInMS || !allowWeeks) timeAgoText = `${(timeAgo / dayInMS).RoundTo_Str(.1)} day${(timeAgo / dayInMS).RoundTo(.1) > 1 ? "s" : ""} ago`;
		else timeAgoText = `${(timeAgo / weekInMS).RoundTo_Str(.1)} week${(timeAgo / weekInMS).RoundTo(.1) > 1 ? "s" : ""} ago`;
	}
	return timeAgoText;
}