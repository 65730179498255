import {InDesktop} from "../../../../Utils/Bridge/Bridge_Native.js";
import {AppendSessionLogEntry} from "../../../../Utils/Bridge/Bridge_Preload.js";
import {OnStoreLoaded_called} from "../../../../Utils/General/GlobalHooks.js";
import {FBASession, NotifyFBASessionLogChanged, liveFBASession} from "../../../../Engine/FBASession.js";
import {LogEntry, LogType} from "../LogEntry.js";

export class SessionlessLogPeriod {
	startTime = Date.now();
	logEntries = [] as LogEntry[];
}
export const sessionlessLogPeriods = [new SessionlessLogPeriod()] as SessionlessLogPeriod[];
export function OnSessionEnded() {
	if (liveFBASession == null) {
		sessionlessLogPeriods.push(new SessionlessLogPeriod());
	}
}

/*export function FBALog(message: string, type: LogType) {
	if (fbaCurrentSession == null) {
		console.log(`FBALog called, but no session active! Message: ${message} Type:${LogType[type]}`);
		return;
	}
	fbaCurrentSession.Log(message, type);
}*/
export type LogOptions_ForGroupX = {alsoLogToConsole?: boolean, blockAutoCommit?: boolean};
export type LogOptions_ForUnknownGroup = LogOptions_ForGroupX & {group?: FBASession|SessionlessLogPeriod};
export function SessionLog(message: string, type = LogType.Event_Small, opt?: LogOptions_ForUnknownGroup) {
	const group = opt?.group ?? (liveFBASession?.startTime ? liveFBASession : null) ?? sessionlessLogPeriods.Last();
	const entry = new LogEntry({message, type, blockAutoCommit: opt?.blockAutoCommit});
	GroupX_AddSessionLogEntry(group, entry, opt?.alsoLogToConsole);
	return entry;
}
export function GroupX_AddSessionLogEntry(group: FBASession|SessionlessLogPeriod, entry: LogEntry, alsoLogToConsole: boolean|n = null) {
	if (alsoLogToConsole == null) alsoLogToConsole = true; // by default, log entries to the console

	const entryStr = entry.toString(group.logEntries.XFromLast(1));

	group.logEntries.push(entry);
	if (OnStoreLoaded_called) NotifyFBASessionLogChanged();
	if (alsoLogToConsole) console.log(`[SessionLog] ${entryStr}`);

	if (group instanceof FBASession && group.IsLocal() && InDesktop() && group.folderName) {
		const firstEntry = group.logEntries.length == 1;
		if (!entry.blockAutoCommit) {
			AppendSessionLogEntry(group.folderName, entryStr, firstEntry);
		} else {
			// this delay can make entries appended out of order, but that's okay (actual delays are very small, and there are timestamps)
			entry.VSet("onCommit", ()=>{
				AppendSessionLogEntry(group.folderName, entryStr, firstEntry);
			});
		}
	}
}