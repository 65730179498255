import {UserPicker} from "../../../@Shared/Users/UserPicker";
import {DeepEquals, E, GetEntries, IsNaN} from "js-vextensions";
import {runInAction} from "mobx";
import {Button, CheckBox, Column, Row, Select, Spinner, Text, TextInput} from "react-vcomponents";
import {BaseComponent} from "react-vextensions";
import {ScrollView} from "react-vscrollview";
import {store} from "../../../Store/index.js";
import {GetTestSegments} from "../../../Store/firebase/testSegments.js";
import {GetUser} from "../../../Store/firebase/users.js";
import {Observer, RunInAction, RunInAction_Set} from "web-vcore";
import {TestSegment} from "../../../Store/firebase/testSegments/@TestSegment.js";
import {TestResult} from "../Processors/TestSegmentRunner.js";
import {TestSegmentUI} from "./TestingPanel/TestSegmentUI.js";
import {TestingTab} from "../../../Store/main/testing.js";
import React, {useState} from "react";
import {GetLiveFBASession_Reactive} from "../../../Engine/FBASession.js";
import {CanvasOverlay, CanvasOverlay_OnDoubleClick} from "../../Tools/Journey/CanvasOverlay.js";
import {SequenceItem} from "../../../Store/firebase/fbaConfigs/@TriggerSet.js";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {GetNavBarHeights} from "../../Root.js";
import {TriggerSet_Activator} from "../../../Store/firebase/fbaConfigs/@TriggerSet_Activator.js";

@Observer
export class TestingPanel extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.testing;

		const page_leftMarginWidth = (window.innerWidth - 960) / 2;
		const pageWideEnoughToFitPanelInLeftMargin = page_leftMarginWidth >= 400;
		const width = uiState.expanded || !pageWideEnoughToFitPanelInLeftMargin
			? "100%"
			: "calc(((100% - 960px) / 2) - 10px)";

		return (
			<Column style={E(
				{position: "fixed", zIndex: 12, width, height: `calc(100% - ${GetNavBarHeights()}px)`, padding: 5, background: "rgba(0,0,0,.9)", borderRadius: "0 0 0 5px"},
				store.main.topLeftOpenPanel != "testing" && {display: "none"},
			)}>
				<Row>
					<Select options={GetEntries(TestingTab, "ui")} displayType="button bar" value={uiState.tab} onChange={val=>RunInAction_Set(this, ()=>uiState.tab = val)}/>
					<Button ml="auto" faIcon="arrows-alt-h" onClick={()=>RunInAction_Set(this, ()=>uiState.expanded = !uiState.expanded)}/>
				</Row>
				{uiState.tab == TestingTab.general && <GeneralTab/>}
				{uiState.tab == TestingTab.session && <SessionTab/>}
				{uiState.tab == TestingTab.eyeMoveDetection && <EyeMoveDetectionTab/>}
			</Column>
		);
	}
}

@Observer
class GeneralTab extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.testing;

		return (
			<Column>
				<Row mt={5}>
					<TextInput value={uiState.errorMessage} onChange={val=>RunInAction_Set(this, ()=>uiState.errorMessage = val)} style={{flex: 1}}/>
					<Button ml={5} text="Throw error" onClick={()=>{
						//throw new Error(uiState.errorMessage);
						nativeBridge.Call("TriggerError", uiState.errorMessage);
					}}/>
				</Row>
			</Column>
		);
	}
}

@Observer
class SessionTab extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const fbaSession = GetLiveFBASession_Reactive();
		if (fbaSession == null) return <Text>No session is active.</Text>;

		const [ocrCandidateCharValue, setOcrCandidateCharValue] = useState("");

		const FindTriggerActivatorByPackageName = (triggerPackageName: string)=>{
			const triggerPackage = fbaSession.triggerPackages.find(a=>a.name == triggerPackageName);
			return triggerPackage?.activator;
		};
		const FindTriggerActivatorsWithSequenceWithOneExactSequenceItem = (exactItemData: SequenceItem)=>{
			//const targetJSON = JSON.stringify(exactItemData);
			const excludeNulls = obj=>Object.entries(obj).filter(a=>a[1] != null).ToMapObj(a=>a[0], a=>a[1]);

			return fbaSession.triggerPackages.map(a=>a.activator).filter(trigActivator=>{
				return trigActivator?.sequenceActivators.Any(seqActivator=>{
					if (seqActivator.sequence.items.length != 1) return false;
					const item = seqActivator.sequence.items[0];
					//return JSON.stringify(item) == targetJSON;
					return DeepEquals(excludeNulls(item), excludeNulls(exactItemData));
				});
			}) as TriggerSet_Activator[];
		};

		return (
			<Column>
				<Button text="Switch input-modes" onClick={()=>FindTriggerActivatorByPackageName("Journey_NextInputMode")?.OnSequenceActivated(null)}/>
				<Row mt={5}>
					<Text>KeyDown:</Text>
					<Button ml={5} text="VolUp" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "KeyDown", key_name: "VolumeUp"})).forEach(a=>a.OnSequenceActivated(null))}/>
					<Button ml={5} text="VolDown" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "KeyDown", key_name: "VolumeDown"})).forEach(a=>a.OnSequenceActivated(null))}/>
				</Row>
				<Row mt={5}>
					<Text>KeyUp:</Text>
					<Button ml={5} text="VolUp" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "KeyUp", key_name: "VolumeUp"})).forEach(a=>a.OnSequenceActivated(null))}/>
					<Button ml={5} text="VolDown" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "KeyUp", key_name: "VolumeDown"})).forEach(a=>a.OnSequenceActivated(null))}/>
				</Row>
				<Row mt={5}>
					<Text>Press:</Text>
					<Button ml={5} text="VolUp" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "Key", key_name: "VolumeUp"})).forEach(a=>a.OnSequenceActivated(null))}/>
					<Button ml={5} text="VolDown" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "Key", key_name: "VolumeDown"})).forEach(a=>a.OnSequenceActivated(null))}/>
				</Row>
				<Row mt={5}>
					<Text>Hold:</Text>
					<Button ml={5} text="VolUp" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "KeyHold", key_name: "VolumeUp"})).forEach(a=>a.OnSequenceActivated(null))}/>
					<Button ml={5} text="VolDown" onClick={()=>FindTriggerActivatorsWithSequenceWithOneExactSequenceItem(new SequenceItem({type: "KeyHold", key_name: "VolumeDown"})).forEach(a=>a.OnSequenceActivated(null))}/>
				</Row>
				{/*<Button text="JourneyEntry_HoldUp" onClick={()=>FindTriggerActivator("JourneyEntry_HoldUp").OnSequenceActivated(null)}/>
				<Button text="JourneyEntry_HoldDown" onClick={()=>FindTriggerActivator("JourneyEntry_HoldDown").OnSequenceActivated(null)}/>*/}
				<Row mt={5}>
					<TextInput instant value={ocrCandidateCharValue} onChange={val=>setOcrCandidateCharValue(val.slice(-1))}/>
					<Button ml={5} text="Submit as OCR character" onClick={()=>CanvasOverlay_OnDoubleClick(ocrCandidateCharValue)}/>
				</Row>
			</Column>
		);
	}
}

@Observer
class EyeMoveDetectionTab extends BaseComponent<{}, {}> {
	segmentResults = [] as TestResult[];
	OnTestResult = (result: TestResult, segment: TestSegment, index: number)=>{
		const uiState = store.main.testing;
		const segments = GetTestSegments(uiState.selectedUser);
		//const segment = segments[index];
		const enabledSegments = segments.filter(a=>a.enabled);
		const indexInEnabled = enabledSegments.indexOf(segment);

		this.segmentResults[indexInEnabled] = result;
		this.Update();

		const allTestsDone = this.segmentResults.filter(a=>a).length == enabledSegments.length;
		if (allTestsDone) { // only share segment test-results on store, once all have come in
			var averageScore = this.segmentResults.filter(a=>a).map(a=>a.score).Average();
			RunInAction("OnTestResult.allDone", ()=>uiState.averageTestScore = averageScore);
		} /*else if (uiState.averageTestScore != null) {
			RunInAction("OnTestResult.starting", ()=>uiState.averageTestScore = null);
		}*/
	};

	render() {
		let {} = this.props;
		const uiState = store.main.testing;

		const selectedUser = GetUser(uiState.selectedUser);
		const segments = GetTestSegments(uiState.selectedUser).OrderByDescending(a=>a.startTime);
		this.segmentResults.length = segments.filter(a=>a.enabled).length; // keep segment-results array-length up-to-date
		if (this.segmentResults.length) {
			var averageScore = this.segmentResults.filter(a=>a).map(a=>a.score).Average();
		}

		return (
			<>
				<Row center>
					<Text>User:</Text>
					<UserPicker value={uiState.selectedUser} onChange={val=>RunInAction_Set(this, ()=>uiState.selectedUser = val)}>
						<Button ml={5} p={5} style={{fontSize: 12}} text={uiState.selectedUser ? `${selectedUser?.displayName} (id: ${selectedUser?._key})` : "(click to select user)"}/>
					</UserPicker>
					<CheckBox ml={5} text="Run tests" value={uiState.runTests} onChange={val=>RunInAction_Set(this, ()=>uiState.runTests = val)}/>
					{uiState.runTests && averageScore! != null && !IsNaN(averageScore) &&
					<Text ml={5}>Average score: {averageScore.ToPercentStr(.1)}</Text>}
				</Row>
				<Row mt={5} center>
					<Text>X-range:</Text>
					<Spinner ml={5} value={uiState.xRange} onChange={val=>RunInAction_Set(this, ()=>uiState.xRange = val)}/>
					<Text ml={5}>Y-range:</Text>
					<Spinner ml={5} value={uiState.yRange} onChange={val=>RunInAction_Set(this, ()=>uiState.yRange = val)}/>
					<Text ml={5}>False detection penalty:</Text>
					<Spinner ml={5} value={(uiState.falseDetectionPenalty * 100).RoundTo(1)} onChange={val=>RunInAction_Set(this, ()=>uiState.falseDetectionPenalty = val / 100)}/>
					<Text>%</Text>
					{/*<CheckBox ml={5} text="Normalize" value={uiState.normalize} onChange={val=>RunInAction_Set(this, ()=>uiState.normalize = val)}/>
					<InfoButton ml={5} text="Normalization settings are borrowed from the engine config's eeg section."/>
					<CheckBox ml={5} text="Smooth" value={uiState.smooth} onChange={val=>RunInAction_Set(this, ()=>uiState.smooth = val)}/>
					<InfoButton ml={5} text="Smoothing settings are borrowed from the engine config's eeg section."/>*/}
					<CheckBox ml={5} text="Show detections" value={uiState.showDetections} onChange={val=>RunInAction_Set(this, ()=>uiState.showDetections = val)}/>
				</Row>
				<Row mt={5}>Test segments:</Row>
				<ScrollView style={{marginTop: 5}} contentStyle={{paddingBottom: window.innerHeight - 200}}>
					{segments.map((segment, index)=>{
						return <TestSegmentUI key={segment._key} segment={segment} index={index} onTestResult={this.OnTestResult}/>;
					})}
				</ScrollView>
			</>
		);
	}
}