import React, {useEffect} from "react";
import {Button, CheckBox, Column, DropDown, DropDownContent, DropDownTrigger, Row, Select, Spinner, Text} from "react-vcomponents";
import {ShowMessageBox} from "react-vmessagebox";
import useResizeObserver from "use-resize-observer";
import {minuteInMS, Observer, PageContainer, RunInAction_Set} from "web-vcore";
import {E, Timer} from "js-vextensions";
import moment from "moment";
import {BaseComponent, GetDOM, UseEffect} from "react-vextensions";
import {fbaEndedSessions, GetLiveFBASession_Reactive, GetLiveLocalSession_Reactive, liveFBASession, StartHostSession, StopHostSession} from "../../../Engine/FBASession.js";
import {AddJournalEntry} from "../../../Server/Commands/AddJournalEntry.js";
import {UpdateJournalEntry} from "../../../Server/Commands/UpdateJournalEntry.js";
import {GetEntities} from "../../../Store/firebase/entities.js";
import {GetSelectedFBAConfig} from "../../../Store/firebase/fbaConfigs.js";
import {JournalEntry} from "../../../Store/firebase/journalEntries/@JournalEntry.js";
import {GetLights} from "../../../Store/firebase/lights.js";
import {store} from "../../../Store/index.js";
import {BottomPanelSubpanel, entityCellWidthTarget, JourneyTab} from "../../../Store/main/tools/journey.js";
import {nativeBridge} from "../../../Utils/Bridge/Bridge_Native.js";
import {zIndexes} from "../../../Utils/UI/ZIndexes.js";
import {useExpandedNavBar} from "../../@Shared/NavBar";
import {SessionLog} from "../@Shared/BetweenSessionTypes/SessionLog.js";
import {LogType} from "../@Shared/LogEntry.js";
import {DreamPeriodsUI} from "../Journey/DreamPeriodsUI.js";
import {CanvasOverlay} from "./CanvasOverlay.js";
import {JBottomPanel} from "./JBottomPanel.js";
import {LockOverlay} from "./LockOverlay.js";
import {Journey_GetJournalEntriesToShow} from "../../../Store/firebase/journalEntries.js";
import {AlarmsComp, AlarmsPhase, GetAlarmDelayCandidates} from "../../../Engine/FBASession/Components/AlarmsComp.js";
import {CalcInitialDelayOverrideAfterDurationChange_IfTimerActive} from "../../../Utils/General/General.js";

//export const journeyPanelHeights = [0, 50, 50, 0];

export var sessionUIActive = false;
export var lockOverlayActive = false;
export function SetSessionUIActive(active: boolean) {
	sessionUIActive = active;
	UpdateSystemUIVisibility();
}
export function SetLockOverlayActive(active: boolean) {
	lockOverlayActive = active;
	UpdateSystemUIVisibility();
}
export function UpdateSystemUIVisibility() {
	const fbaConfig = GetSelectedFBAConfig();
	const config = fbaConfig?.general;
	if (config == null) return;
	if (lockOverlayActive) {
		nativeBridge.Call("SetSystemUIVisibility", !config.lockOverlay_hideStatusBar, !config.lockOverlay_hideNavBar);
	} else if (sessionUIActive) {
		nativeBridge.Call("SetSystemUIVisibility", !config.sessionUI_hideStatusBar, !config.sessionUI_hideNavBar);
	} else {
		nativeBridge.Call("SetSystemUIVisibility", true, true);
	}
}

@Observer
export class JSessionUI extends BaseComponent<{}, {}> {
	ComponentWillMount = ()=>SetSessionUIActive(true);
	ComponentWillUnmount = ()=>SetSessionUIActive(false);
	render() {
		const uiState = store.main.tools.journey;
		const largeVersion = useExpandedNavBar();

		const {ref: rootRef, width = -1, height = -1} = useResizeObserver({onResize: e=>{
			// find the highest number of columns we can fit, while keeping the column-width >=40px, and keeping the column-count >=9, and a multiple of 3 (since select-entities panel splits it into 3 categories)
			// (add one to the result, to fit an empty column at right of select-entities panel, for touch-based scrolling)
			const newWidth = e.width!.FloorTo(1);
			const newColumnCount = (e.width! / entityCellWidthTarget).FloorTo(3).KeepAtLeast(9) + 1;
			if (newWidth != e.width || newColumnCount != uiState.entities.entityCellColumns) {
				RunInAction_Set(uiState, ()=>{
					uiState.entities.entityCellContainerWidth = newWidth;
					uiState.entities.entityCellColumns = newColumnCount;
				});
			}
		}});
		
		// these are just here so we have synchronous access to them in JourneySession.ts
		const entities = GetEntities();
		const lights = GetLights();

		return (
			<>
				{/* put lock-overlay outside page-container, since page-container's filter-css otherwise keeps overlay from covering the whole viewport */}
				{uiState.locked && <LockOverlay/>}
				{uiState.canvasLocked && <CanvasOverlay/>}
				<PageContainer preset={JSessionUI_Full() ? "full" : "text"}
					ref={c=>{
						const el = GetDOM(c) as HTMLElement|n;
						rootRef["current" as any] = el; // force-set
					}}
					style={E(
						{position: "relative", padding: 0, width: "100%", height: "100%"},
						JSessionUI_Full() && {
							height: "calc(100% - 30px)",
							//background: `rgba(0,0,0,.6)`,
							background: `rgba(0,0,0,.8)`,
						},
						!largeVersion && {margin: "0 auto 0 auto"},
					)}
				>
					<Banner/>
					<DreamPeriodsUI/>
					{uiState.showBottomPanel && <JBottomPanel/>}
				</PageContainer>
			</>
		);
	}
}

export function JSessionUI_Full() {
	const uiState = store.main.tools.journey;
	if (uiState.bottomPanelSubpanel == BottomPanelSubpanel.llm && uiState.llm.showLLMOutputOnSide) return true;
	return false;
}

@Observer
class Banner extends BaseComponent<{}, {}> {
	render() {
		let {} = this.props;
		const uiState = store.main.tools.journey;
		const liveSession = GetLiveFBASession_Reactive();
		const liveLocalSession = GetLiveLocalSession_Reactive();
		if (liveSession != null && liveLocalSession == null) {
			return <div>
				<Button text="X" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.config)}/>
				<Text>{`A non-local FBA session is active; it must be ended (on Tools->Engine page) before using this panel.`}</Text>
			</div>
		}
		const selectedFBAConfig_tracker1 = GetSelectedFBAConfig(); // this is just here so we have synchronous access to it in startHostSession() func (and the funcs called by it)
		const liveAlarmsComp = liveLocalSession?.Comp(AlarmsComp);
		const playing = liveAlarmsComp != null;

		const {journalEntryForSession} = Journey_GetJournalEntriesToShow();

		// update banner every 1s (so "time till next phase" part is up-to-date)
		useEffect(()=>{
			const timer = new Timer(1000, ()=>this.Update()).Start();
			return ()=>timer.Stop();
		});

		let bannerLine1 = "";
		let bannerLine2 = "";
		if (playing) {
			const phaseNiceNames = {
				[AlarmsPhase.NotStarted]: "not started",
				[AlarmsPhase.InitialDelay]: "initial delay",
				[AlarmsPhase.Sleep]: "sleep",
				[AlarmsPhase.Alarm]: "alarm",
				[AlarmsPhase.Solving]: "solving",
			};
			bannerLine1 = `Phase: ${phaseNiceNames[liveAlarmsComp.GetPhase()]}`;

			const timeOfNextPhase = Math.min(...[
				liveLocalSession?.initialDelayTimer.Enabled && liveLocalSession.initialDelayTimer.nextTickTime,
				liveAlarmsComp.sleepUntilAlarmTimer.Enabled && liveAlarmsComp.sleepUntilAlarmTimer.nextTickTime,
				liveAlarmsComp.requiredSolveProgressTimer.Enabled && liveAlarmsComp.requiredSolveProgressTimer.nextTickTime,
				//liveJourneySession.autoEndDelayTimer.Enabled && liveJourneySession.autoEndDelayTimer.nextTickTime,
			].filter(a=>a) as number[]);
			if (timeOfNextPhase == Infinity) {
				bannerLine2 = "(waiting for user action)";
			} else {
				const minutesTillNextPhase = (timeOfNextPhase - Date.now()).KeepAtLeast(0) / minuteInMS;
				const secondsTillNextPhase = (timeOfNextPhase - Date.now()).KeepAtLeast(0) / 1000;
				bannerLine2 = minutesTillNextPhase >= 1
					? `(ends in: ${Math.ceil(minutesTillNextPhase).toString()} minutes)`
					: `(ends in: ${Math.ceil(secondsTillNextPhase).toString()} seconds)`;
			}
		} else {
			bannerLine1 = "No session active."
		}

		UseEffect(()=>{
			// if our app was just restarted after a crash (due to the crash-checker alarms), then auto-start a journey-session after a brief delay (to ensure data required for session-start is loaded in)
			if (startURL.GetQueryVar("restoredFromCrash") == "1") {
				const timer = new Timer(10000, ()=>{
					if (g.journeySessionStartedFromCrashRecovery) return;
					g.journeySessionStartedFromCrashRecovery = true;
					SessionLog("Automatically starting journey-session after crash-recovery.", LogType.Event_Large);
					startHostSession();
				}, 1).Start();
				return ()=>timer.Stop();
			}
		}, []);

		const playing_regularMode = liveLocalSession?.launchType == "night";
		const playing_daytimeMode = liveLocalSession?.launchType == "day";
		const startHostSession = (daytimeMode = false)=>{
			StartHostSession(daytimeMode ? "day" : "night");
		};
		const stopSession = ()=>{
			/*liveJourneySession.Stop(false, false); // maybe temp; don't save in fba-sessions table
			SetLiveFBASession(null);*/
			const session = liveLocalSession!;
			StopHostSession(false, false);

			let saveSession = true;
			let markDreamWakeTime = journalEntryForSession != null && journalEntryForSession.wakeTime == null;
			const controller = ShowMessageBox({
				title: "Perform final data saves?", cancelButton: true,
				message: ()=>{
					const Change = (..._)=>controller.UpdateUI();
					return (
						<Column>
							<Row>
								<CheckBox text="Save session-info" value={saveSession} onChange={val=>Change(saveSession = val)}/>
							</Row>
							<Row>
								<CheckBox text="Mark dream-wake-time" enabled={journalEntryForSession != null} value={markDreamWakeTime} onChange={val=>Change(markDreamWakeTime = val)}/>
							</Row>
						</Column>
					);
				},
				onOK: ()=>{
					if (saveSession) {
						// save session-info, but don't save timeline-event (haven't used/cared-about that system for a long time)
						session.FinalizeStoredData(false, true, false);
					}
					if (markDreamWakeTime) {
						new UpdateJournalEntry({id: journalEntryForSession!._key, updates: {wakeTime: Date.now()}}).Run();
					}
				},
			});
		};

		return (
			<Row>
				{/*<Button size={32} mdIcon="close" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.config)}/>*/}
				{/*<Button ml={3} size={32} mdIcon="chart-line" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.stats)}/>*/}
				<Button ml={3} size={32} mdIcon="text-box" onClick={()=>RunInAction_Set(this, ()=>uiState.tab = JourneyTab.log)}/>
				<Button ml={3} size={32} mdIcon={playing_daytimeMode ? "pause" : "white-balance-sunny"}enabled={!playing || playing_daytimeMode}
					title="Start daytime session. (disables certain features, like alarms; affected options tinted light blue)"
					onClick={()=>{
						if (playing) {
							stopSession();
						} else {
							startHostSession(true);
						}
					}}/>
				<Button ml={3} size={32} mdIcon={playing_regularMode ? "pause" : "play"} enabled={!playing || playing_regularMode}
					title="Start night/regular session. (full functionality)"
					onClick={()=>{
						if (playing) {
							stopSession();
						} else {
							startHostSession();
						}
					}}/>
				<Column style={{flex: 1, alignItems: "center"}}>
					<Text style={{flex: 1}}>{bannerLine1}</Text>
					{bannerLine2 && <Text style={{flex: 1}}>{bannerLine2}</Text>}
				</Column>
				<Button ml={3} size={32} mdIcon={uiState.showAnchorEntities ? "image" : "image-frame"} onClick={()=>{
					RunInAction_Set(this, ()=>uiState.showAnchorEntities = !uiState.showAnchorEntities);
				}}/>
				<Button ml={3} size={32} mdIcon={uiState.locked ? "lock" : "lock-open-variant"} onClick={()=>{
					//RunInAction_Set(this, ()=>uiState.locked = !uiState.locked);
					if (!uiState.locked) {
						RunInAction_Set(()=>store.main.tools.journey.locked = true);
						/*if (alsoHideNavigationUI) {
							document.documentElement.requestFullscreen({navigationUI: "hide"});
						}*/
					}
				}}/>
				{/*<Button ml={3} text="VTT" onClick={()=>{}}/>*/}
				<DropDown>
					<DropDownTrigger>
						<Button ml={3} mdIcon="cog" size={32}/>
					</DropDownTrigger>
					<DropDownContent style={{zIndex: zIndexes.dropDown, position: "fixed", right: 0, background: "rgba(0,0,0,.9)", borderRadius: 5}}>
						<JSessionSettingsDropDown/>
					</DropDownContent>
				</DropDown>
			</Row>
		);
	}
}

@Observer
class JSessionSettingsDropDown extends BaseComponent<{}, {}> {
	render() {
		const uiState = store.main.tools.journey;
		const liveLocalSession = GetLiveLocalSession_Reactive();
		const liveAlarmsComp = liveLocalSession?.Comp(AlarmsComp);
		return (
			<Column>
				<Row>
					<Text>Prior journal-entries to show:</Text>
					<Spinner ml={5} value={uiState.pastJournalEntriesToShow} onChange={val=>RunInAction_Set(this, ()=>uiState.pastJournalEntriesToShow = val)}/>
				</Row>
				<Row>
					<Text>Show anchor-entities:</Text>
					<CheckBox ml={5} value={uiState.showAnchorEntities} onChange={val=>RunInAction_Set(this, ()=>uiState.showAnchorEntities = val)}/>
				</Row>
				<Row>
					<Text>Show event boxes:</Text>
					<CheckBox ml={5} value={uiState.showEventBoxes} onChange={val=>RunInAction_Set(this, ()=>uiState.showEventBoxes = val)}/>
				</Row>
				<Row>
					<Text>Show dream-entities:</Text>
					<CheckBox ml={5} value={uiState.showDreamEntities} onChange={val=>RunInAction_Set(this, ()=>uiState.showDreamEntities = val)}/>
				</Row>
				<Row>
					<Text>Show entity selection-panel:</Text>
					<CheckBox ml={5} value={uiState.showBottomPanel} onChange={val=>RunInAction_Set(this, ()=>uiState.showBottomPanel = val)}/>
				</Row>
				<Row>
					<Text>Screenless entity-adding:</Text>
					<CheckBox ml={5} value={uiState.allowScreenlessEntityAdding} onChange={val=>RunInAction_Set(this, ()=>uiState.allowScreenlessEntityAdding = val)}/>
				</Row>
				{liveLocalSession != null && <Row mt={5}>Live:</Row>}
				{liveLocalSession != null &&
				<Column ml={10}>
					{liveLocalSession.autoEndDelayTimer.Enabled &&
					<Row ml={10} mt={5}>
						<Text>{`Change auto-end time (${moment(liveLocalSession.autoEndDelayTimer?.nextTickTime).format("HH:mm:ss")})`}</Text>
						<Button ml={5} p="1px 7px" text="-30m" onClick={async()=>{
							liveLocalSession.ModifyLiveAutoEndTime(liveLocalSession.autoEndDelayTimer.nextTickTime! - (30 * minuteInMS));
							this.Update();
						}}/>
						<Button ml={5} p="1px 7px" text="+30m" onClick={async()=>{
							liveLocalSession.ModifyLiveAutoEndTime(liveLocalSession.autoEndDelayTimer.nextTickTime! + (30 * minuteInMS));
							this.Update();
						}}/>
					</Row>}
					{liveAlarmsComp != null && <>
						<Row mt={5}>
							<Text>{`Change alarm delay (${Math.ceil((liveAlarmsComp.sleepUntilAlarmTimer.intervalInMS / minuteInMS))}m)`}</Text>
							{GetAlarmDelayCandidates(liveAlarmsComp.c).map(durationInMins=>{
								return <Button key={durationInMins} ml={5} p="1px 7px" text={`${durationInMins}m`} onClick={async()=>{
									const initialDelayOverride_ifWasActive = CalcInitialDelayOverrideAfterDurationChange_IfTimerActive(liveAlarmsComp.sleepUntilAlarmTimer, durationInMins * minuteInMS);
									liveAlarmsComp.SetSleepUntilAlarmTimerDuration(durationInMins * minuteInMS);
									if (initialDelayOverride_ifWasActive != null) {
										liveAlarmsComp.sleepUntilAlarmTimer.Start(initialDelayOverride_ifWasActive);
									}
									this.Update();
								}}/>;
							})}
						</Row>
						{liveLocalSession.launchType == "night" &&
						<Row mt={5}>
							<Text>Current timer:</Text>
							<Button ml={5} p="1px 7px" text="Set to X" onClick={()=>{
								if (!liveAlarmsComp.sleepUntilAlarmTimer.Enabled) return;
								if (liveAlarmsComp.sleepUntilAlarmTimer.nextTickTime == null) return;
								const timerTimeLeft = liveAlarmsComp.sleepUntilAlarmTimer.nextTickTime - Date.now();
								let newMinutesLeft = Math.ceil(timerTimeLeft / minuteInMS);
								const controller = ShowMessageBox({
									title: "Set sleep-timer to X", cancelButton: true,
									message: ()=>(
										<Column style={{padding: `10px 0`, width: 600}}>
											<Row>
												<Text>Minutes left:</Text>
												<Spinner ml={5} value={newMinutesLeft} onChange={val=>{
													newMinutesLeft = val;
													controller.UpdateUI();
												}}/>
											</Row>
										</Column>
									),
									onOK: ()=>{
										if (!liveAlarmsComp.sleepUntilAlarmTimer.Enabled) return;
										liveAlarmsComp.sleepUntilAlarmTimer.Start(newMinutesLeft * minuteInMS);
									},
								});
							}}/>
							<Button ml={5} p="1px 7px" text={`Reset to ${liveLocalSession.recoveryInfo_saved.journey_alarmDelay / minuteInMS}m`} onClick={()=>{
								if (!liveAlarmsComp.sleepUntilAlarmTimer.Enabled) return;
								liveAlarmsComp.sleepUntilAlarmTimer.Start(liveLocalSession.recoveryInfo_saved.journey_alarmDelay);
							}}/>
						</Row>}
					</>}
					<Row mt={5}>
						<Button text="Suspend/unsuspend comps" onClick={()=>{
							const controller = ShowMessageBox({
								title: "Suspend/unsuspend comps",
								message: ()=>(
									<Column style={{padding: `10px 0`, width: 600}}>
										{liveLocalSession?.components.map((comp, index)=>{
											return <Row key={index}>
												<CheckBox ml={5} text={comp.constructor.name} value={!comp.IsSuspended()} onChange={val=>{
													if (val) comp.Unsuspend();
													else comp.Suspend();
													controller.UpdateUI();
												}}/>
											</Row>;
										})}
									</Column>
								),
							});
						}}/>
					</Row>
				</Column>}
				<Row mt={5}>
					<Button p="1px 7px" text="Upload recent session" enabled={fbaEndedSessions.length > 0} onClick={async()=>{
						const recentSessions = [...fbaEndedSessions];
						let selectedRecentSession = recentSessions.LastOrX();

						const controller = ShowMessageBox({
							title: "Select recent session", cancelButton: true,
							message: ()=>(
								<Column style={{padding: `10px 0`, width: 600}}>
									<Select displayType="dropdown"
										options={recentSessions.map(session=>({
											name: `${moment(session.startTime).format("YYYY-MM-DD HH:mm:ss")} - ${session.endTime ? moment(session.endTime).format("YYYY-MM-DD HH:mm:ss") : "n/a"}`,
											value: session,
										}))}
										value={selectedRecentSession}
										onChange={val=>{
											selectedRecentSession = val;
											controller.UpdateUI();
										}}/>
								</Column>
							),
							onOK: async()=>{
								const localSessionToUpload = selectedRecentSession?.AsLocal;
								if (localSessionToUpload == null) {
									return void ShowMessageBox({title: "Cannot upload session", message: "Only local sessions can be uploaded. (selected session is not local)"});
								}
								// save session-info, but don't save timeline-event (haven't used/cared-about that system for a long time)
								localSessionToUpload.FinalizeStoredData(false, true, false);
							},
						});
					}}/>
					<Button ml={5} size={32} mdIcon="lock-smart" onClick={()=>{
						if (!uiState.canvasLocked) {
							RunInAction_Set(()=>store.main.tools.journey.canvasLocked = true);
						}
					}}/>
				</Row>
				<Row mt={5}>
					<Button p="1px 7px" text="Add journal entry" onClick={async()=>{
						const entry = new JournalEntry(E(
							{
								title: "New entry",
								// we don't know if user's adding the entry before or after sleep, so fill in both fields (user can then delete/modify the one that is wrong; till then, the equality indicates auto-entry/uncertainty)
								sleepTime: Date.now(),
								wakeTime: Date.now(),
								segments: [],
							} as Partial<JournalEntry>,
							// if session active, assume user's adding entry for it, so copy session's start-time to also be journal-entry's start-time (and set end-time to null, since session is ongoing)
							liveLocalSession != null && {
								sleepTime: liveLocalSession.startTime,
								wakeTime: null,
							},
						));
						const entryID = await new AddJournalEntry({entry}).Run();
					}}/>
				</Row>
			</Column>
		)
	}
}