import {Assert} from "js-vextensions";
import {liveFBASession} from "../../../Engine/FBASession.js";
import {SessionEvent} from "../../../Engine/FBASession/SessionEvent.js";
import {FBAConfig} from "../fbaConfigs/@FBAConfig.js";
import {EstimateSegmentEndTime} from "../journalEntries.js";
import {JournalEntry, JournalSegment} from "../journalEntries/@JournalEntry.js";
import {GetSessions} from "../sessions.js";
import {EngineSessionInfo} from "../sessions/@EngineSessionInfo.js";
import {MeID} from "../users.js";
import {minuteInMS} from "web-vcore";

export type EventGroup = {
	sessionInfo: EngineSessionInfo,
	type: "special" | "wait-period" | "cycle",
	events: SessionEvent[],
	//enderReached: boolean|n,
	duration: number, // in ms
};

/*export function GetDataFromSessionsForJournalSegment(entry: JournalEntry, nextEntry: JournalEntry|n, segment: JournalSegment): DataFromSessionsForJournalEntry {
	// for now, keep it simple; have all event-groups shown only for the first dream/non-wake segment in the journal-entry
	const firstDreamSegment = entry.segments.filter(a=>a.wakeTime == null)[0];
	if (segment != firstDreamSegment) return {sessionInfosInRange: [], eventGroups: []};
	return GetDataFromSessionsForJournalEntry(entry, nextEntry);
}*/

export class DataFromSessionsForJournalEntry {
	static NewEmpty() { return new DataFromSessionsForJournalEntry({sessionInfosInRange: [], eventGroups: []}); }
	
	constructor(data: Partial<DataFromSessionsForJournalEntry>) { Object.assign(this, data); }
	sessionInfosInRange: EngineSessionInfo[];
	eventGroups: EventGroup[];
};
export function GetDataFromSessionsForJournalEntry(entry: JournalEntry, nextEntry: JournalEntry|n, warnIf5PlusSessionsInRange: boolean): DataFromSessionsForJournalEntry {
	//const lastWakeTime = entry.segments.map(a=>a.wakeTime).filter(a=>a != null).LastOrX();
	let sessions = GetSessions(MeID())
		.concat(liveFBASession?.AsLocal ? [liveFBASession.AsLocal.GetSessionInfo()] : []);
	/*const sessionInfosInRange = lastWakeTime == null ? [] :
		sessions.filter(a=>a.startTime >= entry.sleepTime && (a.endTime == null || a.endTime <= lastWakeTime));*/
	const sessionInfosInRange = sessions.filter(session=>{
		const sessionStartsAtOrAfterThisSegment = entry.sleepTime && session.startTime >= entry.sleepTime;
		const sessionIsBeforeNextSegment = nextEntry == null || (nextEntry.sleepTime && session.startTime < nextEntry.sleepTime);
		return sessionStartsAtOrAfterThisSegment && sessionIsBeforeNextSegment;
	});
	const eventGroups = GetEventGroupsInSessions(sessionInfosInRange);
	if (sessionInfosInRange.length > 5 && warnIf5PlusSessionsInRange) {
		console.warn("More than 5 sessions were found in range for a single journal-entry. This *might* indicate a bug, or bad data.");
		console.log("Journal-entry start-time:", entry.sleepTime ? new Date(entry.sleepTime).toLocaleString("sv") : "n/a");
		console.log("Next journal-entry start-time:", nextEntry?.sleepTime ? new Date(nextEntry.sleepTime).toLocaleString("sv") : "n/a");
		for (const [i, session] of sessionInfosInRange.entries()) {
			console.log(`#${i + 1} session start-time:`, new Date(session.startTime).toLocaleString("sv"));
		}
		debugger;
	}
	return {sessionInfosInRange, eventGroups};
}
export function GetEventGroupsInSessions(sessions: EngineSessionInfo[]): EventGroup[] {
	const sessionEventsInRange = sessions.SelectMany(a=>{
		return [
			// add synthetic event, for start of session
			new SessionEvent({date: a.startTime, type: "General.SessionStart", _isSynthetic: true}),
			...a.events,
			// add synthetic event, for end of session
			new SessionEvent({date: a.endTime ?? Date.now(), type: "General.SessionEnd", _isSynthetic: true}),
		];
	}).OrderBy(a=>a.date);
	
	const eventGroups = [] as EventGroup[];
	
	type EventGroup_BeingConstructed = PartialBy<EventGroup, "sessionInfo" | "type" | "duration">;
	let currentGroup = {events: [] as SessionEvent[]} as EventGroup_BeingConstructed;
	const endCurrentGroup = ()=>{
		currentGroup.duration = currentGroup.events.Last().date - currentGroup.events.First().date;
		const groupFirstNonSyntheticEvent = currentGroup.events.find(a=>!a._isSynthetic);
		currentGroup.sessionInfo = sessions.find(session=>{
			//return session.events.ContainsAny(...currentGroup.events);
			return session.events.includes(groupFirstNonSyntheticEvent as any);
		});
		eventGroups.push(currentGroup as EventGroup);
		currentGroup = {events: []} as EventGroup_BeingConstructed;
	};
	for (const [i, event] of sessionEventsInRange.entries()) {
		//const prevEvent: SessionEvent|n = sessionEventsInRange[i - 1];
		
		let isGroupEnder = false;
		// events for group-type: special
		if (event.type == "General.SessionRecovered") {
			// end previous group (session-recovery should be its own group)
			if (currentGroup.events.length) endCurrentGroup();
			currentGroup.type = "special";
			// end new group (session-recovery should be its own group)
			isGroupEnder = true;
		}
		// events for group-type: wait-period
		if (event.type == "General.SessionStart") {
			// if a group is already in-progress, end it now (can't have groups span multiple sessions)
			if (currentGroup.events.length) endCurrentGroup();
		} else if (event.type == "General.SessionEnd") {
			isGroupEnder = true;
		} else if (event.type == "General.InitialDelayEnd") {
			currentGroup.type = "wait-period";
			isGroupEnder = true;
		} else if (event.type == "Journey.ResleepStart") {
			currentGroup.type = "wait-period";
		} else if (event.type == "Journey.ResleepSkip") {
			isGroupEnder = true;
		} else if (event.type == "Journey.ResleepEnd") {
			isGroupEnder = true;
		}
		// events for group-type: cycle
		else if (event.type == "Journey.CycleStart") {
			currentGroup.type = "cycle";
		} else if (event.type == "Journey.CycleFail") {
			isGroupEnder = true;
		} else if (event.type == "Journey.CycleSuccess") {
			isGroupEnder = true;
		}

		currentGroup.events.push(event);
		if (isGroupEnder) endCurrentGroup();
	}
	if (currentGroup.events.length) endCurrentGroup();
	return eventGroups;
}

export class CycleInfo {
	constructor(data: Partial<CycleInfo>) { Object.assign(this, data); }
	cycleNumber: number;
	alarmDelay: number;
	alarmWaitEndTime: number;
	responseTime: number;
	// basic logic for associating dream-segments and sleep-cycles: a given dream-segment is associated with the latest sleep-cycle whose end-time is before the dream-segment's end-time
	// (reason: regardless of whether the user or the engine adds the dream-segment, the sleep-period should have ended first to trigger the user/engine to add the dream-segment)
	associatedDreamSegments = [] as JournalSegment[];
}
export function DiscernCycleInfosFromEventGroups(eventGroups: EventGroup[], infoForAssociation?: {dream: JournalEntry, segments: JournalSegment[]}) {
	//Assert(!infoForAssociation?.segments.Any(a=>a.wakeTime != null), "FindCycleNumberForDreamSegment should only be called for non-wake segments.");
	const groupIsAlarmWait = group=>group.type == "wait-period" && group.events.Any(b=>b.type == "Journey.ResleepEnd");

	const result = [] as CycleInfo[];

	let alarmWaitsReached = 0;
	let lastAlarmWait_delay = 0;
	let lastAlarmWait_endTime: number|n;
	for (const [i, group] of eventGroups.entries()) {
		//const priorGroups = eventGroups.slice(0, i);
		const lastEvent = group.events.Last();

		if (groupIsAlarmWait(group)) {
			alarmWaitsReached++;
			// if the session had the alarm-delay locked to a single value, prefer that over duration inferred from the session-events
			// (the session-events can be complicated by eg. manual resets of sleep-timer, so a known locked alarm-delay is more reliable)
			lastAlarmWait_delay = group.sessionInfo.alarmDelay ?? group.duration;
			lastAlarmWait_endTime = lastEvent.date;
		} else if (group.type == "cycle" && group.events.Any(a=>a.type == "Journey.CycleSuccess") && lastAlarmWait_endTime != null) {
			const cycleNumber = alarmWaitsReached; // the "first real cycle" comes after the first alarm-wait
			const graphGroup_alarmDelay = (lastAlarmWait_delay / minuteInMS).RoundTo(1) ?? 0;

			const userResponseEvent = group.events.find(a=>a.type == "Journey.ListenStart") ?? lastEvent;
			const responseTime = userResponseEvent.date - lastAlarmWait_endTime;
			const cycleInfo = new CycleInfo({cycleNumber, alarmDelay: graphGroup_alarmDelay, alarmWaitEndTime: lastAlarmWait_endTime, responseTime});
			result.push(cycleInfo);
		}
	}

	const dreamSegments = infoForAssociation?.segments.filter(a=>a.wakeTime == null) ?? [];
	for (const segment of dreamSegments) {
		const segmentEndTime = EstimateSegmentEndTime(infoForAssociation!.dream, segment);
		if (segmentEndTime == null) continue; // cannot associate segment with cycle, if segment has no end-time
		const latestCycleBeforeSegment = result.LastOrX(a=>a.alarmWaitEndTime < segmentEndTime);
		if (latestCycleBeforeSegment) {
			latestCycleBeforeSegment.associatedDreamSegments.push(segment);
		}
	}

	return result;
}

export function Legacy_GetInitialDelay(config: FBAConfig, fallbackValue = 0) {
	return config.general?.initialDelay ?? config["initialDelay"] ?? fallbackValue;
}
export function Legacy_FirstCycleCountsAsRehearsalForConfig(config: FBAConfig) {
	return Legacy_GetInitialDelay(config) <= 60000;
};

export function GetMaxPossibleCycleSuccesses(sessionInfosInRange: EngineSessionInfo[], eventGroups: EventGroup[]) {
	if (sessionInfosInRange.length == 0) return 0;
	// fallback for ancient sessions with configs that miss needed data
	const session1Conf = sessionInfosInRange[0].config;
	if (session1Conf.journeyVisualization?.cycleReverse_minTime == null) return 0;
	if (session1Conf.alarms?.alarmDelay_pool == null) return 0;
	if (session1Conf.journeyGrid?.targetDelay_minTime == null) return 0;
	
	const totalTime = sessionInfosInRange.map(a=>a.endTime ?? Date.now()).Max() - sessionInfosInRange.map(a=>a.startTime).Min();
	const timeForInitDelays = sessionInfosInRange.map(a=>Legacy_GetInitialDelay(a.config)).Sum();
	// number of sessions that were "possible to have had a successful initial-cycle in", ie. those which completed their initial-delay
	const nonRehearsalInitCycles = sessionInfosInRange.filter(a=>!Legacy_FirstCycleCountsAsRehearsalForConfig(a.config) && a.events.Any(a=>a.type == "Journey.CycleStart")).length;
	
	const timeForAlarmWaitPlusCyclePairs = totalTime - timeForInitDelays;

	const cycleType = sessionInfosInRange.Any(a=>a.events.Any(b=>b.type == "Journey.CycleReverse")) ? "visualization" : "grid";
	// Should the alarm-delay "min" be used here, or the "average"? Not sure...
	const alarmWaitPlusCyclePair_minLength = cycleType == "visualization"
		? sessionInfosInRange[0].config.journeyVisualization.cycleReverse_minTime + sessionInfosInRange[0].config.alarms.alarmDelay_pool.Min()
		: sessionInfosInRange[0].config.journeyGrid.targetDelay_minTime + sessionInfosInRange[0].config.alarms.alarmDelay_pool.Min();

	return nonRehearsalInitCycles + (timeForAlarmWaitPlusCyclePairs / alarmWaitPlusCyclePair_minLength).FloorTo(1);
}

export function EventGroupWasRehearsalCycle(group: EventGroup, allGroups: EventGroup[]) {
	// if this event-group is not even a cycle group (eg. instead an alarm-wait period), then it can't be a rehearsal-cycle
	//if (!group.events.Any(a=>a.type == "Journey.CycleStart")) return false;
	if (group.type != "cycle") return false;
	
	const session = group.sessionInfo;
	const groupsInSession = allGroups.filter(a=>a.sessionInfo == session);
	const priorGroupsInSession = groupsInSession.slice(0, groupsInSession.indexOf(group));
	const priorGroupsInSession_lastIndexOfRehearsallyInitDelayOrAlarmWaitSkip = priorGroupsInSession.SelectMany(a=>a.events).findLastIndex(a=>{
		return (Legacy_FirstCycleCountsAsRehearsalForConfig(session.config) && a.type == "General.InitialDelayEnd") || a.type == "Journey.ResleepSkip";
	});
	const priorGroupsInSession_lastIndexOfAlarmWaitEnd = priorGroupsInSession.SelectMany(a=>a.events).findLastIndex(a=>a.type == "Journey.ResleepEnd");
	// if initial-delay or last-alarm-wait-skip (before target cycle-group) was closer to target cycle-group than the last alarm-wait-end, then the cycle was a "rehearsal"
	// (the idea of a "rehearsal cycle" is one that was the first of the night [if initial delay was <1m], or it was skipped to -- ie. not organic / interacted with after actual awakening)
	return priorGroupsInSession_lastIndexOfRehearsallyInitDelayOrAlarmWaitSkip > priorGroupsInSession_lastIndexOfAlarmWaitEnd;
};